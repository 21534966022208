import hours from "../components/Common/Duration/hours.json"
import minutes from "../components/Common/Duration/minutes.json"

const getHours = () => {
  return hours
}

const getMinutes = () => {
  return minutes
}
const getHour = hh => {
  return getHours().filter(hour => hour.hh == hh)[0]
}
const getMinute = mm => {
  return getMinutes().filter(minute => minute.mm == mm)[0]
}

const getHoursMinutes = mm => {
  var hour = Math.floor(mm / 60)
  var minute = mm % 60

  let result = ""
  if (hour > 0) {
    result = `${hour} hours `
  }
  if (minute > 0) {
    result = result + `${minute} minutes`
  }
  return result
}

export { getHours, getMinutes, getHour, getMinute, getHoursMinutes }
