import PropTypes from "prop-types"
import React from "react"

//react form
import { FormProvider, useForm } from "react-hook-form"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

import { isEmpty } from "lodash"

import { addEnrollment as onAddEnrollment } from "store/actions"
import PageModal from "components/Common/PageModal"
import EnrollmentFields from "components/Common/Enrollment"
import { EnumServiceTypes } from "helpers/enum_helper"

const formdefault = {
  ProgramID: 0,
  DayID: 0,
  ServiceTypeID: EnumServiceTypes.Enroll_In_Program,
  ProgramSchedules: [],
}

const EnrollModal = ({ show, onCloseClick }) => {
  const dispatch = useDispatch()
  const { program } = useSelector(state => state.program)

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    ProgramID: yup.number(),
    DayID: yup.number(),
    Children: yup
      .array()
      .required("Required")
      .transform((_, val) => (val ? val : null)),
    ProgramSchedules: yup.array().of(
      yup.object().shape({
        DayID: yup.number(),
        Name: yup.string(),
        IsLinked: yup.bool(),
        Times: yup.array().of(
          yup.object().shape({
            StartTime: yup.string(),
            EndTime: yup.string(),
          })
        ),
      })
    ),
    EnrollmentTypes: yup
      .object()
      .required("Required")
      .transform((_, val) => (val ? val : null)),
    IsSchedulePass: yup.string().required("Required"),
    StartDate: yup.string().required("Required"),
    EndDate: yup.string(),
    Classes: yup
      .object()
      .required("Required")
      .transform((_, val) => (val ? val : null)),
    ServiceTypeID: yup.number(),
    AddOns: yup.array(),
    Charges: yup.array(),
    Credits: yup.array(),
    AppliedDays: yup.array(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, watch, trigger } =
    methods
  const { errors, isValid } = formState

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      setValue("ProgramID", program.ProgramID)
      setValue("DayID", program.ProgramSchedules[0].Days)
      //console.log(getValues())
      dispatch(onAddEnrollment(getValues()))
      onCloseClick()
    }
  }

  if (isEmpty(program)) {
    return
  }

  return (
    <PageModal
      show={show}
      onCloseClick={onCloseClick}
      onSaveClick={() => onSaveClick()}
      header="Enroll into Program"
    >
      <FormProvider {...methods}>
        <EnrollmentFields />
      </FormProvider>
    </PageModal>
  )
}

EnrollModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
}

export default EnrollModal
