import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import * as Yup from "yup"
import classnames from "classnames"
import { useFormik } from "formik"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import listPlugin from "@fullcalendar/list"
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"
import * as moment from "moment"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//import Images
import verification from "assets/images/verification-img.png"
//custom hooks
import { useDeepCompareEffect, useUserDetail } from "hooks"
import {
  addNewEvent as onAddNewEvent,
  deleteEvent as onDeleteEvent,
  getCategories as onGetCategories,
  //getEvents as onGetEvents,
  getCalendarProgramEnrollmentSchedule as onGetCalendarProgramEnrollmentSchedule,
  getCalendarProgramEnrollmentScheduleDetail as onGetCalendarProgramEnrollmentScheduleDetail,
  updateEvent as onUpdateEvent,
} from "../../../store/actions"
import ProfilePicture from "components/Common/ProfilePicture"
import { EnumObjectTypes } from "helpers/enum_helper"
import { Calculate_Age, HandleValidDate } from "helpers/method_helper"
import DeleteModal from "./DeleteModal"
import CurriculumTab from "./tabs/CurriculumTab"
import LessonPlanTab from "./tabs/LessonPlanTab"
import NoteTab from "./tabs/NoteTab"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

const Calender = props => {
  //meta title
  document.title = "Calendar | " + process.env.REACT_APP_NAME
  const { userprofile, relations } = useUserDetail()
  const dispatch = useDispatch()

  const [event, setEvent] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [editable, setEditable] = useState(true)
  // category validation
  const categoryValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (event && event.title) || "",
      start: (event && event.start) || "",
      end: (event && event.end) || "",
      // end: (event && event.end) || "",
      category: (event && event.category) || "",
      classtitle: (event && event.classtitle) || "",
      absenttype: (event && event.absenttype) || "",
      id: (event && event.id) || 0,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
      category: Yup.string().required("Please Enter Your Billing Name"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateEvent = {
          id: event.id,
          title: values.title,
          classNames: values.category + " text-white",
          start: event.start,
        }
        // update event
        dispatch(onUpdateEvent(updateEvent))
        categoryValidation.resetForm()
      } else {
        const newEvent = {
          id: Math.floor(Math.random() * 100),
          title: values["title"],
          start: selectedDay ? selectedDay.date : new Date(),
          className: values["category"]
            ? values["category"] + " text-white"
            : "bg-primary text-white",
        }
        // save new event
        dispatch(onAddNewEvent(newEvent))
        categoryValidation.resetForm()
      }
      toggle()
    },
  })

  //const selectCalendarState = state => state.calendar
  const selectCalendarState = state => state.calendar

  const CalendarProperties = createSelector(selectCalendarState, Calendar => ({
    programenrollmentDetails: Calendar.programenrollmentDetails,
    events: Calendar.programenrollments,
    categories: Calendar.categories,
  }))

  const { events, categories, programenrollmentDetails } =
    useSelector(CalendarProperties)

  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()
  const [modalcategory, setModalcategory] = useState(false)
  const [selectedDay, setSelectedDay] = useState(0)
  const [tabValue, setTabValue] = useState("1")
  useEffect(() => {
    dispatch(onGetCategories())
    // dispatch(onGetCalendarProgramEnrollmentSchedule({ filter: moment().format("YYYY-MM-DD"), childID: 0 }))
    dispatch(
      onGetCalendarProgramEnrollmentSchedule({
        filter: moment().format("YYYY-MM-DD"),
        userID: userprofile?.RoleID != 1 ? userprofile?.UserID : 0,
      })
    )
    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    })
  }, [dispatch])

  useEffect(() => {
    if (!modalcategory && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({})
        setIsEdit(false)
      }, 500)
    }
  }, [modalcategory, event, programenrollmentDetails])

  /**
   * Handling the modal state
   */
  const toggle = () => {
    setTabValue("1")
    if (modalcategory) {
      setModalcategory(false)
      setEvent(null)
      setIsEdit(false)
    } else {
      setModalcategory(true)
    }
  }

  /**
   * Handling date click on calendar
   */
  const handleDateClick = arg => {
    const date = arg["date"]
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    const currectDate = new Date()
    const currentHour = currectDate.getHours()
    const currentMin = currectDate.getMinutes()
    const currentSec = currectDate.getSeconds()
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    )
    const modifiedData = { ...arg, date: modifiedDate }

    setSelectedDay(modifiedData)
    toggle()
  }

  /**
   * Handling click on event on calendar
   */
  const handleEventClick = arg => {
    const event = arg.event

    setEvent({
      id: event.id,
      title: event.title,
      title_category: event.title_category,
      start: event.start,
      end: event.end,
      className: event.classNames,
      category: event.classNames[0],
      event_category: event.classNames[0],
      absenttype: event?.extendedProps?.absenttype,
      classtitle: event?.extendedProps?.classtitle,
      absentComment: event?.extendedProps?.absentComment,
    })
    setDeleteId(event.id)
    setIsEdit(true)
    setModalcategory(true)
    setEditable(userprofile?.RoleID == 1 ? true : false)
    dispatch(
      onGetCalendarProgramEnrollmentScheduleDetail({
        programEnrollmentID: event.id,
        userID: userprofile?.RoleID != 1 ? userprofile?.UserID : 0,
      })
    )
    toggle()
  }

  /**
   * On delete event
   */
  const handleDeleteEvent = () => {
    if (deleteId) {
      dispatch(onDeleteEvent(deleteId))
    }
    setDeleteModal(false)
  }

  /**
   * On category darg event
   */
  const onDrag = event => {
    event.preventDefault()
  }

  /**
   * On calendar drop event
   */
  const onDrop = event => {
    const date = event["date"]
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    const currectDate = new Date()
    const currentHour = currectDate.getHours()
    const currentMin = currectDate.getMinutes()
    const currentSec = currectDate.getSeconds()
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    )

    const draggedEl = event.draggedEl
    const draggedElclass = draggedEl.className
    if (
      draggedEl.classList.contains("external-event") &&
      draggedElclass.indexOf("fc-event-draggable") == -1
    ) {
      const modifiedData = {
        id: Math.floor(Math.random() * 100),
        title: draggedEl.innerText,
        start: modifiedDate,
        className: draggedEl.className,
      }
      dispatch(onAddNewEvent(modifiedData))
    }
  }

  const renderEventContent = eventInfo => {
    return (
      <>
        <div style={{ color: "white" }}>
          <div>
            <b>
              {/* {moment(eventInfo.event.start).format("DD MMM Y")} -{" "}
              {moment(eventInfo.event.end).format("DD MMM Y")} */}
              {moment(eventInfo.event.start).format("DD MMM Y")}
              {eventInfo.event.extendedProps.serviceTypeID == 1
                ? " - " + moment(eventInfo.event.end).format("DD MMM Y")
                : ""}
            </b>{" "}
            <i>{eventInfo.event.title}</i>{" "}
          </div>

          {/* <div>
            {" "}
            <i>{eventInfo.event.title}</i>{" "}
          </div> */}
        </div>
      </>
    )
  }
  const toggleTab = tab => {
    if (tabValue !== tab) {
      setTabValue(tab)
    }
  }
  const NavItemTab = (navTitle, navValue) => {
    return (
      <NavItem>
        <NavLink
          style={{ cursor: "pointer" }}
          className={classnames({
            active: tabValue === navValue,
          })}
          onClick={() => {
            toggleTab(navValue)
          }}
        >
          {navTitle}
        </NavLink>
      </NavItem>
    )
  }
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Home" breadcrumbItem="Calendar" />
          <Row>
            <Col className="col-12">
              <Row>
                <Col xl={3} className="d-none">
                  <Card>
                    <CardBody>
                      <div className="d-grid">
                        <Button
                          color="primary"
                          className="font-16 btn-block"
                          onClick={toggle}
                        >
                          <i className="mdi mdi-plus me-1" />
                          Create New Event
                        </Button>
                      </div>

                      <div id="external-events" className="mt-2">
                        <br />
                        <p className="text-muted">
                          Drag and drop your event or click in the calendar
                        </p>
                        {categories &&
                          categories.map((category, i) => (
                            <div
                              className={`${category.type} external-event fc-event text-white`}
                              key={"cat-" + category.id}
                              draggable
                              onDrag={event => onDrag(event, category)}
                            >
                              <i className="mdi mdi-checkbox-blank-circle font-size-11 me-2" />
                              {category.title}
                            </div>
                          ))}
                      </div>

                      <Row className="justify-content-center mt-5">
                        <img
                          src={verification}
                          alt=""
                          className="img-fluid d-block"
                        />
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col xl={12}>
                  <Card>
                    <CardBody>
                      {/* fullcalendar control */}
                      <FullCalendar
                        plugins={[
                          BootstrapTheme,
                          dayGridPlugin,
                          listPlugin,
                          interactionPlugin,
                        ]}
                        slotDuration={"00:15:00"}
                        handleWindowResize={true}
                        themeSystem="bootstrap"
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                        }}
                        events={events}
                        editable={true}
                        droppable={true}
                        selectable={true}
                        dateClick={handleDateClick}
                        eventClick={handleEventClick}
                        drop={onDrop}
                        eventContent={renderEventContent}
                        eventClassNames={"bg-primary text-white"}
                      />
                    </CardBody>
                  </Card>

                  <Modal
                    isOpen={modalcategory}
                    className={
                      props.className +
                      " modal-dialog-centered modal-dialog-scrollable"
                    }
                    centered
                    size="lg"
                  >
                    <ModalHeader toggle={toggle} tag="h5">
                      {/* {!!isEdit ? "Edit Event" : "Add Event"} */}
                      Details
                    </ModalHeader>
                    <ModalBody className="p-4">
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          categoryValidation.handleSubmit()
                          return false
                        }}
                      >
                        <Row>
                          <Col xs="12">
                            <Nav tabs className="nav-tabs-custom nav-justified">
                              {NavItemTab("Key Details", "1")}
                              {NavItemTab("Curriculums", "2")}
                              {NavItemTab("Lesson Plans", "3")}
                              {NavItemTab("Notes", "4")}
                            </Nav>
                            <TabContent
                              activeTab={tabValue}
                              className="p-3 text-muted"
                            >
                              <TabPane tabId="1">
                                <Row>
                                  <Col className="col-12">
                                    <div className="mb-3">
                                      <Label className="form-label m-2">
                                        Date:{" "}
                                      </Label>
                                      {moment(
                                        categoryValidation?.values?.start
                                      ).format("DD MMM Y")}{" "}
                                      -{" "}
                                      {moment(
                                        categoryValidation?.values?.end
                                      ).format("DD MMM Y")}
                                    </div>
                                  </Col>
                                  <Col className="col-12">
                                    <div className="mb-3">
                                      <Label className="form-label m-2">
                                        Program:{" "}
                                      </Label>
                                      {categoryValidation?.values?.title}
                                    </div>
                                  </Col>
                                  <Col className="col-12">
                                    <div className="mb-3">
                                      <Label className="form-label m-2">
                                        Class:
                                      </Label>
                                      {categoryValidation?.values?.classtitle}
                                    </div>
                                  </Col>
                                  <Col className="col-12">
                                    <div className="mb-3">
                                      {programenrollmentDetails[0]?.Children &&
                                        programenrollmentDetails[0]?.Children?.map(
                                          (v, p) => {
                                            return (
                                              <>
                                                <div key={v.ChildID}>
                                                  <ProfilePicture
                                                    ObjectTypeID={
                                                      EnumObjectTypes.Children
                                                    }
                                                    RowID={v.RowID}
                                                    Title={
                                                      v.FirstName +
                                                      " " +
                                                      v.LastName
                                                    }
                                                    SubTitle={Calculate_Age(
                                                      v.DateOfBirth
                                                    )}
                                                  />
                                                </div>
                                              </>
                                            )
                                          }
                                        )}
                                    </div>
                                  </Col>
                                </Row>
                              </TabPane>

                              <TabPane tabId="2">
                                <Row>
                                  <Col sm="12">
                                    {/* programenrollmentDetails */}

                                    <CurriculumTab editable={editable} />
                                  </Col>
                                </Row>
                              </TabPane>
                              <TabPane tabId="3">
                                <Row>
                                  <Col sm="12">
                                    <LessonPlanTab editable={editable} />
                                  </Col>
                                </Row>
                              </TabPane>
                              <TabPane tabId="4">
                                <Row>
                                  <Col sm="12">
                                    <NoteTab editable={editable} />
                                  </Col>
                                </Row>
                              </TabPane>
                            </TabContent>
                          </Col>
                        </Row>
                        <Row className="mt-2 d-none">
                          <Col className="col-6">
                            {isEdit && (
                              <button
                                type="button"
                                className="btn btn-danger"
                                id="btn-delete-event"
                                onClick={() => {
                                  toggle()
                                  setDeleteModal(true)
                                }}
                              >
                                Delete
                              </button>
                            )}
                          </Col>

                          <Col className="col-6 text-end">
                            <button
                              type="button"
                              className="btn btn-light me-1"
                              onClick={toggle}
                            >
                              Close
                            </button>
                            <button
                              type="submit"
                              className="btn btn-success"
                              id="btn-save-event"
                            >
                              Save
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Calender.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

export default Calender
