import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"

import {
  Badge,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import Spinners from "components/Common/Spinner"
// import { ToastContainer } from "react-toastify"

import { getProfileDetail as onGetProfileDetail } from "store/actions"

import { getAdultVisibility, getStaffVisibility } from "helpers/token_helper"

//redux
import { useSelector, useDispatch } from "react-redux"
import { useDeepCompareEffect } from "hooks"
import Moment from "moment"
import ProfileModal from "./modal/ProfileModal"
import AddressModal from "./modal/AddressModal"
import ContactModal from "./modal/ContactModal"
import OperateHourModal from "./modal/OperateHourModal"
import ClosingModal from "./modal/ClosingModal"
import _ from "lodash"
import { EnumRoleTypes } from "helpers/enum_helper"

function Profile() {
  const dispatch = useDispatch()
  const [profileModal, setProfileModal] = useState(false)

  const [addressData, setAddressData] = useState({})
  const [addressEdit, setAddressEdit] = useState(false)
  const [addressModal, setAddressModal] = useState(false)

  const [contactData, setContactData] = useState({})
  const [contactEdit, setContactEdit] = useState(false)
  const [contactModal, setContactModal] = useState(false)
  const [contactType, setContactType] = useState(0)

  const [operatingHourData, setOperatingHourData] = useState([])
  const [operatingHourModal, setOperatingHourModal] = useState(false)

  const [closingEditData, setClosingEditData] = useState({})
  const [closingEdit, setClosingEdit] = useState(false)
  const [closingModal, setClosingModal] = useState(false)
  const [closingData, setClosingData] = useState([])

  const [editable, setEditable] = useState(true)

  const [filter, setFilter] = useState({
    year: parseInt(Moment().year()),
  })

  const onClickProfile = () => {
    setProfileModal(true)
  }

  const onAddAddressClick = () => {
    setAddressModal(true)
  }

  const onAddContactClick = arg => {
    setContactType(arg)
    setContactModal(true)
  }

  const onClosingClick = () => {
    setClosingModal(true)
  }

  const onOperatingHourClick = arg => {
    setOperatingHourData(arg)
    setOperatingHourModal(true)
  }

  const onEditClosingClick = arg => {
    setClosingEdit(true)
    setClosingEditData(arg)
    setClosingModal(true)
  }

  const onEditContactClick = arg => {
    setContactEdit(true)
    setContactType(arg.ContactTypeID)
    setContactData(arg)
    setContactModal(true)
  }

  const onEditAddressClick = arg => {
    setAddressData(arg)
    setAddressEdit(true)
    setAddressModal(true)
  }

  const onCloseModal = () => {
    setProfileModal(false)
    setAddressModal(false)
    setAddressData({})
    setAddressEdit(false)
    setContactModal(false)
    setContactData({})
    setContactEdit(false)
    setOperatingHourData([])
    setOperatingHourModal(false)
    setClosingModal(false)
    setClosingEdit(false)
    setClosingEditData({})
    setClosingModal(false)
  }

  useDeepCompareEffect(() => {
    if (
      getAdultVisibility() ||
      getStaffVisibility([
        EnumRoleTypes.Teaching_Staff,
        EnumRoleTypes.Non_Teaching_Staff,
        EnumRoleTypes.Accountant,
      ])
    ) {
      setEditable(false)
    }
    dispatch(onGetProfileDetail(1))
  }, [dispatch])

  //////////////////PROGRAM SCHEDULE STATE/////////////////////
  const { profile, address, contacts, openings, closings, loading } =
    useSelector(state => state.centerProfile)
  const [isLoading, setLoading] = useState(loading)
  //////////////////////////////////////

  useEffect(() => {
    function updateClosingData() {
      if (closings) {
        let data = closings.filter(function (item) {
          return Moment(item.ClosingDate).year() === filter.year
        })
        setClosingData(data)
      }
    }

    updateClosingData()
  }, [profile, closings, filter])

  return (
    <React.Fragment>
      {profileModal && (
        <ProfileModal show={profileModal} onCloseClick={() => onCloseModal()} />
      )}
      {addressModal && (
        <AddressModal
          onEdit={addressEdit}
          data={addressData}
          show={addressModal}
          profileID={profile.CenterProfileID}
          onCloseClick={() => onCloseModal()}
        />
      )}
      {contactModal && (
        <ContactModal
          profileID={profile.CenterProfileID}
          onEdit={contactEdit}
          data={contactData}
          show={contactModal}
          type={contactType}
          onCloseClick={() => onCloseModal()}
        />
      )}
      {operatingHourModal && (
        <OperateHourModal
          profileID={profile.CenterProfileID}
          availabilities={operatingHourData}
          show={operatingHourModal}
          onCloseClick={() => onCloseModal()}
        />
      )}
      {closingModal && (
        <ClosingModal
          profileID={profile.CenterProfileID}
          onEdit={closingEdit}
          data={closingEditData}
          show={closingModal}
          onCloseClick={() => onCloseModal()}
        />
      )}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Center Profile"
            breadcrumbItem="Manage Center Profile"
          />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <>
              <Card>
                <CardBody>
                  <div className="d-flex gap-3">
                    <h1>{profile.LongName}</h1>
                    <Link hidden={!editable}>
                      <i
                        className="bx bx-pencil font-size-18"
                        id="edittooltip"
                        onClick={() => {
                          onClickProfile()
                        }}
                      />
                      <UncontrolledTooltip placement="top" target="edittooltip">
                        Edit
                      </UncontrolledTooltip>
                    </Link>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div className="d-flex gap-3">
                    <h4>Addresses</h4>
                    <button
                      hidden={!editable}
                      type="button"
                      className="btn btn btn-primary btn-sm"
                      onClick={() => onAddAddressClick()}
                    >
                      Add
                    </button>
                  </div>
                  {address &&
                    address.map((v, p) => {
                      return (
                        <>
                          <div key={"address" + p} className="d-flex gap-3">
                            <i className="fa fa-lg fa-home"></i>
                            {v.Address}
                            {v.Cities.name ? ", " + v.Cities.name : ""}
                            {v.States.name ? ", " + v.States.name : ""}
                            {v.ZipCode ? ", " + v.ZipCode : ""}
                            {v.Countries.name ? ", " + v.Countries.name : ""}
                            <Link hidden={!editable}>
                              <i
                                className="bx bx-pencil font-size-18"
                                id="edittooltip"
                                onClick={() => {
                                  onEditAddressClick(v)
                                }}
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target="edittooltip"
                              >
                                Edit
                              </UncontrolledTooltip>
                            </Link>
                          </div>
                        </>
                      )
                    })}
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div className="d-flex gap-3">
                    <h4>Contact Information</h4>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        hidden={!editable}
                        href="#!"
                        tag="a"
                        className="btn btn btn-primary btn-sm"
                      >
                        Add
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={e => onAddContactClick(2)}
                          href="#"
                        >
                          Phone
                        </DropdownItem>
                        <DropdownItem
                          onClick={e => onAddContactClick(1)}
                          href="#"
                        >
                          Email
                        </DropdownItem>
                        <DropdownItem
                          onClick={e => onAddContactClick(3)}
                          href="#"
                        >
                          Website
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  {contacts &&
                    contacts.map((v, p) => {
                      return (
                        <>
                          <div key={"contact" + p} className="d-flex gap-3">
                            <i className="fa fa-lg fa-at"></i>
                            {v.Contact}
                            <Link hidden={!editable}>
                              <i
                                className="bx bx-pencil font-size-18"
                                id="edittooltip"
                                onClick={() => {
                                  onEditContactClick(v)
                                }}
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target="edittooltip"
                              >
                                Edit
                              </UncontrolledTooltip>
                            </Link>
                          </div>
                        </>
                      )
                    })}
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="6">
                      <div className="d-flex gap-3">
                        <h4>Operating Hours</h4>
                        <button
                          hidden={!editable}
                          type="button"
                          className="btn btn btn-primary btn-sm"
                          onClick={() => onOperatingHourClick(openings)}
                        >
                          Edit
                        </button>
                      </div>
                      <Row>
                        {openings &&
                          openings.map((item, index) => {
                            return (
                              <div
                                key={`outer${index}`}
                                className="d-flex justify-content-between align-items-center mb-3"
                              >
                                <Col sm="2">{item.Availability + "s:"}</Col>
                                <Col sm="4">
                                  {item.CenterOpeningDetail &&
                                    item.CenterOpeningDetail.map((inner, i) => {
                                      return (
                                        <>
                                          {Moment(inner.StartTime).format(
                                            "h:mm a"
                                          ) +
                                            "-" +
                                            Moment(inner.EndTime).format(
                                              "h:mm a"
                                            )}
                                          <br />
                                        </>
                                      )
                                    })}
                                </Col>
                              </div>
                            )
                          })}
                      </Row>
                    </Col>
                    <Col sm="6">
                      <div className="d-flex gap-3">
                        <h4>Center Closings</h4>
                        <div role="group" className="btn-group btn-group-sm">
                          <button type="button" className="btn btn-secondary">
                            <div>
                              <i
                                onClick={() =>
                                  setFilter({ year: filter.year - 1 })
                                }
                                className="fa fa-chevron-left"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </button>
                          <button type="button" className="btn btn-secondary">
                            <div>
                              <i
                                onClick={() =>
                                  setFilter({ year: filter.year + 1 })
                                }
                                className="fa fa-chevron-right"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </button>
                        </div>
                        <button
                          hidden={!editable}
                          type="button"
                          className="btn btn btn-primary btn-sm"
                          onClick={() => onClosingClick()}
                        >
                          +
                        </button>
                      </div>
                      {filter.year}
                      <div className="mt-2">
                        {_.isEmpty(closingData)
                          ? "No closed days in " + filter.year
                          : closingData &&
                            closingData.map((item, index) => {
                              return (
                                <div key={`outer${index}`} className="mb-3">
                                  {Moment(item.ClosingDate).format(
                                    "DD MMM yyyy"
                                  )}
                                  <Link hidden={!editable}>
                                    <i
                                      className="bx bx-pencil font-size-18"
                                      id="edittooltip"
                                      onClick={() => {
                                        onEditClosingClick(item)
                                      }}
                                    />
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="edittooltip"
                                    >
                                      Edit
                                    </UncontrolledTooltip>
                                  </Link>
                                  <div>{item.Description}</div>
                                </div>
                              )
                            })}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </>
          )}
        </div>
      </div>
      {/* <ToastContainer /> */}
    </React.Fragment>
  )
}
Profile.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Profile
