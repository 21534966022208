import {
  GET_DOCTOR_SCHEDULES,
  GET_DOCTOR_SCHEDULES_SUCCESS,
  GET_DOCTOR_SCHEDULES_FAIL,
  ADD_DOCTOR_SCHEDULES,
  ADD_DOCTOR_SCHEDULES_SUCCESS,
  ADD_DOCTOR_SCHEDULES_FAIL,
  DELETE_DOCTOR_SCHEDULE,
  DELETE_DOCTOR_SCHEDULE_SUCCESS,
  DELETE_DOCTOR_SCHEDULE_FAIL,
  DOCTOR_MEETING_SYNC,
  DOCTOR_MEETING_SYNC_SUCCESS,
  DOCTOR_MEETING_SYNC_FAIL,
} from "./actionTypes"

export const getDoctorSchedules = filter => ({
  type: GET_DOCTOR_SCHEDULES,
  filter,
})

export const getDoctorSchedulesSuccess = doctorSchedules => ({
  type: GET_DOCTOR_SCHEDULES_SUCCESS,
  payload: doctorSchedules,
})

export const getDoctorSchedulesFail = error => ({
  type: GET_DOCTOR_SCHEDULES_FAIL,
  payload: error,
})

export const addDoctorSchedule = doctorSchedule => ({
  type: ADD_DOCTOR_SCHEDULES,
  payload: doctorSchedule,
})

export const addDoctorScheduleSuccess = doctorSchedule => ({
  type: ADD_DOCTOR_SCHEDULES_SUCCESS,
  payload: doctorSchedule,
})

export const addDoctorScheduleFail = error => ({
  type: ADD_DOCTOR_SCHEDULES_FAIL,
  payload: error,
})

export const deleteDoctorSchedule = doctorSchedule => ({
  type: DELETE_DOCTOR_SCHEDULE,
  payload: doctorSchedule,
})

export const deleteDoctorScheduleSuccess = doctorSchedule => ({
  type: DELETE_DOCTOR_SCHEDULE_SUCCESS,
  payload: doctorSchedule,
})

export const deleteDoctorScheduleFail = error => ({
  type: DELETE_DOCTOR_SCHEDULE_FAIL,
  payload: error,
})

export const doctorMeetingSync = doctorScheduleID => ({
  type: DOCTOR_MEETING_SYNC,
  payload: doctorScheduleID,
})

export const doctorMeetingSyncSuccess = syncMessage => ({
  type: DOCTOR_MEETING_SYNC_SUCCESS,
  payload: syncMessage,
})

export const doctorMeetingSyncFail = error => ({
  type: DOCTOR_MEETING_SYNC_FAIL,
  payload: error,
})
