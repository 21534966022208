import PropTypes, { number, object } from "prop-types"
import React, { useState, useEffect } from "react"
//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Modal,
  ModalBody,
  InputGroup,
  FormFeedback,
  ModalHeader,
  ModalFooter,
  Table,
  Card,
  CardBody,
  Badge,
  UncontrolledTooltip,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"
import { Link, useNavigate } from "react-router-dom"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//Date filter
import Moment from "moment"

import {
  getPrograms as onGetPrograms,
  getProgramScheduleDetail as onGetProgramScheduleDetail,
  addEnrollment as onAddEnrollment,
} from "store/actions"

import { isEmpty } from "lodash"
import EnrollmentFields from "components/Common/Enrollment"
import { RESET_PROGRAM } from "store/pricing/program/actionTypes"
import { EnumObjectTypes, EnumServiceTypes } from "helpers/enum_helper"
import ProfilePicture from "components/Common/ProfilePicture"
import { Calculate_Age } from "helpers/method_helper"

const formdefault = {
  ProgramID: 0,
  DayID: 0,
  ServiceTypeID: EnumServiceTypes.Enroll_In_Program,
  ProgramSchedules: [],
}

const EnrollModal = ({ show, onCloseClick }) => {
  const dispatch = useDispatch()
  const [state, setState] = useState({
    programSelected: false,
  })

  //////////////////CHILD STATE/////////////////////
  const { child } = useSelector(state => state.child)
  //////////////////////////////////////

  const updateState = data => setState(previous => ({ ...previous, ...data }))

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    ProgramID: yup.number(),
    DayID: yup.number(),
    Children: yup
      .array()
      .required("Required")
      .transform((_, val) => (val ? val : null)),
    ProgramSchedules: yup.array().of(
      yup.object().shape({
        DayID: yup.number(),
        Name: yup.string(),
        IsLinked: yup.bool(),
        Times: yup.array().of(
          yup.object().shape({
            StartTime: yup.string(),
            EndTime: yup.string(),
          })
        ),
      })
    ),
    EnrollmentTypes: yup
      .object()
      .required("Required")
      .transform((_, val) => (val ? val : null)),
    IsSchedulePass: yup.string().required("Required"),
    StartDate: yup.string().required("Required"),
    EndDate: yup.string(),
    Classes: yup
      .object()
      .required("Required")
      .transform((_, val) => (val ? val : null)),
    ServiceTypeID: yup.number(),
    AddOns: yup.array(),
    Charges: yup.array(),
    Credits: yup.array(),
    AppliedDays: yup.array(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, watch, setValue, getValues, trigger } =
    methods
  const { errors, isValid } = formState

  useEffect(() => {
    dispatch(onGetPrograms())
  }, [dispatch])

  //////////////////PROGRAM SCHEDULE STATE/////////////////////
  const { programs, program, loading } = useSelector(state => state.program)
  const [isLoading, setLoading] = useState(loading)
  //////////////////////////////////////

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      setValue("ProgramID", program.ProgramID)
      setValue("DayID", program.ProgramSchedules[0].Days)
      //console.log(getValues())
      dispatch(onAddEnrollment(getValues()))
      onCloseClick()
    }
  }

  const onBackClick = () => {
    updateState({ programSelected: false })
    dispatch({ type: RESET_PROGRAM })
  }

  const EnrollClick = (rowID, programScheduleID) => {
    dispatch(onGetProgramScheduleDetail(rowID, programScheduleID))
    updateState({ programSelected: true })
  }

  const onClosed = () => {
    reset()
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      onClosed={() => onClosed()}
      centered={true}
    >
      <ModalHeader className="d-flex justify-content-center">
        Enroll into Program
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <FormProvider {...methods}>
          {!state.programSelected && (
            <div className="table-responsive">
              <Table className="table mb-0">
                {programs &&
                  programs.map((v, p) => {
                    return (
                      <>
                        <tbody>
                          <tr className="table-light" key={"program" + p}>
                            <td colSpan={3}>
                              <div>
                                <h4 style={{ display: "inline-block" }}>
                                  {v.Name}
                                </h4>
                                <Badge className="bg-success ms-1">
                                  {v.BillingCycle}
                                </Badge>
                              </div>
                              <div className="font-weight-normal">
                                {v.WeekDays.map((f, i) => {
                                  return i === v.WeekDays.length - 1
                                    ? f.Name
                                    : f.Name + ", "
                                })}
                                <span>
                                  {` from ` +
                                    Moment(v.StartTime).format("hh:mm A") +
                                    " to " +
                                    Moment(v.EndTime).format("hh:mm A")}
                                </span>
                              </div>
                            </td>
                          </tr>

                          {v.ProgramSchedules.filter(
                            e => e.IsLinked == true
                          ).map((z, s) => {
                            return (
                              <tr key={"schedule" + s}>
                                <td>
                                  {z.Days + (z.Days === 1 ? " day" : " days")}
                                </td>
                                <td width="100">{`$` + z.Price.toFixed(2)}</td>
                                <td width="100">
                                  <Button
                                    type="button"
                                    color="primary"
                                    className="btn btn-sm"
                                    onClick={() => {
                                      EnrollClick(v.RowID, z.ProgramScheduleID)
                                    }}
                                  >
                                    Select
                                  </Button>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </>
                    )
                  })}
              </Table>
            </div>
          )}
          {state.programSelected && !isEmpty(program) ? (
            <>
              <ProfilePicture
                ObjectTypeID={EnumObjectTypes.Children}
                RowID={child.RowID}
                Title={child.Name}
                SubTitle={Calculate_Age(child.DateOfBirth)}
              />
              <br />
              <EnrollmentFields ProgramSelected={true} />
            </>
          ) : (
            ""
          )}
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <Row className="container">
          <Col lg={2}>
            {state.programSelected && (
              <button
                type="button"
                className="btn btn btn-secondary"
                onClick={() => onBackClick()}
              >
                Back
              </button>
            )}
          </Col>
          <Col lg={10}>
            <div className="text-end">
              {state.programSelected && (
                <button
                  type="button"
                  className="btn btn btn-primary"
                  onClick={() => onSaveClick()}
                >
                  Save
                </button>
              )}{" "}
              <button
                type="button"
                className="btn btn btn-secondary"
                onClick={onCloseClick}
              >
                Cancel
              </button>
            </div>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

EnrollModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.bool,
}

export default EnrollModal
