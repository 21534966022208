import React, { useEffect, useState, Fragment, useMemo } from "react"
import {
  Table,
  Row,
  Col,
  Tooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Moment from "moment"
// import { ToastContainer } from "react-toastify"
import { isEmpty } from "lodash"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

import {
  getDoctorSchedules as onGetDoctorSchedules,
  getMeetingStartURL as onGetMeetingStartURL,
} from "store/actions"
import Spinners from "components/Common/Spinner"
import Paginations from "components/Common/Pagination"
import { EnumRoles } from "helpers/enum_helper"

//custom hooks
import { useUserDetail } from "hooks"

function TelevisitSchedule() {
  const dispatch = useDispatch()
  const { userprofile } = useUserDetail()
  const [tooltipOpen, setTooltipOpen] = useState({})
  const [filter, setFilter] = useState({
    date: Moment().format("YYYY-MM-DD"),
    objectID: 0,
    roleID: 0,
  })

  // pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [dataList, setDataList] = useState()
  const perPageData = 5
  const indexOfLast = currentPage * perPageData
  const indexOfFirst = indexOfLast - perPageData

  const updateState = data => setFilter(previous => ({ ...previous, ...data }))

  useEffect(() => {
    updateState({ objectID: userprofile?.UserID, roleID: userprofile?.RoleID })
  }, [userprofile])

  useEffect(() => {
    if (filter.objectID > 0) {
      dispatch(onGetDoctorSchedules(filter))
    }
  }, [filter])

  const toggleTooltip = id => {
    setTooltipOpen(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }

  //////////////////STAFF SCHEDULE STATE/////////////////////
  const selectDoctorScheduleState = state => state.doctorSchedules
  const DoctorScheduleProperties = createSelector(
    selectDoctorScheduleState,
    e => ({
      data: e.doctorSchedules,
      loading: e.loading,
      column: e.column,
    })
  )
  const { data, loading, column } = useSelector(DoctorScheduleProperties)
  const [isLoading, setLoading] = useState(loading)
  //////////////////////////////////////

  //////////////////HELPER STATE/////////////////////
  const { meetingStartURL } = useSelector(state => state.helper)
  //////////////////////////////////////

  function handleDate(param) {
    setFilter({
      date: param,
      objectID: userprofile?.UserID,
      roleID: userprofile?.RoleID,
    })
  }

  const onStartClick = (event, meetingKey) => {
    event.preventDefault()
    dispatch(onGetMeetingStartURL(meetingKey))
  }

  useEffect(() => {
    if (meetingStartURL) {
      window.open(meetingStartURL, "_blank") // Open the meeting in a new tab
    }
  }, [meetingStartURL])

  const onJoinClick = (event, meetingJoinURL) => {
    event.preventDefault()
    window.open(meetingJoinURL, "_blank") // Open the meeting in a new tab
  }

  const currentdata = useMemo(
    () => data ?? data.slice(indexOfFirst, indexOfLast),
    [data, indexOfFirst, indexOfLast]
  )

  useEffect(() => {
    setDataList(currentdata)
  }, [currentdata])

  if (isLoading) {
    return <Spinners setLoading={setLoading} />
  }

  return (
    <Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Schedule" breadcrumbItem="Televisit Schedule" />
          <Row className="mb-2">
            <Col sm="12">
              <Card>
                <CardBody>
                  <div className="text-sm-end">
                    <div className="btn-group" role="group">
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio4"
                        autoComplete="off"
                        onClick={() =>
                          handleDate(
                            Moment(filter.date)
                              .subtract(7, "days")
                              .format("YYYY-MM-DD")
                          )
                        }
                      />
                      <label
                        className="btn btn-outline-secondary"
                        htmlFor="btnradio4"
                      >
                        <i className="fas fa-angle-left" />
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio5"
                        autoComplete="off"
                        defaultChecked
                        onClick={() =>
                          handleDate(Moment().format("YYYY-MM-DD"))
                        }
                      />
                      <label
                        className="btn btn-outline-secondary"
                        htmlFor="btnradio5"
                      >
                        Today
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio6"
                        autoComplete="off"
                        onClick={() =>
                          handleDate(
                            Moment(filter.date)
                              .add(7, "days")
                              .format("YYYY-MM-DD")
                          )
                        }
                      />
                      <label
                        className="btn btn-outline-secondary"
                        htmlFor="btnradio6"
                      >
                        <i className="fas fa-angle-right" />
                      </label>
                    </div>
                  </div>
                  {filter.roleID === EnumRoles.Staff && (
                    <Table className="align-middle table-nowrap table-check table-bordered">
                      <thead className="table-light">
                        <tr>
                          {column.map((row, index) => (
                            <th
                              key={"th" + index}
                              className={row === "DoctorID" ? "d-none" : ""}
                            >
                              <div className="m-0">{row}</div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {dataList.map((n, index) => {
                          return (
                            <Fragment key={"dl" + index}>
                              <tr style={{ height: "100px" }}>
                                {column.map((v, index) => {
                                  return (
                                    <td
                                      key={"clm" + index}
                                      className={
                                        v === "DoctorID"
                                          ? "d-none"
                                          : "align-top"
                                      }
                                    >
                                      {/* {console.log("Inner Loop", n[v])} */}
                                      {v !== "Name" &&
                                      v !== "DoctorID" &&
                                      v !== "id" &&
                                      n[v] !== "" &&
                                      n[v] !== undefined
                                        ? JSON.parse(n[v]).map(w => (
                                            <div
                                              className="border rounded p-2 mb-2 w-100 cursor-pointer"
                                              key={"dv" + w.DoctorSchedulingID}
                                            >
                                              <div
                                                className="float-end"
                                                onClick={() =>
                                                  onClickDelete(
                                                    w.DoctorSchedulingID
                                                  )
                                                }
                                                hidden={
                                                  w.IsCompleted || w.IsPassed
                                                }
                                              >
                                                <i className="bx bx-trash bx-sm"></i>
                                              </div>
                                              <h6>
                                                {" "}
                                                {w.Title}
                                                <i
                                                  hidden={!w.IsCompleted}
                                                  className="p-1 bx bx-check"
                                                ></i>
                                                <span
                                                  style={{ width: "30px" }}
                                                  className="p-2"
                                                  hidden={
                                                    w.IsCompleted || w.IsPassed
                                                  }
                                                >
                                                  <i
                                                    className="bx bx-notepad"
                                                    id={`tooltip-${w.DoctorSchedulingID}`}
                                                  ></i>
                                                  <Tooltip
                                                    placement="bottom"
                                                    isOpen={
                                                      tooltipOpen[
                                                        w.DoctorSchedulingID
                                                      ] || false
                                                    }
                                                    target={`tooltip-${w.DoctorSchedulingID}`}
                                                    toggle={() => {
                                                      toggleTooltip(
                                                        w.DoctorSchedulingID
                                                      )
                                                    }}
                                                  >
                                                    <h5>
                                                      {`Meeting Key : ` +
                                                        w.MeetingKey}
                                                    </h5>
                                                    <h5>
                                                      {`Meeting Passcode : ` +
                                                        w.MeetingPassword}
                                                    </h5>
                                                  </Tooltip>
                                                </span>
                                              </h6>

                                              <span>
                                                {Moment(w.StartTime).format(
                                                  "hh:mm A"
                                                ) +
                                                  " - " +
                                                  Moment(w.EndTime).format(
                                                    "hh:mm A"
                                                  )}
                                              </span>
                                              <div
                                                hidden={
                                                  w.IsCompleted || w.IsPassed
                                                }
                                              >
                                                <button
                                                  type="button"
                                                  className="btn btn-sm btn-primary"
                                                  onClick={e =>
                                                    onStartClick(
                                                      e,
                                                      w.MeetingKey
                                                    )
                                                  }
                                                >
                                                  Start
                                                </button>
                                              </div>
                                            </div>
                                          ))
                                        : n[v]}
                                    </td>
                                  )
                                })}
                              </tr>
                            </Fragment>
                          )
                        })}
                      </tbody>
                    </Table>
                  )}
                  {filter.roleID === EnumRoles.Adult && (
                    <Table className="align-middle table-nowrap table-check table-bordered">
                      <thead className="table-light">
                        <tr>
                          {column.map((row, index) => (
                            <th
                              key={"th" + index}
                              className={row === "ChildID" ? "d-none" : ""}
                            >
                              <div className="m-0">{row}</div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {dataList.map((n, index) => {
                          return (
                            <Fragment key={"dl" + index}>
                              <tr style={{ height: "100px" }}>
                                {column.map((v, index) => {
                                  return (
                                    <td
                                      key={"clm" + index}
                                      className={
                                        v === "ChildID" ? "d-none" : "align-top"
                                      }
                                    >
                                      {/* {console.log("Inner Loop", n[v])} */}
                                      {v !== "Name" &&
                                      v !== "ChildID" &&
                                      v !== "id" &&
                                      n[v] !== "" &&
                                      n[v] !== undefined
                                        ? JSON.parse(n[v]).map(w => (
                                            <div
                                              className="border rounded p-2 mb-2 w-100 cursor-pointer"
                                              key={"dv" + w.DoctorSchedulingID}
                                            >
                                              <h6>
                                                {" "}
                                                {w.Title}
                                                <i
                                                  hidden={!w.IsCompleted}
                                                  className="p-1 bx bx-check"
                                                ></i>
                                                <span
                                                  style={{ width: "30px" }}
                                                  className="p-2"
                                                  hidden={
                                                    w.IsCompleted || w.IsPassed
                                                  }
                                                >
                                                  <i
                                                    className="bx bx-notepad"
                                                    id={`tooltip-${w.DoctorSchedulingID}`}
                                                  ></i>
                                                  <Tooltip
                                                    placement="bottom"
                                                    isOpen={
                                                      tooltipOpen[
                                                        w.DoctorSchedulingID
                                                      ] || false
                                                    }
                                                    target={`tooltip-${w.DoctorSchedulingID}`}
                                                    toggle={() => {
                                                      toggleTooltip(
                                                        w.DoctorSchedulingID
                                                      )
                                                    }}
                                                  >
                                                    <h5>
                                                      {`Meeting Key : ` +
                                                        w.MeetingKey}
                                                    </h5>
                                                    <h5>
                                                      {`Meeting Passcode : ` +
                                                        w.MeetingPassword}
                                                    </h5>
                                                  </Tooltip>
                                                </span>
                                              </h6>

                                              <span>
                                                {Moment(w.StartTime).format(
                                                  "hh:mm A"
                                                ) +
                                                  " - " +
                                                  Moment(w.EndTime).format(
                                                    "hh:mm A"
                                                  )}
                                              </span>
                                              <div
                                                hidden={
                                                  w.IsCompleted || w.IsPassed
                                                }
                                              >
                                                <button
                                                  type="button"
                                                  className="btn btn-sm btn-primary"
                                                  onClick={e =>
                                                    onJoinClick(
                                                      e,
                                                      w.WebinarJoinURL
                                                    )
                                                  }
                                                >
                                                  Join
                                                </button>
                                              </div>
                                            </div>
                                          ))
                                        : n[v]}
                                    </td>
                                  )
                                })}
                              </tr>
                            </Fragment>
                          )
                        })}
                      </tbody>
                    </Table>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </Fragment>
  )
}
export default TelevisitSchedule
