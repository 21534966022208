import React, { useEffect, useMemo, useState } from "react"
import PropTypes, { func } from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import Moment from "moment"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"

import {} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Badge,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
} from "reactstrap"
import Spinners from "components/Common/Spinner"
// import { ToastContainer } from "react-toastify"
import { getBasicSettings as onGetBasicSettings } from "store/actions"
import RightSidebar from "components/CommonForBoth/RightSidebar"
import { isEmpty } from "lodash"
import EmptyContainer from "components/Common/EmptyContainer"
import BasicSettingTab from "./tabs/BasicSettingTab"
import LoginImageTab from "./tabs/LoginImageTab"
import SideBarImageTab from "./tabs/SideBarImageTab"
import ZoomTab from "./tabs/ZoomTab"
import PaypalTab from "./tabs/PaypalTab"
import StripeTab from "./tabs/StripeTab"
import classnames from "classnames"

//custom hooks
import { useDeepCompareEffect } from "hooks"
function Theme() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [header, setHeader] = useState("")
  const [tabValue, setTabValue] = useState("1")

  //////////////////PROGRAM SCHEDULE STATE/////////////////////
  const { basicSetting, loading } = useSelector(state => state.Layout)
  const [isLoading, setLoading] = useState(loading)
  //////////////////////////////////////

  useDeepCompareEffect(() => {
    dispatch(onGetBasicSettings())
  }, [dispatch])

  useEffect(() => {
    dispatch(onGetBasicSettings())
  }, [dispatch])
  const toggleTab = tab => {
    if (tabValue !== tab) {
      setTabValue(tab)
    }
  }
  const NavItemTab = (navTitle, navValue) => {
    return (
      <NavItem>
        <NavLink
          style={{ cursor: "pointer" }}
          className={classnames({
            active: tabValue === navValue,
          })}
          onClick={() => {
            toggleTab(navValue)
          }}
        >
          {navTitle}
        </NavLink>
      </NavItem>
    )
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Branding" breadcrumbItem="Manage Branding" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    {NavItemTab("Theme", "1")}
                    {NavItemTab("Basic Settings", "2")}
                    {NavItemTab("Login Logo", "3")}
                    {NavItemTab("SideBar Logo", "4")}
                    {NavItemTab("Zoom", "5")}
                    {NavItemTab("Paypal", "6")}
                    {NavItemTab("Stripe", "7")}
                  </Nav>

                  <TabContent activeTab={tabValue} className="p-3 text-muted">
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <Card>
                            <CardBody>
                              <RightSidebar />
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <BasicSettingTab />
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12">
                          <LoginImageTab />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="4">
                      <Row>
                        <Col sm="12">
                          <SideBarImageTab />
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tabId="5">
                      <Row>
                        <Col sm="12">
                          <ZoomTab />
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tabId="6">
                      <Row>
                        <Col sm="12">
                          <PaypalTab />
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tabId="7">
                      <Row>
                        <Col sm="12">
                          <StripeTab />
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Theme" breadcrumbItem="Manage Theme" />
          <Col xs="12">
            <Card>
              <CardBody>
                <RightSidebar />
              </CardBody>
            </Card>
          </Col>
        </div>
      </div> */}
      {/* <ToastContainer /> */}
    </React.Fragment>
  )
}
Theme.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Theme
