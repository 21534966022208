import axios from "axios"
import { del, get, post, put, getFile } from "./api_helper"
import * as url from "./url_helper"
import qs from "qs"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postLogin = data =>
  post(
    url.POST_LOGIN,
    qs.stringify({
      ...data,
      grant_type: "password",
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  )
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data)

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)

//update password
export const updatePassword = profile => put(url.CHANGE_PASSWORD_URL, profile)

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data)

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data)

// get Adults
export const getAdults = () => get(url.ADULT_URL)
//delete adult
export const deleteAdult = adultID => del(url.ADULT_URL + `/${adultID}`)
//add adult
export const addNewAdult = adult => post(url.ADD_NEW_ADULT, adult)
//get adult detail
export const getAdultDetail = adultID => get(url.ADULT_URL + `/${adultID}`)
//update adult
export const updateAdult = adult =>
  put(url.ADULT_URL + `/${adult.AdultID}`, adult)
//update Adult Photo
export const updateAdultPhoto = adult =>
  post(url.PHOTO_URL + `/${adult.AdultID}`, adult)
// get adult relations
export const getAdultRelations = adultID =>
  get(url.ADULT_RELATIONS_URL + `/${adultID}`)
//update adult notes
export const updateAdultNotes = obj =>
  put(url.ADULT_NOTES_URL + `/${obj.AdultID}`, obj)
//add child
export const addChildByAdult = child => post(url.ADD_CHILD_BY_ADULT, child)

// get Children
export const getChildren = () => get(url.CHILD_URL)
//delete child
export const deleteChild = childID => del(url.CHILD_URL + `/${childID}`)
//add child
export const addNewChild = child => post(url.ADD_NEW_CHILD, child)
//get child detail
export const getChildDetail = childID => get(url.CHILD_URL + `/${childID}`)
//update child
export const updateChild = child =>
  put(url.CHILD_URL + `/${child.ChildID}`, child)
//update Child Photo
export const updateChildPhoto = child =>
  post(url.PHOTO_URL + `/${child.ChildID}`, child)

// get child schedules
export const getChildSchedules = filter =>
  get(url.CHILD_SCHEDULES_URL + `/${filter.date}` + `/${filter.childID}`)
// add child schedules
export const addChildSchedule = childSchedule =>
  post(url.ADD_CHILD_SCHEDULES_URL, childSchedule)
//delete child schedule
export const deleteChildSchedule = childSchedulingID =>
  del(url.CHILD_SCHEDULES_URL + `/${childSchedulingID}`)

// get Tags
export const getTags = childId => get(url.TAGS_URL + `/${childId}`)
//delete tag
export const deleteTag = tagId => del(url.TAGS_URL + `/${tagId}`)
//add tag
export const addNewTag = tag => post(url.ADD_TAG_URL, tag)
// get tag category
export const getTagCategory = () => get(url.GET_TAG_CATEGORY)

// get Messages
export const getMessages = childId => get(url.MESSAGE_URL + `/${childId}`)
// get Message Posts
export const getMessagePosts = messageId =>
  get(url.MESSAGE_POSTS_URL + `/${messageId}`)
// add Message Posts
export const addMessagePost = pst => post(url.MESSAGE_POSTS_URL, pst)
//add Message
export const addNewMessage = message => post(url.ADD_NEW_MESSAGE_URL, message)
// get Awaited Messages
export const getAwaitedMessages = () => get(url.AWAITED_MESSAGE_URL)
// update Message Approval
export const updateMessageApproval = message =>
  post(url.APPROVAL_MESSAGE_URL, message)
//delete Message
export const deleteMessage = messageId => del(url.MESSAGE_URL + `/${messageId}`)
//delete Message Post
export const deleteMessagePost = messagePostId =>
  del(url.MESSAGE_POSTS_URL + `/${messagePostId}`)

// get Classes
export const getClasses = () => get(url.CLASS_URL)
//delete class
export const deleteClass = classID => del(url.CLASS_URL + `/${classID}`)
//add class
export const addNewClass = cls => post(url.ADD_NEW_CLASS, cls)
//get class detail
export const getClassDetail = classID => get(url.CLASS_URL + `/${classID}`)
//update class
export const updateClass = cls => put(url.CLASS_URL + `/${cls.ClassID}`, cls)
// get class types
export const getClassTypes = () => get(url.CLASS_TYPE_URL)
// get class schedules
export const getClassSchedules = filter =>
  get(url.CLASS_SCHEDULES_URL + `/${filter.date}` + `/${filter.classID}`)
// add class schedules
export const addClassSchedule = classSchedule =>
  post(url.ADD_CLASS_SCHEDULES_URL, classSchedule)
//delete class schedule
export const deleteClassSchedule = classSchedulingID =>
  del(url.CLASS_SCHEDULES_URL + `/${classSchedulingID}`)
// get class programs
export const getClassPrograms = classID =>
  get(url.CLASS_PROGRAMS_URL + `/${classID}`)
//add class notes
export const addClassNote = cls => post(url.ADD_CLASS_NOTES, cls)
//delete class notes
export const deleteClassNote = classNoteId =>
  del(url.ADD_CLASS_NOTES + `/${classNoteId}`)
//get class happening types
export const getClassHappeningTypes = () => get(url.CLASS_HAPPENING_TYPE_URL)
//get class happenings
export const getClassHappenings = obj =>
  get(url.CLASS_HAPPENING_URL + `/${obj.classID}/${obj.filterDate}`)
//delete class happening
export const deleteClassHappening = classHappeningID =>
  del(url.CLASS_HAPPENING_URL + `/${classHappeningID}`)
//add class happening
export const addClassHappening = classHappening =>
  post(url.ADD_CLASS_HAPPENING_URL, classHappening)

// get Meals
export const getMeals = () => get(url.MEAL_URL)
//delete meal
export const deleteMeal = mealID => del(url.MEAL_URL + `/${mealID}`)
//add meal
export const addNewMeal = meal => post(url.ADD_NEW_MEAL, meal)
//get meal detail
export const getMealDetail = mealID => get(url.MEAL_URL + `/${mealID}`)
//update meal
export const updateMeal = meal => put(url.MEAL_URL + `/${meal.MealID}`, meal)
// get meal schedules
export const getMealSchedules = filter =>
  get(url.MEAL_SCHEDULES_URL + `/${filter.date}` + `/${filter.childID}`)

// add meal schedules
export const addMealSchedule = mealSchedule =>
  post(url.ADD_MEAL_SCHEDULES_URL, mealSchedule)
//delete meal schedule
export const deleteMealSchedule = mealSchedulingID =>
  del(url.MEAL_SCHEDULES_URL + `/${mealSchedulingID}`)

// get Buses
export const getBuses = () => get(url.BUS_URL)
//delete bus
export const deleteBus = busID => del(url.BUS_URL + `/${busID}`)
//add meal
export const addNewBus = bus => post(url.ADD_NEW_BUS, bus)
//get bus detail
export const getBusDetail = busID => get(url.BUS_URL + `/${busID}`)
//update bus
export const updateBus = bus => put(url.BUS_URL + `/${bus.BusID}`, bus)
// get bus schedules
export const getBusSchedules = filter =>
  get(url.BUS_SCHEDULES_URL + `/${filter.date}` + `/${filter.busID}`)
// add bus schedules
export const addBusSchedule = busSchedule =>
  post(url.ADD_BUS_SCHEDULES_URL, busSchedule)
//delete bus schedule
export const deleteBusSchedule = busSchedulingID =>
  del(url.BUS_SCHEDULES_URL + `/${busSchedulingID}`)
// get child bus schedules
export const getChildBusSchedules = filter =>
  get(url.CHILD_BUS_SCHEDULES_URL + `/${filter.date}` + `/${filter.objectID}`)
// get staff bus schedules
export const getStaffBusSchedules = filter =>
  get(url.STAFF_BUS_SCHEDULES_URL + `/${filter.date}` + `/${filter.objectID}`)

// get Rooms
export const getRooms = () => get(url.ROOM_URL)
//delete room
export const deleteRoom = roomID => del(url.ROOM_URL + `/${roomID}`)
//add room
export const addNewRoom = room => post(url.ADD_NEW_ROOM, room)
//get room detail
export const getRoomDetail = roomID => get(url.ROOM_URL + `/${roomID}`)
//update room
export const updateRoom = room => put(url.ROOM_URL + `/${room.RoomID}`, room)

// get staffs
export const getStaffs = () => get(url.STAFF_URL)
//delete staff
export const deleteStaff = staffID => del(url.STAFF_URL + `/${staffID}`)
//add staff
export const addNewStaff = staff => post(url.ADD_NEW_STAFF, staff)
//get staff detail
export const getStaffDetail = staffID => get(url.STAFF_URL + `/${staffID}`)
//update staff
export const updateStaff = staff =>
  put(url.STAFF_URL + `/${staff.StaffID}`, staff)
//update staff
export const updateStaffPhoto = staff =>
  post(url.PHOTO_URL + `/${staff.StaffID}`, staff)

// get roles
export const getRoles = () => get(url.ROLE_URL)
// get role types
export const getRoleTypes = () => get(url.ROLE_TYPES_URL)

// get staff schedules
export const getStaffSchedules = filter =>
  get(url.STAFF_SCHEDULES_URL + `/${filter.date}` + `/${filter.staffID}`)
// add staff schedules
export const addStaffSchedule = staffschedule =>
  post(url.ADD_STAFF_SCHEDULES_URL, staffschedule)
//delete staff schedule
export const deleteStaffSchedule = staffSchedulingID =>
  del(url.STAFF_SCHEDULES_URL + `/${staffSchedulingID}`)

/*Common helper functions */
// get countries
export const getCountries = () => get(url.COUNTRY_URL)
// get states
export const getStates = countryID => get(url.STATE_URL + `/${countryID}`)
// get cities
export const getCities = stateID => get(url.CITY_URL + `/${stateID}`)
// get meal types
export const getMealTypes = () => get(url.MEAL_TYPES_URL)
// get services
export const getServices = () => get(url.SERVICES_URL)
// get service charges
export const getServiceCharges = () => get(url.SERVICE_CHARGES_URL)
// get availabilities
export const getAvailabilities = () => get(url.AVAILABILITIES_URL)
// get absent types
export const getAbsentTypes = () => get(url.GET_ABSENT_TYPES_URL)
//get relation types
export const getRelationTypes = () => get(url.GET_RELATION_TYPES_URL)
//get permission types
export const getPermissionTypes = () => get(url.GET_PERMISSION_TYPES_URL)
// get Notification Events
export const getNotificationEvents = () => get(url.GET_NOTIFICATION_EVENTS_URL)
// get Notification Recipients
export const getNotificationRecipients = () =>
  get(url.GET_NOTIFICATION_RECIPIENTS_URL)
// get Forum visibility types
export const getForumVisibilityTypes = () => get(url.GET_FORUM_VISIBILITY_TYPES)
// get Specializations
export const getSpecializations = () => get(url.SPECIALIZATIONS_URL)
// get Meeting start url
export const getMeetingStartURL = meetingKey =>
  get(url.MEETING_START_URL + `/${meetingKey}`)

// get Privacy types
export const getPrivacyTypes = objectTypeID =>
  get(url.GET_PRIVACY_TYPES_URL + `/${objectTypeID}`)
// get Available Types
export const getAvailableTypes = () => get(url.AVAILABLE_TYPES_URL)
// get documents
export const getDocuments = filter =>
  get(
    url.DOCUMENT_URL +
      `?objectID=${filter.objectID}&objectTypeID=${filter.objectTypeID}`
  )
//delete document
export const deleteDocument = objectArtifactID =>
  del(url.DOCUMENT_URL + `/${objectArtifactID}`)
//add document
export const addNewDocument = document =>
  post(url.DOCUMENT_URL, document, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
//download Activity attachment
export const downloadArtifactDocument = rowID =>
  getFile(url.DOWNLOAD_ARTIFACT_ATTACHMENT_URL + `/${rowID}`)

// get payrates
export const getPayrates = staffID => get(url.PAYRATE_URL + `/${staffID}`)
//delete Payrate
export const deletePayrate = payRateID => del(url.PAYRATE_URL + `/${payRateID}`)
//add Payrate
export const addNewPayrate = payrate => post(url.ADD_NEW_PAYRATE, payrate)
//get Payrate detail
export const getPayrateDetail = rowID =>
  get(url.PAYRATE_URL + `?rowID=${rowID}`)
//update Payrate
export const updatePayrate = payrate =>
  put(url.PAYRATE_URL + `/${payrate.PayRateID}`, payrate)

export const getEmployeementTypes = () => get(url.EMPLOYEEMENT_TYPE_URL)

export const getClassificationTypes = () => get(url.CLASSIFICATION_TYPE_URL)

// get staff availability
export const getStaffAvailability = staffID =>
  get(url.STAFF_AVAILABILITY_URL + `/${staffID}`)

// add staff availability
export const addStaffAvailability = ({ staffID, staffavailability }) =>
  post(url.STAFF_AVAILABILITY_URL + `/${staffID}`, staffavailability)

// get staff profile
export const getStaffProfile = staffID =>
  get(url.STAFF_PROFILE_URL + `/${staffID}`)

// add staff profile
export const addStaffProfile = ({ staffID, staffprofile }) =>
  post(url.STAFF_PROFILE_URL + `/${staffID}`, staffprofile)

// get certificates
export const getCertificates = () => get(url.CERTIFICATE_URL)

// get certificate required days
export const getCertificateDays = () => get(url.GET_CERTIFICATE_DAYS_URL)

// add certificate
export const addNewCertificate = certificate =>
  post(url.CERTIFICATE_SAVE_URL, certificate)

//get certificate detail
export const getCertificateDetail = rowId =>
  get(url.CERTIFICATE_URL + `/${rowId}`)

// update certificate
export const updateCertificate = certificate =>
  put(url.CERTIFICATE_UPDATE_URL, certificate)

//delete certificate
export const deleteCertificate = certificateId =>
  del(url.CERTIFICATE_URL + `/${certificateId}`)

// get staff certifications
export const getStaffCertifications = staffId =>
  get(url.STAFF_CERTIFICATION_URL + `/${staffId}`)

// update staff certifications
export const updateStaffCertification = ({ staffId, staffcertification }) =>
  put(url.STAFF_CERTIFICATION_URL + `/${staffId}`, staffcertification)

// get immunizations
export const getImmunizations = () => get(url.IMMUNIZATION_URL)

// add immunization
export const addNewImmunization = immunization =>
  post(url.IMMUNIZATION_SAVE_URL, immunization)

//get immunization detail
export const getImmunizationDetail = rowId =>
  get(url.IMMUNIZATION_URL + `/${rowId}`)

// update immunization
export const updateImmunization = immunization =>
  put(url.IMMUNIZATION_UPDATE_URL, immunization)

//delete immunization
export const deleteImmunization = immunizationId =>
  del(url.IMMUNIZATION_URL + `/${immunizationId}`)

// get child immunizations
export const getChildImmunizations = childId =>
  get(url.IMMUNIZATION_CHILD_URL + `/${childId}`)

// update child immunization
export const updateChildImmunization = ({ childId, childimmunization }) =>
  put(url.IMMUNIZATION_CHILD_URL + `/${childId}`, childimmunization)

//add Staff happening
export const getHappeningTypes = () => get(url.HAPPENING_TYPE_URL)
export const getStaffHappenings = staff =>
  get(url.GET_STAFF_HAPPENING_URL + `/${staff.staffID}/${staff.filterDate}`)

//delete StaffHappening
export const deleteStaffHappening = staffHappeningID =>
  del(url.STAFF_HAPPENING_URL + `/${staffHappeningID}`)
//add StaffHappening
export const addStaffHappening = staffHappening =>
  post(url.ADD_STAFF_HAPPENING, staffHappening)
//get StaffHappening detail
export const getStaffHappeningDetail = staffHappeningID =>
  get(
    url.STAFF_HAPPENING_URL +
      `?staffHappeningID=${staffHappeningID}` +
      "&rowID=''"
  )
//update StaffHappening
export const updateStaffHappening = staffHappening => {
  return put(
    url.STAFF_HAPPENING_URL + `/${staffHappening.StaffHappeningID}`,
    staffHappening
  )
}
export const getHourTypes = () => get(url.HOUR_TYPE_URL)

// get parent provided foods
export const getParentProvidedFoods = childId =>
  get(url.CHILD_PROVIDED_FOOD_URL + `/${childId}`)

// add parent provided food
export const addParentProvidedFood = ({ childId, options }) =>
  post(url.CHILD_PROVIDED_FOOD_URL + `/${childId}`, options)

// get child notes
export const getChildNotes = childId => get(url.CHILD_NOTES_URL + `/${childId}`)

// add child notes
export const addChildNotes = ({ childId, notes }) =>
  post(url.CHILD_NOTES_URL + `/${childId}`, notes)

// get child availability
export const getChildAvailability = childID =>
  get(url.CHILD_AVAILABILITY_URL + `/${childID}`)

// add child availability
export const addChildAvailability = ({ childID, childavailability }) =>
  post(url.CHILD_AVAILABILITY_URL + `/${childID}`, childavailability)

export const getGenders = () => get(url.GET_GENDER_URL)

// get child relations
export const getChildRelations = childId =>
  get(url.CHILD_RELATIONS_URL + `/${childId}`)

// add child relation
export const addChildRelation = ({ childId, relation }) =>
  post(url.CHILD_RELATIONS_URL + `/${childId}`, relation)

//delete child relation
export const deleteChildRelation = childRelationId =>
  del(url.CHILD_RELATIONS_URL + `/${childRelationId}`)

// get Notification
export const getNotifications = () => get(url.NOTIFICATION_URL)
//delete notification
export const deleteNotification = notificationID =>
  del(url.NOTIFICATION_URL + `/${notificationID}`)
//add notification
export const addNewNotification = notification =>
  post(url.ADD_NEW_NOTIFICATION, notification)
//get notification detail
export const getNotificationDetail = notificationID =>
  get(url.NOTIFICATION_URL + `/${notificationID}`)
//update notification
export const updateNotification = notification =>
  put(url.NOTIFICATION_URL + `/${notification.NotificationID}`, notification)

// add Child Happening
export const getChildHappeningTypes = () => get(url.HAPPENING_TYPE_URL)
export const getChildHappenings = child =>
  get(url.GET_CHILD_HAPPENING_URL + `/${child.childID}/${child.filterDate}`)

//delete ChildHappening
export const deleteChildHappening = childHappeningID =>
  del(url.CHILD_HAPPENING_URL + `/${childHappeningID}`)
//add ChildHappening
export const addChildHappening = childHappening =>
  post(url.ADD_CHILD_HAPPENING, childHappening)
//get ChildHappening detail
export const getChildHappeningDetail = childHappeningID =>
  get(
    url.CHILD_HAPPENING_URL +
      `?childHappeningID=${childHappeningID}` +
      "&rowID=''"
  )
//update ChildHappening
export const updateChildHappening = childHappening => {
  return put(
    url.CHILD_HAPPENING_URL + `/${childHappening.ChildHappeningID}`,
    childHappening
  )
}

// get Forum
export const getForums = () => get(url.FORUM_URL)
//delete forum
export const deleteForum = forumID => del(url.FORUM_URL + `/${forumID}`)
//add forum
export const addNewForum = forum => post(url.ADD_NEW_FORUM, forum)
//get forum detail
export const getForumDetail = forumID => get(url.FORUM_URL + `/${forumID}`)
//update forum
export const updateForum = forum =>
  put(url.FORUM_URL + `/${forum.ForumID}`, forum)
//add forum post
export const addNewForumPost = forumPost =>
  post(url.ADD_NEW_FORUM_POST, forumPost)
//delete forum post
export const deleteForumPost = forumPostID =>
  del(url.DELETE_FORUM_POST + `/${forumPostID}`)
//update forum image
export const updateForumPostFile = forumPost =>
  post(url.PHOTO_URL + `/${forumPost.PostID}`, forumPost)

// get Chat
export const getChats = () => get(url.CHAT_URL)
//delete Chat
export const deleteChat = chatID => del(url.CHAT_URL + `/${chatID}`)
//add Chat
export const addNewChat = chat => post(url.ADD_NEW_CHAT, chat)
//get Chat detail
export const getChatDetail = chatID => get(url.CHAT_URL + `/${chatID}`)
//update Chat
export const updateChat = chat => put(url.CHAT_URL + `/${chat.ChatID}`, chat)
//get Chat detail
export const getLatestChat = () => get(url.LATEST_CHAT_URL)
//add Chat message
export const addNewChatMessage = chatMessage =>
  post(url.ADD_NEW_CHAT_MESSAGE, chatMessage)
//delete Chat message
export const deleteChatMessage = chatMessageID =>
  del(url.DELETE_CHAT_MESSAGE + `/${chatMessageID}`)
//update Chat message image
export const updateChatMessageFile = chatMessage =>
  post(url.PHOTO_URL + `/${chatMessage.ChatMessageID}`, chatMessage)

// get Programs
export const getPrograms = () => get(url.PROGRAM_URL)
//delete program
export const deleteProgram = programID => del(url.PROGRAM_URL + `/${programID}`)
//add program
export const addNewProgram = program => post(url.ADD_NEW_PROGRAM, program)
//get program detail
export const getProgramDetail = programID =>
  get(url.PROGRAM_URL + `/${programID}`)
//update program
export const updateProgram = program => post(url.ADD_NEW_PROGRAM, program)

// get enrollments
export const getEnrollments = childId => get(url.ENROLLMENT_URL + `/${childId}`)
// get enrollment detail
export const getEnrollmentDetail = programEnrollmentID =>
  get(url.ENROLLMENT_DETAIL_URL + `/${programEnrollmentID}`)
//add enrollment
export const addEnrollment = enrollment => post(url.ADD_ENROLLMENT, enrollment)
//update enrollment
export const updateEnrollment = enrollment =>
  post(url.UPDATE_ENROLLMENT, enrollment)

// get calendar Program enrollment
export const getCalendarProgramEnrollmentSchedule = event =>
  get(
    url.GET_CALENDAR_PROGRAMS_ENROLLMENT_SCHEDULE_URL +
      `/${event.filter}/${event.userID}`
  )

// get calendar Program enrollment Detail
export const getCalendarProgramEnrollmentScheduleDetail = event =>
  get(
    url.GET_CALENDAR_PROGRAMS_ENROLLMENT_SCHEDULE_DETAIL_URL +
      `/${event.programEnrollmentID}/${event.userID}`
  )

// get flexibles
export const getFlexibles = () => get(url.FLEXIBLE_URL)
//delete flexible
export const deleteFlexible = flexibleID =>
  del(url.FLEXIBLE_URL + `/${flexibleID}`)
//add flexible
export const addNewFlexible = flexible => post(url.ADD_NEW_FLEXIBLE, flexible)
//get flexible detail
export const getFlexibleDetail = flexibleID =>
  get(url.FLEXIBLE_URL + `/${flexibleID}`)
//update flexible
export const updateFlexible = flexible => post(url.ADD_NEW_FLEXIBLE, flexible)
//update flexible payrates
export const updateFlexibleSwitch = request =>
  post(url.UPDATE_FLEXIBLE_SWITCH, request)
//update flexible default rate
export const updateFlexibleDefaultRate = request =>
  post(url.UPDATE_FLEXIBLE_DEFAULT_RATE, request)

// get navigation
export const getNavigation = roleTypeId =>
  get(url.NAVIGATION_URL + `/${roleTypeId}`)

// get Addons
export const getAddOns = () => get(url.ADDON_URL)
//delete program
export const deleteAddOn = addOnID => del(url.ADDON_URL + `/${addOnID}`)
//add program
export const addAddOn = addon => post(url.ADD_ADDON, addon)
//get program detail
export const getAddOnDetail = addOnID => get(url.ADDON_URL + `/${addOnID}`)
//update program
export const updateAddOn = addon => post(url.ADD_ADDON, addon)

// get center profile
export const getProfile = () => get(url.CENTER_PROFILE_URL)
//get center profile detail
export const getProfileDetail = profileId =>
  get(url.CENTER_PROFILE_URL + `/${profileId}`)
//update center profile
export const updateProfile = profile =>
  put(url.CENTER_PROFILE_UPDATE_URL + `/${profile.CenterProfileID}`, profile)
//update center profile address
export const updateProfileAddress = address =>
  put(
    url.CENTER_PROFILE_ADDRESS_UPDATE_URL + `/${address.CenterProfileID}`,
    address
  )
//update center profile contact
export const updateProfileContact = contact =>
  put(
    url.CENTER_PROFILE_CONTACT_UPDATE_URL + `/${contact.CenterProfileID}`,
    contact
  )
//update center profile closing
export const updateProfileClosing = closing =>
  put(
    url.CENTER_PROFILE_CLOSING_UPDATE_URL + `/${closing.CenterProfileID}`,
    closing
  )
// update center operating hour
export const updateProfileOperatingHour = ({ profileID, operatinghour }) =>
  post(
    url.CENTER_PROFILE_OPEATING_HOUR_UPDATE_URL + `/${profileID}`,
    operatinghour.availability
  )
//delete center profile address
export const deleteProfileAddress = addressID =>
  del(url.CENTER_PROFILE_ADDRESS_UPDATE_URL + `/${addressID}`)
//delete center profile contact
export const deleteProfileContact = contactID =>
  del(url.CENTER_PROFILE_CONTACT_UPDATE_URL + `/${contactID}`)
//delete center profile closing
export const deleteProfileClosing = closingID =>
  del(url.CENTER_PROFILE_CLOSING_UPDATE_URL + `/${closingID}`)

// get ChargeTypes
export const getChargeTypes = () => get(url.CHARGETYPE_URL)
// get Charges
export const getCharges = () => get(url.CHARGE_URL)
//delete program
export const deleteCharge = chargeID => del(url.CHARGE_URL + `/${chargeID}`)
//add program
export const addCharge = charge => post(url.ADD_CHARGE, charge)
//get program detail
export const getChargeDetail = chargeID => get(url.CHARGE_URL + `/${chargeID}`)
//update program
export const updateCharge = charge => post(url.ADD_CHARGE, charge)

// get Albums
export const getAlbums = () => get(url.ALBUM_URL)
//delete Album
export const deleteAlbum = albumID => del(url.ALBUM_URL + `/${albumID}`)
//add Album
export const addNewAlbum = album => post(url.ADD_NEW_ALBUM_URL, album)
//get Album detail
export const getAlbumDetail = albumID => get(url.ALBUM_URL + `/${albumID}`)
//update Album
export const updateAlbum = album =>
  put(url.ALBUM_URL + `/${album.AlbumID}`, album)

//add AlbumMedia

export const addNewAlbumMedia = albummedia =>
  post(url.PHOTO_URL + `/${albummedia.AlbumID}`, albummedia)
//delete AlbumMedia
export const deleteAlbumMedia = albumMediaID =>
  del(url.DELETE_ALBUMMEDIA_URL + `/${albumMediaID}`)

// get Lesson
export const getLessonPlans = () => get(url.LESSON_PLAN_URL)
//delete LessonPlan
export const deleteLessonPlan = lessonPlanID =>
  del(url.LESSON_PLAN_URL + `/${lessonPlanID}`)
//add LessonPlan
export const addNewLessonPlan = lessonPlan =>
  post(url.ADD_NEW_LESSON_PLAN, lessonPlan, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
//get LessonPlan detail
export const getLessonPlanDetail = lessonPlanID =>
  get(url.LESSON_PLAN_DETAIL_URL + `/${lessonPlanID}`)
//update LessonPlan
export const updateLessonPlan = lessonPlan =>
  put(url.LESSON_PLAN_URL + `/${lessonPlan.LessonPlanID}`, lessonPlan)

//add LessonPlan schedule
export const addLessonPlanSchedule = obj =>
  post(
    url.ADD_NEW_LESSON_PLAN_SCHEDULE + `/${obj.lessonPlanRowId}`,
    obj.lessonPlanschedule
  )
//update LessonPlan schedule
export const updateLessonPlanSchedule = obj =>
  post(url.UPDATE_LESSON_PLAN_SCHEDULE, obj)

//delete LessonPlanSchedule
export const deleteLessonPlanSchedule = lessonPlanScheduleID =>
  del(url.LESSON_PLAN_SCHEDULE_DELETE_URL + `/${lessonPlanScheduleID}`)

// Get LessonPlans Schedules
export const getLessonPlanSchedulesByLessonPlan = lessonPlanID =>
  get(url.LESSON_PLAN_SCHEDULE_URL + `/${lessonPlanID}`)

//update LessonPlan schedule Detail
export const updateLessonPlanScheduleDetail = lessonPlanScheduleDetail =>
  put(
    url.LESSON_PLAN_SCHEDULE_DETAIL_UPDATE_URL +
      `/${lessonPlanScheduleDetail?.LessonPlanScheduleDetailID}`,
    lessonPlanScheduleDetail
  )

//delete deleteLessonPlanScheduleDetail
export const deleteLessonPlanScheduleDetail = lessonPlanScheduleDetailID =>
  del(
    url.LESSON_PLAN_SCHEDULE_DETAIL_DELETE_URL +
      `/${lessonPlanScheduleDetailID}`
  )

// Get LessonPlanScheduleDetail by NSD
export const getLessonPlanScheduleDetailbyNSD = lessonPlanScheduleDetail =>
  get(
    url.LESSON_PLAN_SCHEDULE_DETAIL_URL +
      `/${lessonPlanScheduleDetail.LessonPlanScheduleID}/${lessonPlanScheduleDetail.Day}`
  )

export const getLessonPlansView = lessonplan =>
  get(
    url.LESSON_PLAN_ENROLL_URL +
      `/${lessonplan?.AdultID}/${lessonplan?.ChildID}`
  )

// Get Curriculums
export const getCurriculums = () => get(url.CURRICULUM_URL)
//delete Curriculum
export const deleteCurriculum = curriculumID =>
  del(url.CURRICULUM_URL + `/${curriculumID}`)

//add Curriculum
export const addNewCurriculum = curriculum =>
  post(url.ADD_NEW_CURRICULUM, curriculum, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
//get Curriculum detail
export const getCurriculumDetail = curriculumID =>
  get(url.CURRICULUM_DETAIL_URL + `/${curriculumID}`)
//update Curriculum
export const updateCurriculum = curriculum =>
  put(url.CURRICULUM_URL + `/${curriculum.CurriculumID}`, curriculum)

export const getNoteCategories = () => get(url.NOTE_CATEGORIES_URL)

//add Curriculum schedule
export const addCurriculumSchedule = obj =>
  post(
    url.ADD_NEW_CURRICULUM_SCHEDULE + `/${obj.curriculumRowId}`,
    obj.curriculumschedule
  )

//update Curriculum schedule
export const updateCurriculumSchedule = obj =>
  post(url.UPDATE_CURRICULUM_SCHEDULE, obj)

//delete CurriculumSchedule
export const deleteCurriculumSchedule = curriculumScheduleID =>
  del(url.CURRICULUM_SCHEDULE_DELETE_URL + `/${curriculumScheduleID}`)

// Get Curriculums Schedules
export const getCurriculumSchedulesByCurriculum = curriculumID =>
  get(url.CURRICULUM_SCHEDULE_URL + `/${curriculumID}`)

//update Curriculum schedule Detail
export const updateCurriculumScheduleDetail = curriculumScheduleDetail =>
  put(
    url.CURRICULUM_SCHEDULE_DETAIL_UPDATE_URL +
      `/${curriculumScheduleDetail.CurriculumScheduleDetailID}`,
    curriculumScheduleDetail
  )

//delete deleteCurriculumScheduleDetail
export const deleteCurriculumScheduleDetail = curriculumScheduleDetailID =>
  del(
    url.CURRICULUM_SCHEDULE_DETAIL_DELETE_URL + `/${curriculumScheduleDetailID}`
  )

// Get CurriculumScheduleDetail by CSD
export const getCurriculumScheduleDetailbyCSD = curriculumScheduleDetail =>
  get(
    url.CURRICULUM_SCHEDULE_DETAIL_URL +
      `/${curriculumScheduleDetail.CurriculumScheduleID}/${curriculumScheduleDetail.Day}`
  )

export const getCurriculumsView = curriculum =>
  get(
    url.CURRICULUM_ENROLL_URL + `/${curriculum.AdultID}/${curriculum.ChildID}`
  )

// Get Notes
export const getAllNotes = () => get(url.NOTE_URL)
//delete Note
export const deleteNote = noteID => del(url.NOTE_URL + `/${noteID}`)
//add Note
export const addNewNote = note =>
  post(url.ADD_NEW_NOTE, note, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
//get Note detail
export const getNoteDetail = noteID => get(url.NOTE_DETAIL_URL + `/${noteID}`)
//update Note
export const updateNote = note => put(url.NOTE_URL + `/${note.NoteID}`, note)

//add Note schedule
export const addNoteSchedule = obj =>
  post(url.ADD_NEW_NOTE_SCHEDULE + `/${obj.noteRowId}`, obj.noteschedule)
//update Note schedule
export const updateNoteSchedule = obj => post(url.UPDATE_NOTE_SCHEDULE, obj)

//delete NoteSchedule
export const deleteNoteSchedule = noteScheduleID =>
  del(url.NOTE_SCHEDULE_DELETE_URL + `/${noteScheduleID}`)

// Get Notes Schedules
export const getNoteSchedulesByNote = noteID =>
  get(url.NOTE_SCHEDULE_URL + `/${noteID}`)

//update Note schedule Detail
export const updateNoteScheduleDetail = noteScheduleDetail =>
  put(
    url.NOTE_SCHEDULE_DETAIL_UPDATE_URL +
      `/${noteScheduleDetail?.NoteScheduleDetailID}`,
    noteScheduleDetail
  )

//delete deleteNoteScheduleDetail
export const deleteNoteScheduleDetail = noteScheduleDetailID =>
  del(url.NOTE_SCHEDULE_DETAIL_DELETE_URL + `/${noteScheduleDetailID}`)

// Get NoteScheduleDetail by NSD
export const getNoteScheduleDetailbyNSD = noteScheduleDetail =>
  get(
    url.NOTE_SCHEDULE_DETAIL_URL +
      `/${noteScheduleDetail.NoteScheduleID}/${noteScheduleDetail.Day}`
  )

export const getNotesView = note =>
  get(url.NOTE_ENROLL_URL + `/${note?.AdultID}/${note?.ChildID}`)

// get Resources
export const getResources = filter =>
  get(
    url.RESOURCE_URL + `/${filter?.objectRowID}` + `/${filter?.resourceTypeID}`
  )
//delete Resource
export const deleteResource = resourceID =>
  del(url.RESOURCE_URL + `/${resourceID}`)
//add Resource
export const addResource = resource =>
  post(url.ADD_NEW_RESOURCE, resource, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
//get Resource detail
export const getResourceDetail = resourceID =>
  get(url.RESOURCE_URL + `/${resourceID}`)
//update Resource
export const updateResource = resource =>
  put(url.RESOURCE_URL + `/${resource.resourceID}}`, resource)

export const downloadResource = rowID =>
  getFile(url.DOWNLOAD_RESOURCE_URL + `/${rowID}`)

export const getTheme = () => get(url.THEME_URL)
//update Theme
export const updateTheme = theme => post(url.UPDATE_THEME, theme)

//get BasicSettings
export const getBasicSettings = () => get(url.BASICSETINGS_URL + `/${1}`)
//update BasicSettings
export const updateBasicSettings = basicSetting =>
  post(url.UPDATE_BASICSETINGS, basicSetting, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })

// get Reports
export const getReports = () => get(url.REPORT_URL)
// get Report Detail
export const getReportDetail = reportID => get(url.REPORT_URL + `/${reportID}`)
// get Report Types
export const getReportTypes = () => get(url.REPORT_TYPE_URL)
//get Report Data
export const getReportData = obj =>
  get(
    url.REPORT_URL +
      `?reportID=${obj.reportID}&filterDate=${obj.filterDate}&filterEndDate=${obj.filterEndDate}`
  )

//get dashboard chart
export const getChartData = centerId => get(url.DASHBOARD_URL + `/${centerId}`)
//get scheduled data
export const getScheduledData = centerId =>
  get(url.DASHBOARD_SCHEDULED_URL + `/${centerId}`)
//get live data
export const getLiveData = centerId =>
  get(url.DASHBOARD_LIVE_URL + `/${centerId}`)

// get users profile
export const getUserProfile = rowId => get(url.USER_URL + `/${rowId}`)

// get Events
//export const getEvents = () => get(url.GET_EVENTS)
export const getEvents = event =>
  get(url.GET_EVENTS + `/${event.filter}/${event.childID}`)

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } })

// get Payment Methods
export const getPaymentMethods = () => get(url.PAYMENT_METHOD_URL)
// add Wallet Transaction
export const addWalletTransaction = obj =>
  post(url.ADD_WALLET_URL + `/${obj.AdultID}`, obj.Values)
// get Wallet Transaction
export const getWalletTransaction = adultID =>
  get(url.WALLET_URL + `/${adultID}`)

// get Activities
export const getActivities = filter =>
  get(url.ACTIVITIES_URL + `/${filter.date}` + `/${filter.classID}`)
// add Activity
export const addActivity = activity => post(url.ADD_ACTIVITIES_URL, activity)
//delete Activity
export const deleteActivity = activityID =>
  del(url.ACTIVITIES_URL + `/${activityID}`)
//get Activity detail
export const getActivityDetail = activityID =>
  get(url.ADD_ACTIVITT_DETAIL_URL + `/${activityID}`)
// update Activity
export const updateActivity = activity =>
  post(url.UPDATE_ACTIVITIES_URL, activity)
// get Activities by Class
export const getActivitiesByClass = filter =>
  get(
    url.ACTIVITIES_URL +
      `?classId=${filter.classId}&startDate=${filter.startDate}&page=1&limit=100`
  )
//download Activity attachment
export const downloadActivityAttachment = attachmentID =>
  getFile(url.DOWNLOAD_ACTIVITY_ATTACHMENT_URL + `/${attachmentID}`)

// get Fee Transaction
export const getFeeTransaction = childID => get(url.FEE_URL + `/${childID}`)
// add Fee Transaction
export const addFeeTransaction = obj =>
  post(url.ADD_FEE_URL + `/${obj.AdultID}`, obj.Values)
// get Fee Transaction Detail
export const getFeeTransactionDetail = adultID =>
  get(url.FEE_DETAIL_URL + `/${adultID}`)
// get Paid Fee Transaction
export const getPaidFeeTransaction = transactionIds =>
  get(url.PAID_FEE_URL + `/${transactionIds}`)

// get RoleType Modules
export const getRoleTypeModules = roleTypeID =>
  get(url.ROLETYPE_MODULE_URL + `/${roleTypeID}`)
// add RoleType Modules
export const addRoleTypeModules = ({ roleTypeID, roleTypeModules }) =>
  post(url.ROLETYPE_MODULE_URL + `/${roleTypeID}`, roleTypeModules)

//Impersonation
export const updateImpersonateUser = userID =>
  put(url.UPDATE_IMPERSONATE_USER + `/${userID}`)
export const deleteImpersonateUser = userID =>
  put(url.DELETE_IMPERSONATE_USER + `/${userID}`)

export const resetUserPassword = email =>
  get(url.RESET_USER_PASSWORD_URL + `/${0}` + `?email=${email}`)

// get doctor schedules
export const getDoctorSchedules = filter =>
  get(
    url.DOCTOR_SCHEDULES_URL +
      `/${filter.date}` +
      `/${filter.objectID}` +
      `/${filter.roleID}`
  )
// add doctor schedule
export const addDoctorSchedule = doctorSchedule =>
  post(url.ADD_DOCTOR_SCHEDULES_URL, doctorSchedule)
//delete doctor schedule
export const deleteDoctorSchedule = doctorSchedulingID =>
  del(url.DOCTOR_SCHEDULES_URL + `/${doctorSchedulingID}`)
// get doctor Meeting sync
export const getDoctorMeetingSync = doctorScheduleID =>
  get(url.DOCTOR_MEETING_SYNC_URL + `/${doctorScheduleID}`)

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postLogin,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
}
