import React, { useState, useEffect } from "react"

//controls
import { Button, Col, Form, Input, Label, Row, FormFeedback } from "reactstrap"
import Flatpickr from "react-flatpickr"
import Switch from "react-switch"

//react form
//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

import { updateBasicSettings as OnUpdateBasicSettings } from "store/actions"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

const formdefault = {
  StripeAPIKey: "",
  StripeSecretKey: "",
  EnableStripe: false,
}
function StripeTab() {
  const dispatch = useDispatch()

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    StripeAPIKey: yup.string().nullable(),
    StripeSecretKey: yup.string().nullable(),
    EnableStripe: yup.bool(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  useEffect(() => {
    //reset form with server data
    // if (basicSetting) {
    //   const response = {
    //     ...basicSetting[0],
    //   }
    //   reset(response)
    // }
    return () => {}
  }, [dispatch])

  const onCancelClick = () => {
    // navigate("/learning/curriculums")
  }
  const handleSave = () => {
    trigger() //for form validation
    if (isValid) {
      dispatch(OnUpdateBasicSettings({ ...getValues(), BasicSettingID: 1 }))
      reset(formdefault)
    }
  }

  return (
    <FormProvider {...methods}>
      <Row>
        <Col lg={6}>
          <div className="mb-3">
            <Label>Stripe API Key</Label>
            <Controller
              name="StripeAPIKey"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="StripeAPIKey"
                    type="text"
                    // required
                    //  invalid={!!errors.StripeAPIKey}
                  />
                  {/* {errors?.StripeAPIKey?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.StripeAPIKey?.message}
                    </FormFeedback>
                  ) : null} */}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Stripe Secret Key</Label>
            <Controller
              name="StripeSecretKey"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="StripeSecretKey"
                    type="text"
                    value={field.value || ""}
                  />
                  {/* {errors?.StripeSecretKey?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.StripeSecretKey?.message}
                    </FormFeedback>
                  ) : null} */}
                </>
              )}
            />
          </div>

          <div className="mb-3">
            <Label>Enable</Label>
            <div>
              <Controller
                name="EnableStripe"
                control={control}
                render={({ field }) => (
                  <>
                    <Switch
                      {...field}
                      id="EnableStripe"
                      checked={field.value}
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      className="me-1 mb-sm-8 mb-2"
                      onColor="#626ed4"
                    />
                  </>
                )}
              />
            </div>
          </div>
        </Col>
      </Row>
      <div className="d-flex flex-wrap gap-2">
        <Button color="primary" className="btn" onClick={handleSave}>
          {"Save"}
        </Button>
     
      </div>
    </FormProvider>
  )
}
export default StripeTab
