import React, { useState, useEffect } from "react"

//controls
import { Button, Col, Form, Input, Label, Row, FormFeedback } from "reactstrap"
//react form
import { FormProvider, useForm, Controller } from "react-hook-form"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useDispatch } from "react-redux"

import { updateBasicSettings as OnUpdateBasicSettings } from "store/actions"
import { isEmpty } from "lodash"

import {
  validExtensionsBasicSetting,
  maxFileSizeBasicSetting,
} from "pages/Utility/constants"
import {} from "store/actions"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

const formdefault = {
  LoginLightLogoFileName: "",
  LoginDarkLogoFileName: "",
  BannerFileName: "",
}
function LoginImageTab() {
  const dispatch = useDispatch()

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    LoginLightLogoFileName: yup
      .mixed()
      .test("fileType", "File must be a PNG, JPG, or JPEG", value =>
        value ? validExtensionsBasicSetting.includes(value.type) : true
      )
      .test("fileSize", "File must be smaller than 1MB", value =>
        value ? value.size <= maxFileSizeBasicSetting : true
      ),
    LoginDarkLogoFileName: yup
      .mixed()
      .test("fileType", "File must be a PNG, JPG, or JPEG", value =>
        value ? validExtensionsBasicSetting.includes(value.type) : true
      )
      .test("fileSize", "File must be smaller than 1MB", value =>
        value ? value.size <= maxFileSizeBasicSetting : true
      ),
    BannerFileName: yup
      .mixed()
      .test("fileType", "File must be a PNG, JPG, or JPEG", value =>
        value ? validExtensionsBasicSetting.includes(value.type) : true
      )
      .test("fileSize", "File must be smaller than 1MB", value =>
        value ? value.size <= maxFileSizeBasicSetting : true
      ),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  const handleSave = () => {
    trigger() //for form validation
    if (isValid) {
      dispatch(OnUpdateBasicSettings({ ...getValues(), BasicSettingID: 1 }))
      reset(formdefault)
    }
  }

  return (
    <FormProvider {...methods}>
      <Row>
        <Col lg={6}>
          <div className="mb-3">
            <Label>Light Logo</Label>
            <Controller
              name="LoginLightLogoFileName"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    className="form-control"
                    type="file"
                    id="LoginLightLogoFileName"
                    value={field.value?.LoginLightLogoFileName}
                    onChange={e => field.onChange(e.target.files[0])}
                    // required
                    invalid={!!errors.LoginLightLogoFileName}
                  />
                  {errors?.LoginLightLogoFileName?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.LoginLightLogoFileName?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Dark Logo</Label>
            <Controller
              name="LoginDarkLogoFileName"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    className="form-control"
                    type="file"
                    id="LoginDarkLogoFileName"
                    value={field.value?.LoginDarkLogoFileName}
                    onChange={e => field.onChange(e.target.files[0])}
                    // required
                    invalid={!!errors.LoginDarkLogoFileName}
                  />
                  {errors?.LoginDarkLogoFileName?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.LoginDarkLogoFileName?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Banner Image</Label>
            <Controller
              name="BannerFileName"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    className="form-control"
                    type="file"
                    id="BannerFileName"
                    value={field.value?.BannerFileName}
                    onChange={e => field.onChange(e.target.files[0])}
                    // required
                    invalid={!!errors.BannerFileName}
                  />
                  {errors?.BannerFileName?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.BannerFileName?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
        </Col>
      </Row>
      <div className="d-flex flex-wrap gap-2">
        <Button color="primary" className="btn" onClick={handleSave}>
          {"Save"}
        </Button>
      </div>
    </FormProvider>
  )
}
export default LoginImageTab
