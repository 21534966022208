import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Child Redux States
import {
  GET_CHILDREN,
  DELETE_CHILD,
  ADD_NEW_CHILD,
  GET_CHILD_DETAIL,
  UPDATE_CHILD,
  UPDATE_CHILD_PHOTO,
  RESET_CHILD,
  ADD_CHILD_PROVIDED_FOOD,
  GET_CHILD_PROVIDED_FOOD,
  ADD_CHILD_NOTES,
  GET_CHILD_NOTES,
  GET_CHILD_AVAILABILITY,
  ADD_CHILD_AVAILABILITY,
  GET_CHILD_RELATIONS,
  GET_RELATION_TYPES,
  GET_PERMISSION_TYPES,
  ADD_CHILD_RELATION,
  DELETE_CHILD_RELATION,
  ADD_CHILD_PROFILE,
  ADD_CHILD_HAPPENING,
  GET_HAPPENING_TYPES,
  GET_CHILD_HAPPENING,
  DELETE_CHILD_HAPPENING,
  UPDATE_CHILD_HAPPENING,
  GET_CHILD_HAPPENING_DETAIL,
  GET_TAG_CATEGORY,
  GET_TAGS,
  ADD_NEW_TAG,
  DELETE_TAG,
  GET_MESSAGES,
  GET_MESSAGE_POSTS,
  ADD_NEW_MESSAGE,
  ADD_MESSAGE_POST,
  GET_AWAITED_MESSAGES,
  UPDATE_MESSAGE_APPROVAL,
  DELETE_MESSAGE,
  DELETE_MESSAGE_POST,
  //GET_HOUR_TYPES,
} from "./actionTypes"
import {
  getChildrenFail,
  getChildrenSuccess,
  deleteChildSuccess,
  deleteChildFail,
  addChildSuccess,
  addChildFail,
  getChildDetailSuccess,
  getChildDetailFail,
  updateChildSuccess,
  updateChildFail,
  updateChildPhotoSuccess,
  updateChildPhotoFail,
  resetChildSuccess,
  addParentProvidedFoodSuccess,
  addParentProvidedFoodFail,
  getParentProvidedFoodSuccess,
  getParentProvidedFoodFail,
  addNotesSuccess,
  addNotesFail,
  getNotesSuccess,
  getNotesFail,
  getChildAvailabilitySuccess,
  getChildAvailabilityFail,
  addChildAvailabilitySuccess,
  addChildAvailabilityFail,
  getChildRelationsSuccess,
  getChildRelationsFail,
  getRelationTypesSuccess,
  getRelationTypesFail,
  getPermissionTypesSuccess,
  getPermissionTypesFail,
  addRelationSuccess,
  addRelationFail,
  deleteRelationSuccess,
  deleteRelationFail,
  getChildHappeningTypesSuccess,
  getChildHappeningTypesFail,
  addChildHappeningSuccess,
  addChildHappeningFail,
  getChildHappeningDetailSuccess,
  getChildHappeningDetailFail,
  updateChildHappeningSuccess,
  updateChildHappeningFail,
  deleteChildHappeningSuccess,
  deleteChildHappeningFail,
  getChildHappeningsSuccess,
  getChildHappeningsFail,
  getTagCategorySuccess,
  getTagCategoryFail,
  getTagsSuccess,
  getTagsFail,
  addNewTagSuccess,
  addNewTagFail,
  deleteTagSuccess,
  deleteTagFail,
  getMessagesSuccess,
  getMessagesFail,
  getMessagePostsSuccess,
  getMessagePostsFail,
  addMessageSuccess,
  addMessageFail,
  addMessagePostSuccess,
  addMessagePostFail,
  getAwaitedMessagesSuccess,
  getAwaitedMessagesFail,
  updateMessageApprovalSuccess,
  updateMessageApprovalFail,
  deleteMessageSuccess,
  deleteMessageFail,
  deleteMessagePostSuccess,
  deleteMessagePostFail,
  //getHourTypesSuccess,
  //getHourTypesFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getChildren,
  deleteChild,
  addNewChild,
  getChildDetail,
  updateChild,
  updateChildPhoto,
  addParentProvidedFood,
  getParentProvidedFoods,
  addChildNotes,
  getChildNotes,
  getChildAvailability,
  addChildAvailability,
  getChildRelations,
  getRelationTypes,
  getPermissionTypes,
  addChildRelation,
  deleteChildRelation,
  getChildHappeningTypes,
  getChildHappenings,
  addChildHappening,
  getChildHappeningDetail,
  updateChildHappening,
  deleteChildHappening,
  getHourTypes,
  getTags,
  getTagCategory,
  addNewTag,
  deleteTag,
  getMessages,
  getMessagePosts,
  addNewMessage,
  addMessagePost,
  getAwaitedMessages,
  updateMessageApproval,
  deleteMessage,
  deleteMessagePost,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
function* fetchChildren() {
  try {
    const response = yield call(getChildren)
    yield put(getChildrenSuccess(response))
  } catch (error) {
    yield put(getChildrenFail(error))
  }
}

function* onDeleteChild({ payload: child }) {
  try {
    const response = yield call(deleteChild, child)
    yield put(deleteChildSuccess(response))
    toast.success("Child deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteChildFail(error))
    toast.error("Child deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewChild({ payload: child }) {
  try {
    var photoData = new FormData()
    photoData.append("files", child.Photo)
    photoData.append("field", "Child")
    delete child.Photo
    const child1 = {
      ...child,
      GenderID: child.Genders.GenderID,
    }
    const response = yield call(addNewChild, child1)
    yield put(addChildSuccess(response[0]))

    photoData.append("refId", response[0].ChildID)
    try {
      const response1 = yield call(updateChildPhoto, photoData)
      yield put(updateChildPhotoSuccess(response1))
    } catch (error) {
      yield put(updateChildPhotoFail(error))
      toast.error("Photo Update Failed", { autoClose: 2000 })
    }
    toast.success("Child added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addChildFail(error))
    toast.error("Child addition failed", { autoClose: 2000 })
  }
}

function* fetchChildDetail({ childId }) {
  try {
    const response = yield call(getChildDetail, childId)
    let result = response[0]

    result = {
      ...result,
      DateOfBirth: result.DateOfBirth
        ? Moment(result.DateOfBirth).format("DD MMM, YYYY")
        : "",
      StartDate: result.StartDate
        ? Moment(result.StartDate).format("DD MMM, YYYY")
        : "",
      EndDate: result.EndDate
        ? Moment(result.EndDate).format("DD MMM, YYYY")
        : "",
      Countries: {
        CountryID: Number(result.CountryID) ?? 0,
        Name: result.Country ?? "Select...",
      },
      States: {
        StateID: Number(result.StateID) ?? 0,
        Name: result.State ?? "Select...",
      },
      Cities: {
        CityID: Number(result.CityID) ?? 0,
        Name: result.City ?? "Select...",
      },
      Classes: {
        ClassID: result.HomeClassID ?? "",
        Title: result.HomeClassTitle ?? "Select...",
      },
      ZipCode: result.ZipCode ?? "",
      Address: result.Address ?? "",
      Genders: {
        GenderID: result.GenderID ?? "",
        Gender: result.Gender ?? "Select...",
      },
      MealTypes: {
        MealTypeID: result.MealTypeID ?? "",
        MealType: result.MealType ?? "Select...",
      },
    }
    yield put(getChildDetailSuccess(result))
  } catch (error) {
    yield put(getChildDetailFail(error))
  }
}

function* onUpdateChild({ payload: child }) {
  try {
    delete child.Photo
    const child1 = {
      ...child,
      GenderID: child.Genders.GenderID,
    }
    const response = yield call(updateChild, child1)
    let result = response[0]

    yield put(updateChildSuccess(result))
    toast.success("Child updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateChildFail(error))
    toast.error("Child updation failed", { autoClose: 2000 })
  }
}
function* onUpdateChildPhoto({ payload: child }) {
  try {
    var photoData = new FormData()
    photoData.append("files", child.Photo)
    photoData.append("refId", child.ChildID)
    photoData.append("field", "Child")
    const response = yield call(updateChildPhoto, photoData)
    //let result = response[0]

    yield put(updateChildPhotoSuccess(response))
    //toast.success("Photo Update Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateChildPhotoFail(error))
    toast.error("Photo Update Failed", { autoClose: 2000 })
  }
}
function* onAddParentProvidedFood({ payload: { childId, providedfoods } }) {
  try {
    const request = {
      childId: childId,
      options: providedfoods.Foods,
    }
    const response = yield call(addParentProvidedFood, request)
    yield put(addParentProvidedFoodSuccess({ Foods: response }))
    toast.success("Food option added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addParentProvidedFoodFail(error))
    toast.error("Food option addition failed", { autoClose: 2000 })
  }
}
function* fetchParentProvidedFood({ payload: childId }) {
  try {
    const response = yield call(getParentProvidedFoods, childId)
    yield put(getParentProvidedFoodSuccess({ Foods: response }))
  } catch (error) {
    yield put(getParentProvidedFoodFail(error))
  }
}
function* onAddChildNotes({ payload: { childId, notes } }) {
  try {
    const request = {
      childId: childId,
      notes: notes.Notes,
    }

    const response = yield call(addChildNotes, request)
    yield put(addNotesSuccess({ Notes: response }))
    toast.success("Notes added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addNotesFail(error))
    toast.error("Notes addition failed", { autoClose: 2000 })
  }
}
function* fetchChildNotes({ payload: childId }) {
  try {
    const response = yield call(getChildNotes, childId)
    yield put(getNotesSuccess({ Notes: response }))
  } catch (error) {
    yield put(getNotesFail(error))
  }
}
function* fetchChildAvailability({ childID }) {
  try {
    const response = yield call(getChildAvailability, childID)
    yield put(getChildAvailabilitySuccess(response))
  } catch (error) {
    yield put(getChildAvailabilityFail(error))
  }
}

function* onAddChildAvailability({ payload: { childID, childavailability } }) {
  try {
    const request = {
      childID: childID,
      childavailability: childavailability.availability,
    }
    const response = yield call(addChildAvailability, request)
    yield put(addChildAvailabilitySuccess(response))
    toast.success("Child availability added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addChildAvailabilityFail(error))
    toast.error("Child availability addition failed", { autoClose: 2000 })
  }
}

function* fetchChildRelations({ payload: childId }) {
  try {
    const response = yield call(getChildRelations, childId)
    yield put(getChildRelationsSuccess(response))
  } catch (error) {
    yield put(getChildRelationsFail(error))
  }
}

function* fetchRelationTypes() {
  try {
    const response = yield call(getRelationTypes)
    yield put(getRelationTypesSuccess(response))
  } catch (error) {
    yield put(getRelationTypesFail(error))
  }
}

function* fetchPermissionTypes() {
  try {
    const response = yield call(getPermissionTypes)
    yield put(getPermissionTypesSuccess(response))
  } catch (error) {
    yield put(getPermissionTypesFail(error))
  }
}

function* onAddChildRelation({ payload: { childId, relation } }) {
  try {
    const request = {
      childId: childId,
      relation: {
        ChildRelationID: relation.ChildRelationID,
        ChildID: childId,
        Adults: relation.Adults,
        RelationTypes: relation.RelationTypes,
        OtherRelation: relation.OtherRelation,
        AdultPermissions: relation.AdultPermissions,
      },
    }
    const response = yield call(addChildRelation, request)
    yield put(addRelationSuccess(response))
    toast.success("Relation added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addRelationFail(error))
    toast.error("Relation addition failed", { autoClose: 2000 })
  }
}

function* onDeleteChildRelation({ payload: childRelationId }) {
  try {
    const response = yield call(deleteChildRelation, childRelationId)
    yield put(deleteRelationSuccess(response))
    toast.success("Relation deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteRelationFail(error))
    toast.error("Relation deletion failed", { autoClose: 2000 })
  }
}

function* fetchChildHapeningTypes() {
  try {
    const response = yield call(getChildHappeningTypes)
    yield put(getChildHappeningTypesSuccess(response))
  } catch (error) {
    yield put(getChildHappeningTypesFail(error))
  }
}

function* fetchChildHapenings({ payload: { childID, filterDate } }) {
  try {
    const child = {
      childID: childID,
      filterDate: filterDate,
    }
    const response = yield call(getChildHappenings, child)
    yield put(getChildHappeningsSuccess(response))
  } catch (error) {
    yield put(getChildHappeningsFail(error))
  }
}

function* onDeleteChildHappening({ payload: childHappeningID }) {
  try {
    const response = yield call(deleteChildHappening, childHappeningID)
    yield put(deleteChildHappeningSuccess(response))
    toast.success("deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteChildHappeningFail(error))
    toast.error("deletion failed", { autoClose: 2000 })
  }
}

function* onAddChildHappening({ payload: childHappening }) {
  try {
    const childHappening1 = {
      ...childHappening,
      PersonID:
        childHappening.PersonTypeID == 1
          ? childHappening?.Adults?.AdultID ?? null
          : childHappening?.Staffs?.StaffID ?? null,

      ClassID: childHappening?.Classes?.ClassID ?? null,
    }

    const response = yield call(addChildHappening, childHappening1)
    yield put(addChildHappeningSuccess(response))
    toast.success("Happening added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addChildHappeningFail(error))
    toast.error("Happening failed", { autoClose: 2000 })
  }
}

function* fetchChildHappeningDetail({ childHappeningID }) {
  try {
    const response = yield call(getChildHappeningDetail, childHappeningID)
    let result = response[0]
    result = {
      ...result,
      Date: result.Date ? Moment(result.Date).format("DD MMM, YYYY") : "",
    }
    yield put(getChildHappeningDetailSuccess(result))
  } catch (error) {
    yield put(getChildHappeningDetailFail(error))
  }
}
function* onUpdateChildHappening({ payload: childHappening }) {
  try {
    const childHappening1 = {
      ...childHappening,
      PersonID:
        childHappening.PersonTypeID == 1
          ? childHappening?.Adults?.AdultID ?? null
          : childHappening?.Staffs?.StaffID ?? null,
      ClassID: childHappening?.Classes?.ClassID ?? null,
    }
    const response = yield call(updateChildHappening, childHappening1)
    let result = response[0]
    yield put(updateChildHappeningSuccess(result))
    toast.success("Updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateChildHappeningFail(error))
    toast.error("Updation failed", { autoClose: 2000 })
  }
}

// function* fetchHourTypes() {
//   try {
//     const response = yield call(getHourTypes)
//     yield put(getHourTypesSuccess(response))
//   } catch (error) {
//     yield put(getHourTypesFail(error))
//   }
// }

function* fetchTags({ payload: childId }) {
  try {
    const response = yield call(getTags, childId)

    let result = response.map(x => ({
      ...x,
      TagCategories: {
        TagCategoryID: x.TagCategoryID ?? 0,
        TagCategory: x.TagCategory ?? "",
      },
    }))

    yield put(getTagsSuccess(result))
  } catch (error) {
    yield put(getTagsFail(error))
  }
}

function* fetchTagCategory() {
  try {
    const response = yield call(getTagCategory)
    yield put(getTagCategorySuccess(response))
  } catch (error) {
    yield put(getTagCategoryFail(error))
  }
}

function* onAddTag({ payload: tag }) {
  try {
    const response = yield call(addNewTag, tag)
    let result = response.map(x => ({
      ...x,
      TagCategories: {
        TagCategoryID: x.TagCategoryID ?? 0,
        TagCategory: x.TagCategory ?? "",
      },
    }))
    yield put(addNewTagSuccess(result))
    toast.success("Tag added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addNewTagFail(error))
    toast.error("Tag addition failed", { autoClose: 2000 })
  }
}

function* onDeleteTag({ payload: tagId }) {
  try {
    const response = yield call(deleteTag, tagId)
    yield put(deleteTagSuccess(response))
    toast.success("deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteTagFail(error))
    toast.error("deletion failed", { autoClose: 2000 })
  }
}

function* fetchMessages({ payload: childId }) {
  try {
    const response = yield call(getMessages, childId)

    yield put(getMessagesSuccess(response))
  } catch (error) {
    yield put(getMessagesFail(error))
  }
}

function* fetchAwaitedMessages() {
  try {
    const response = yield call(getAwaitedMessages)

    yield put(getAwaitedMessagesSuccess(response))
  } catch (error) {
    yield put(getAwaitedMessagesFail(error))
  }
}

function* fetchMessagePosts({ payload: messageId }) {
  try {
    const response = yield call(getMessagePosts, messageId)

    yield put(getMessagePostsSuccess(response))
  } catch (error) {
    yield put(getMessagePostsFail(error))
  }
}

function* onAddMessage({ payload: message }) {
  try {
    var formData = new FormData()
    let i = 1
    message.Attachment.forEach(item => {
      formData.append("Attachment" + i, item)
      i++
    })
    formData.append("Data", JSON.stringify(message))

    const response = yield call(addNewMessage, formData)
    yield put(addMessageSuccess(response))
    toast.success("Message added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addMessageFail(error))
    toast.error("Message addition failed", { autoClose: 2000 })
  }
}

function* onAddMessagePost({ payload: post }) {
  try {
    var formData = new FormData()

    let i = 1
    post.Attachment.forEach(item => {
      formData.append("Attachment" + i, item)
      i++
    })
    formData.append("Data", JSON.stringify(post))

    const response = yield call(addMessagePost, formData)
    yield put(addMessagePostSuccess(response))
    toast.success("Post added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addMessagePostFail(error))
    toast.error("Post addition failed", { autoClose: 2000 })
  }
}

function* onUpdateMessageApproval({ payload: message }) {
  try {
    const response = yield call(updateMessageApproval, message)
    yield put(updateMessageApprovalSuccess(response))
  } catch (error) {
    yield put(updateMessageApprovalFail(error))
  }
}

function* onDeleteMessage({ payload: messageId }) {
  try {
    const response = yield call(deleteMessage, messageId)
    yield put(deleteMessageSuccess(response))
  } catch (error) {
    yield put(deleteMessageFail(error))
  }
}

function* onDeleteMessagePost({ payload: messagePostId }) {
  try {
    const response = yield call(deleteMessagePost, messagePostId)
    yield put(deleteMessagePostSuccess(response))
  } catch (error) {
    yield put(deleteMessagePostFail(error))
  }
}

function* onResetChild() {
  try {
    yield put(resetChildSuccess({}))
  } catch (error) {}
}
function* childSaga() {
  yield takeEvery(GET_CHILDREN, fetchChildren)
  yield takeEvery(DELETE_CHILD, onDeleteChild)
  yield takeEvery(ADD_NEW_CHILD, onAddNewChild)
  yield takeEvery(GET_CHILD_DETAIL, fetchChildDetail)
  yield takeEvery(UPDATE_CHILD, onUpdateChild)
  yield takeEvery(UPDATE_CHILD_PHOTO, onUpdateChildPhoto)
  yield takeEvery(RESET_CHILD, onResetChild)
  yield takeEvery(GET_CHILD_PROVIDED_FOOD, fetchParentProvidedFood)
  yield takeEvery(ADD_CHILD_PROVIDED_FOOD, onAddParentProvidedFood)
  yield takeEvery(GET_CHILD_NOTES, fetchChildNotes)
  yield takeEvery(ADD_CHILD_NOTES, onAddChildNotes)
  yield takeEvery(ADD_CHILD_AVAILABILITY, onAddChildAvailability)
  yield takeEvery(GET_CHILD_AVAILABILITY, fetchChildAvailability)
  yield takeEvery(GET_CHILD_RELATIONS, fetchChildRelations)
  yield takeEvery(GET_RELATION_TYPES, fetchRelationTypes)
  yield takeEvery(GET_PERMISSION_TYPES, fetchPermissionTypes)
  yield takeEvery(ADD_CHILD_RELATION, onAddChildRelation)
  yield takeEvery(DELETE_CHILD_RELATION, onDeleteChildRelation)

  //  Child Happening
  yield takeEvery(GET_HAPPENING_TYPES, fetchChildHapeningTypes)
  yield takeEvery(GET_CHILD_HAPPENING, fetchChildHapenings)

  yield takeEvery(ADD_CHILD_HAPPENING, onAddChildHappening)
  yield takeEvery(DELETE_CHILD_HAPPENING, onDeleteChildHappening)
  yield takeEvery(GET_CHILD_HAPPENING_DETAIL, fetchChildHappeningDetail)
  yield takeEvery(UPDATE_CHILD_HAPPENING, onUpdateChildHappening)
  //yield takeEvery(GET_HOUR_TYPES, fetchHourTypes)

  //  Child Tags
  yield takeEvery(GET_TAG_CATEGORY, fetchTagCategory)
  yield takeEvery(GET_TAGS, fetchTags)
  yield takeEvery(ADD_NEW_TAG, onAddTag)
  yield takeEvery(DELETE_TAG, onDeleteTag)

  //  Child Messages
  yield takeEvery(GET_MESSAGES, fetchMessages)
  yield takeEvery(GET_MESSAGE_POSTS, fetchMessagePosts)
  yield takeEvery(ADD_NEW_MESSAGE, onAddMessage)
  yield takeEvery(ADD_MESSAGE_POST, onAddMessagePost)
  yield takeEvery(GET_AWAITED_MESSAGES, fetchAwaitedMessages)
  yield takeEvery(UPDATE_MESSAGE_APPROVAL, onUpdateMessageApproval)
  yield takeEvery(DELETE_MESSAGE, onDeleteMessage)
  yield takeEvery(DELETE_MESSAGE_POST, onDeleteMessagePost)
}

export default childSaga
