import React, { useState, useEffect, useMemo, Fragment } from "react"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Col, Row, Card, CardBody, UncontrolledTooltip } from "reactstrap"

import { Link, useNavigate } from "react-router-dom"

import { isEmpty } from "lodash"

import TableContainer from "components/Common/TableContainer"
import { getAdultImagePath } from "helpers/imageService"
import RelationModal from "../tabs/RelationModal"
import DeleteModal from "components/Common/DeleteModal"

import { deleteRelation as onDeleteRelation } from "store/actions"

function Relations() {
  const dispatch = useDispatch()
  const [adultModal, setAdultModal] = useState(false)
  const [relation, setRelation] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)

  const { relations } = useSelector(state => state.child)

  const handleAddClick = () => {
    setAdultModal(true)
  }

  const onCloseModal = () => {
    setAdultModal(false)
    setRelation({})
  }

  const onClickEdit = arg => {
    setRelation(arg)
    setAdultModal(true)
  }

  const onClickDelete = arg => {
    setRelation(arg)
    setDeleteModal(true)
  }

  const handleDelete = () => {
    if (relation && relation.ChildRelationID) {
      dispatch(onDeleteRelation(relation.ChildRelationID))
      setDeleteModal(false)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "RowID",
        Cell: cellProps => {
          return (
            <>
              <div className="d-flex align-items-center">
                <div className="me-2">
                  <img
                    className="rounded-circle avatar-xs"
                    src={getAdultImagePath(
                      cellProps.row.original.RowID,
                      "image.jpeg"
                    )}
                    alt="Header Avatar"
                  />
                </div>

                <div>
                  <h5 className="font-size-14 mb-1">
                    <Link className="text-dark" to="#">
                      {cellProps.row.original.Name}
                    </Link>
                  </h5>
                  <p className="text-muted mb-0">
                    {cellProps.row.original.Relation}
                  </p>
                </div>
              </div>
            </>
          )
        },
      },
      {
        Header: "Permission",
        accessor: "Permissions",
        Cell: cellProps => {
          return <>{cellProps.row.original.Permissions}</>
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                hidden={cellProps.row.original.RelationTypeID === 0}
                className="text-secondary"
                onClick={() => {
                  onClickEdit(cellProps.row.original)
                }}
              >
                <i className="bx bx-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                hidden={cellProps.row.original.RelationTypeID === 0}
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="bx bx-trash font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <Fragment>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      {adultModal && (
        <RelationModal
          show={adultModal}
          data={relation}
          onCloseClick={() => onCloseModal()}
        />
      )}

      <Row>
        <Col xs="12">
          <TableContainer
            columns={columns}
            data={relations}
            isAddOption={true}
            addOptionText="Add Existing Adult"
            handleAddClick={handleAddClick}
            customPageSize={10}
            isPagination={true}
            isheaderTitle={true}
            headerTitle="Family"
            tableClass="align-middle table-nowrap table-check"
            theadClass="table-light"
            pagination="pagination pagination-rounded mb-0 ms-auto"
          />
        </Col>
      </Row>
    </Fragment>
  )
}
export default Relations
