import React, { useEffect, useState, Fragment, useMemo } from "react"
import {
  Table,
  Row,
  Col,
  Tooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Moment from "moment"
// import { ToastContainer } from "react-toastify"
import { isEmpty } from "lodash"

import {
  getDoctorSchedules as onGetDoctorSchedules,
  deleteDoctorSchedule as onDeleteDoctorSchedule,
  getMeetingStartURL as onGetMeetingStartURL,
  doctorMeetingSync as onDoctorMeetingSync,
} from "store/actions"
import Spinners from "components/Common/Spinner"
import AssignModal from "./modal/AssignModal"
import { useDeepCompareEffect } from "hooks"
import Paginations from "components/Common/Pagination"
import DeleteModal from "components/Common/DeleteModal"
import { EnumRoles } from "helpers/enum_helper"
import { RESET_DOCTOR_SCHEDULE } from "store/scheduling/doctor/actionTypes"
import AlertContainer from "components/Common/AlertContainer"

function DoctorScheduling() {
  const dispatch = useDispatch()
  const [assignModal, setAssignModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [doctorScheduleID, setDoctorScheduleID] = useState(0)
  const [tooltipOpen, setTooltipOpen] = useState({})
  const [filter, setFilter] = useState({
    date: Moment().format("YYYY-MM-DD"),
    objectID: 0,
    roleID: EnumRoles.Staff,
  })

  // pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [dataList, setDataList] = useState()
  const perPageData = 5
  const indexOfLast = currentPage * perPageData
  const indexOfFirst = indexOfLast - perPageData

  useDeepCompareEffect(() => {
    dispatch(
      onGetDoctorSchedules({
        date: filter.date,
        objectID: 0,
        roleID: EnumRoles.Staff,
      })
    )
    dispatch({ type: RESET_DOCTOR_SCHEDULE })
  }, [filter])

  //////////////////STAFF SCHEDULE STATE/////////////////////
  const selectDoctorScheduleState = state => state.doctorSchedules
  const DoctorScheduleProperties = createSelector(
    selectDoctorScheduleState,
    e => ({
      data: e.doctorSchedules,
      loading: e.loading,
      column: e.column,
      syncResponse: e.syncResponse,
    })
  )
  const { data, loading, column, syncResponse } = useSelector(
    DoctorScheduleProperties
  )
  const [isLoading, setLoading] = useState(loading)
  //////////////////////////////////////

  //////////////////HELPER STATE/////////////////////
  const { meetingStartURL } = useSelector(state => state.helper)
  //////////////////////////////////////

  function handleDate(param) {
    setFilter({ date: param })
  }

  const onAssignModalClick = () => {
    setAssignModal(true)
  }

  const onStartClick = (event, meetingKey) => {
    event.preventDefault()
    dispatch(onGetMeetingStartURL(meetingKey))
  }

  // useEffect(() => {
  //   if (meetingStartURL) {
  //     window.open(meetingStartURL, "_blank") // Open the meeting in a new tab
  //   }
  // }, [meetingStartURL])

  const onCloseModal = () => {
    setAssignModal(false)
  }

  const onClickDelete = arg => {
    setDoctorScheduleID(arg)
    setDeleteModal(true)
  }

  const onClickSync = arg => {
    dispatch(onDoctorMeetingSync(arg))
  }

  const handleDelete = () => {
    if (doctorScheduleID > 0) {
      dispatch(onDeleteDoctorSchedule(doctorScheduleID))
      setDeleteModal(false)
    }
  }

  const currentdata = useMemo(
    () => data ?? data.slice(indexOfFirst, indexOfLast),
    [data, indexOfFirst, indexOfLast]
  )

  const toggleTooltip = id => {
    setTooltipOpen(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }

  useEffect(() => {
    setDataList(currentdata)
    // if (meetingStartURL != "") {
    //   window.location.href = meetingStartURL
    // }
    if (meetingStartURL !== "") {
      window.open(meetingStartURL, "_blank") // Open meeting in a new tab
    }
  }, [currentdata, meetingStartURL])

  if (isLoading) {
    return <Spinners setLoading={setLoading} />
  }

  return (
    <Fragment>
      {assignModal && (
        <AssignModal show={assignModal} onCloseClick={() => onCloseModal()} />
      )}
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}

      <Row className="mb-2">
        <Col sm="10">
          <div className="text-sm-end">
            <div className="btn-group" role="group">
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio4"
                autoComplete="off"
                onClick={() =>
                  handleDate(
                    Moment(filter.date).subtract(7, "days").format("YYYY-MM-DD")
                  )
                }
              />
              <label className="btn btn-outline-secondary" htmlFor="btnradio4">
                <i className="fas fa-angle-left" />
              </label>

              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio5"
                autoComplete="off"
                defaultChecked
                onClick={() => handleDate(Moment().format("YYYY-MM-DD"))}
              />
              <label className="btn btn-outline-secondary" htmlFor="btnradio5">
                Today
              </label>

              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio6"
                autoComplete="off"
                onClick={() =>
                  handleDate(
                    Moment(filter.date).add(7, "days").format("YYYY-MM-DD")
                  )
                }
              />
              <label className="btn btn-outline-secondary" htmlFor="btnradio6">
                <i className="fas fa-angle-right" />
              </label>
            </div>
          </div>
        </Col>
        <Col sm="2">
          <div className="text-sm-end">
            <button
              type="button"
              className="btn btn btn-primary"
              onClick={() => onAssignModalClick()}
            >
              Assign
            </button>
          </div>
        </Col>
      </Row>
      <AlertContainer
        message={syncResponse?.Message}
        color={syncResponse?.Color}
      />
      <div className="table-responsive">
        <Table className="align-middle table-nowrap table-check table-bordered">
          <thead className="table-light">
            <tr>
              {column.map((row, index) => (
                <th
                  key={"th" + index}
                  className={row === "DoctorID" ? "d-none" : ""}
                >
                  <div className="m-0">{row}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataList.map((n, index) => {
              return (
                <Fragment key={"dl" + index}>
                  <tr style={{ height: "100px" }}>
                    {column.map((v, index) => {
                      return (
                        <td
                          key={"clm" + index}
                          className={v === "DoctorID" ? "d-none" : "align-top"}
                        >
                          {/* {console.log("Inner Loop", n[v])} */}
                          {v !== "Name" &&
                          v !== "DoctorID" &&
                          v !== "id" &&
                          n[v] !== "" &&
                          n[v] !== undefined
                            ? JSON.parse(n[v]).map(w => (
                                <div
                                  className="border rounded p-2 mb-2 w-100 cursor-pointer"
                                  key={"dv" + w.DoctorSchedulingID}
                                >
                                  <div className="float-end">
                                    <UncontrolledDropdown className="d-inline">
                                      <DropdownToggle
                                        href="#!"
                                        tag="a"
                                        className="dropdown-toggle"
                                      >
                                        <i
                                          style={{ fontSize: "20px" }}
                                          className="bx bx-dots-vertical-rounded"
                                        />
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem
                                          hidden={w.IsCompleted || w.IsPassed}
                                          onClick={e =>
                                            onStartClick(e, w.MeetingKey)
                                          }
                                          href="#"
                                        >
                                          Start
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() =>
                                            onClickSync(w.DoctorSchedulingID)
                                          }
                                          href="#"
                                        >
                                          Sync
                                        </DropdownItem>
                                        <DropdownItem
                                          hidden={w.IsCompleted || w.IsPassed}
                                          onClick={() =>
                                            onClickDelete(w.DoctorSchedulingID)
                                          }
                                          href="#"
                                        >
                                          Delete
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                  <h6>
                                    {" "}
                                    {w.Title}
                                    <i
                                      hidden={!w.IsCompleted}
                                      className="p-1 bx bx-check"
                                    ></i>
                                    <span
                                      style={{ width: "30px" }}
                                      className="p-2"
                                      hidden={w.IsCompleted || w.IsPassed}
                                    >
                                      <i
                                        className="bx bx-notepad"
                                        id={`tooltip-${w.DoctorSchedulingID}`}
                                      ></i>
                                      <Tooltip
                                        placement="bottom"
                                        isOpen={
                                          tooltipOpen[w.DoctorSchedulingID] ||
                                          false
                                        }
                                        target={`tooltip-${w.DoctorSchedulingID}`}
                                        toggle={() => {
                                          toggleTooltip(w.DoctorSchedulingID)
                                        }}
                                      >
                                        <h5>
                                          {`Meeting Key : ` + w.MeetingKey}
                                        </h5>
                                        <h5>
                                          {`Meeting Passcode : ` +
                                            w.MeetingPassword}
                                        </h5>
                                      </Tooltip>
                                    </span>
                                  </h6>
                                  <span>
                                    {Moment(w.StartTime).format("hh:mm A") +
                                      " - " +
                                      Moment(w.EndTime).format("hh:mm A")}
                                  </span>
                                </div>
                              ))
                            : n[v]}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
        <Paginations
          perPageData={5}
          data={data}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isShowingPageLength={false}
          paginationDiv="col-12"
          paginationClass="pagination pagination-rounded justify-content-center mt-2 mb-5"
        />
      </div>
      {/* <ToastContainer /> */}
    </Fragment>
  )
}
export default DoctorScheduling
