/*Get  RESOURCES */
export const GET_RESOURCES = "GET_RESOURCES"
export const GET_RESOURCES_SUCCESS = "GET_RESOURCES_SUCCESS"
export const GET_RESOURCES_FAIL = "GET_RESOURCES_FAIL"

/* Reset  RESOURCES */
export const RESET_RESOURCE = "RESET_RESOURCE"
export const RESET_RESOURCE_SUCCESS = "RESET_RESOURCE_SUCCESS"

/*Add  RESOURCE */
export const ADD_RESOURCE = "ADD_RESOURCE"
export const ADD_RESOURCE_SUCCESS = "ADD_RESOURCE_SUCCESS"
export const ADD_RESOURCE_FAIL = "ADD_RESOURCE_FAIL"

/* Get  RESOURCE DETAILS */
export const GET_RESOURCE_DETAIL = "GET_RESOURCE_DETAIL"
export const GET_RESOURCE_DETAIL_SUCCESS = "GET_RESOURCE_DETAIL_SUCCESS"
export const GET_RESOURCE_DETAIL_FAIL = "GET_RESOURCE_DETAIL_FAIL"

/*Edit  RESOURCE */
export const UPDATE_RESOURCE = "UPDATE_RESOURCE"
export const UPDATE_RESOURCE_SUCCESS = "UPDATE_RESOURCE_SUCCESS"
export const UPDATE_RESOURCE_FAIL = "UPDATE_RESOURCE_FAIL"

/* Delete  RESOURCE */
export const DELETE_RESOURCE = "DELETE_RESOURCE"
export const DELETE_RESOURCE_SUCCESS = "DELETE_RESOURCE_SUCCESS"
export const DELETE_RESOURCE_FAIL = "DELETE_RESOURCE_FAIL"