import React, { useState, useEffect } from "react"

//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
  InputGroup,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import Switch from "react-switch"

//react form
//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//navigation
import { Link, useNavigate, useParams } from "react-router-dom"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

import { isEmpty } from "lodash"
//Date filter
import Moment from "moment"
import {
  isValidFileType,
  MAX_FILE_SIZE,
  ValidExtensions,
} from "pages/Utility/constants"
import {
  addCurriculum as onAddCurriculum,
  updateCurriculum as onUpdateCurriculum,
  getClasses as onGetClasses,
  resetCurriculum as onResetCurriculum,
} from "store/actions"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"
import Spinners from "components/Common/Spinner"
import PageModal from "components/Common/PageModal"
const formdefault = {
  CurriculumID: 0,
  Name: "",
  IsShared: false,
  Link: "",
  File: "",
}
function BasicInfoTab({ curriculum }) {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  // const [isLoading, setLoading] = useState(loading)
  const { classes, loading } = useSelector(state => state.class1)
  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    CurriculumID: yup.number(),
    Name: yup.string().required("Required"),
    Classes: yup.object().required("Required"),
    IsShared: yup.bool(),
    Link: yup.string().nullable(),
    File: yup
      .mixed()
      .transform((_, val) => (val ? String(val) : null))
      .notRequired(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  useDeepCompareEffect(() => {
    function updateCurriculumState() {
      dispatch(onGetClasses())
    }

    updateCurriculumState()
  }, [dispatch])

  useEffect(() => {
    const { id } = routeParams
    if (id === "new") {
      reset()
    } else {
      //reset form with server data
      const response = {
        ...curriculum,
        Classes: {
          ClassID: Number(curriculum?.ClassID),
          Title: curriculum?.Title,
        },
        Link: curriculum?.Link || "",
      }
      reset(response)
    }

    return () => {
      //dispatch(onResetCurriculum())
    }
  }, [dispatch, curriculum])

  const onCancelClick = () => {
    navigate("/learning/curriculums")
  }

  const handleSave = () => {
    trigger() //for form validation
    if (isValid) {
      const { id } = routeParams

      const newCurriculum = {
        ...getValues(),
        // id: id,
        CurriculumID: curriculum?.CurriculumID,
      }
      if (id === "new") {
        dispatch(onAddCurriculum({ ...getValues() }))
      } else {
        dispatch(
          onAddCurriculum({
            ...getValues(),
            CurriculumID: curriculum?.CurriculumID,
          })
        )
      }

      navigate("/learning/curriculums")
    }
  }

  return (
    <FormProvider {...methods}>
      <Row>
        <Col lg={6}>
          <div className="mb-3">
            <Label>Title</Label>
            <Controller
              name="Name"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="Name"
                    type="text"
                    required
                    invalid={!!errors.Name}
                  />
                  {errors?.Title?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Name?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>

          <div className="mb-3">
            <Label>Class</Label>
            <Controller
              name="Classes"
              control={control}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    id="Classes"
                    options={classes.filter(x => x.RoomID > 0)}
                    getOptionLabel={option => option.Title}
                    getOptionValue={option => option.ClassID}
                    required
                    aria-invalid={!!errors.Classes}
                    classNamePrefix="select2-selection"
                  />
                  {errors?.Classes?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Classes?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Shared with Parent</Label>
            <div>
              <Controller
                name="IsShared"
                control={control}
                render={({ field }) => (
                  <>
                    <Switch
                      {...field}
                      id="IsShared"
                      checked={field.value}
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      className="me-1 mb-sm-8 mb-2"
                      onColor="#626ed4"
                    />
                  </>
                )}
              />
            </div>
          </div>
          <div className="mb-3">
            <Label>Link</Label>
            <Controller
              name="Link"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="Link"
                    type="text"
                    value={field.value || ""}
                  />
                  {/* {errors?.Link?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Link?.message}
                    </FormFeedback>
                  ) : null} */}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>File</Label>
            <Controller
              name="File"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    className="form-control"
                    type="file"
                    id="File"
                    value={field.value?.File}
                    onChange={e => field.onChange(e.target.files[0])}
                    // required
                    // invalid={!!errors.File}
                  />
                  {/* {errors?.File?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.File?.message}
                        </FormFeedback>
                      ) : null} */}
                </>
              )}
            />
            <a>{getValues("FileName") && "File : " + getValues("FileName")}</a>
          </div>
        </Col>
      </Row>
      <div className="d-flex flex-wrap gap-2">
        <Button color="primary" className="btn" onClick={handleSave}>
          {routeParams.id === "new" ? "Save" : "Update"}
        </Button>
        <Button
          type="submit"
          color="secondary"
          onClick={onCancelClick}
          className=""
        >
          Cancel
        </Button>
      </div>
    </FormProvider>
  )
}
export default BasicInfoTab
