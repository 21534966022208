import PropTypes, { object } from "prop-types"
import React, { useEffect } from "react"
//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Modal,
  ModalBody,
  InputGroup,
  FormFeedback,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import Flatpickr from "react-flatpickr"

const ViewMenuModal = ({ show, menu, onCloseClick }) => {
  const onClosed = () => {
    reset()
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      onClosed={() => onClosed()}
      centered={true}
    >
      <ModalHeader className="d-flex justify-content-center">
        View Detail
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="mb-3">
              <Label>Menu</Label>
              <div>{menu}</div>
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <button
            type="button"
            className="btn btn btn-secondary"
            onClick={onCloseClick}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

ViewMenuModal.propTypes = {
  onCloseClick: PropTypes.func,
  //  onSaveClick: PropTypes.func,
  show: PropTypes.any,
  menu: PropTypes.any,
}

export default ViewMenuModal
