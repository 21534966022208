import React, { Fragment } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useExpanded,
  usePagination,
} from "react-table"
import { Table, Row, Col, Button } from "reactstrap"
import { Link } from "react-router-dom"
import * as XLSX from "xlsx"

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <div className="search-box position-relative mb-2">
        <span id="search-bar-0-label" className="sr-only">
          Search this table
        </span>
        <input
          onChange={e => {
            setValue(e.target.value)
            onChange(e.target.value)
          }}
          id="search-bar-0"
          type="text"
          className="form-control"
          placeholder={`${count} records...`}
          value={value || ""}
        />

        <i className="bx bx-search-alt search-icon"></i>
      </div>
    </React.Fragment>
  )
}

const TableContainer = ({
  columns,
  columnsForExport,
  data,
  isGlobalFilter,
  isAddOption,
  addOptionText,
  handleAddClick,
  customPageSize,
  customPageSizeOptions,
  iscustomPageSizeOptions,
  isPagination,
  isShowingPageLength,
  paginationDiv,
  pagination,
  tableClass,
  theadClass,
  isheaderTitle,
  headerTitle,
  isExportOption,
}) => {
  const exportToExcel = () => {
    console.log(columnsForExport)
    if (!columnsForExport || !columnsForExport.length) {
      console.error("No columns defined for export")
      return
    }

    // Extract headers from columnsForExport
    const headers = columnsForExport.map(col => col.Header)

    // Extract data rows based on accessor functions in columnsForExport
    const rows = data.map(row => {
      return columnsForExport.map(col => {
        const accessor = col.accessor
        return typeof accessor === "function" ? accessor(row) : row[accessor]
      })
    })

    // Combine headers and rows into a single sheet
    const sheetData = [headers, ...rows]

    // Create a new worksheet
    const ws = XLSX.utils.aoa_to_sheet(sheetData)

    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1")

    // Save the workbook to a file
    XLSX.writeFile(wb, headerTitle + " Report.xlsx")
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      // defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
      },
    },
    useGlobalFilter,
    // useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  return (
    <Fragment>
      <Row className="mb-2 justify-content-between align-items-center">
        <Col sm={isheaderTitle ? 4 : 6} lg={isheaderTitle ? 4 : 3}>
          {isheaderTitle && <h5>{headerTitle}</h5>}
          {!isheaderTitle && isGlobalFilter && (
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          )}
        </Col>
        <Col
          sm={isheaderTitle ? 8 : 4}
          className="d-flex justify-content-end flex-column flex-md-row"
        >
          {isheaderTitle && isGlobalFilter && (
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          )}

          <div className="ms-3 text-end">
            {isExportOption && (
              <Button onClick={exportToExcel} className="btn mb-2 mt-2 mt-lg-0">
                Export
              </Button>
            )}
            {isAddOption && (
              <Button
                type="button"
                color="primary"
                className="btn mb-2"
                onClick={handleAddClick}
              >
                <i className="mdi mdi-plus me-1" />
                {addOptionText}
              </Button>
            )}
          </div>
        </Col>
      </Row>

      <div className="table-responsive">
        <Table {...getTableProps()} className={tableClass} id={headerTitle}>
          <thead className={theadClass}>
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    key={column.id}
                    className={column.isSort ? "sorting" : ""}
                  >
                    <div className="m-0" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                    </div>
                    {/* <Filter column={column} /> */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>

      {isPagination && data.length > 0 && (
        <Row>
          <Col md={4} lg={2}>
            {iscustomPageSizeOptions && (
              <select
                className="form-select mb-2 mb-md-0"
                value={pageSize}
                onChange={onChangeInSelect}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            )}
          </Col>

          <Col
            md={8}
            lg={10}
            className="d-flex justify-content-between align-items-center"
          >
            {isShowingPageLength && (
              <div className="text-muted">
                Showing <span className="fw-semibold">{page.length}</span> of{" "}
                <span className="fw-semibold">{data.length}</span> entries
              </div>
            )}
            <ul className={pagination}>
              <li className={`page-item ${!canPreviousPage ? "disabled" : ""}`}>
                <Link to="#" className="page-link" onClick={previousPage}>
                  <i className="mdi mdi-chevron-left"></i>
                </Link>
              </li>
              {pageOptions.map((item, key) => (
                <React.Fragment key={key}>
                  <li
                    className={
                      pageIndex === item ? "page-item active" : "page-item"
                    }
                  >
                    <Link
                      to="#"
                      className="page-link"
                      onClick={() => gotoPage(item)}
                    >
                      {item + 1}
                    </Link>
                  </li>
                </React.Fragment>
              ))}
              <li className={`page-item ${!canNextPage ? "disabled" : ""}`}>
                <Link to="#" className="page-link" onClick={nextPage}>
                  <i className="mdi mdi-chevron-right"></i>
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
      )}
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
