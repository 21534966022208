import {
  GET_DOCTOR_SCHEDULES_FAIL,
  GET_DOCTOR_SCHEDULES_SUCCESS,
  ADD_DOCTOR_SCHEDULES_SUCCESS,
  ADD_DOCTOR_SCHEDULES_FAIL,
  DELETE_DOCTOR_SCHEDULE_SUCCESS,
  DELETE_DOCTOR_SCHEDULE_FAIL,
  RESET_DOCTOR_SCHEDULE,
  DOCTOR_MEETING_SYNC_SUCCESS,
  DOCTOR_MEETING_SYNC_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  doctorSchedules: [],
  column: [],
  syncResponse: {},
  error: {},
  loading: true,
  closeModal: false,
}

const Schedules = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DOCTOR_SCHEDULES_SUCCESS:
      return {
        ...state,
        doctorSchedules: action.payload,
        column: Object.keys(action.payload[0]),
        loading: true,
      }
    case GET_DOCTOR_SCHEDULES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_DOCTOR_SCHEDULES_SUCCESS:
      return {
        ...state,
        doctorSchedules: action.payload,
        loading: false,
        error: {},
        closeModal: true,
      }
    case ADD_DOCTOR_SCHEDULES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        closeModal: false,
      }
    case DELETE_DOCTOR_SCHEDULE_SUCCESS: {
      return {
        ...state,
        doctorSchedules: action.payload,
        column: Object.keys(action.payload[0]),
        loading: true,
      }
    }
    case DELETE_DOCTOR_SCHEDULE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DOCTOR_MEETING_SYNC_SUCCESS:
      return {
        ...state,
        syncResponse: action.payload,
      }
    case DOCTOR_MEETING_SYNC_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case RESET_DOCTOR_SCHEDULE:
      return {
        ...state,
        syncResponse: {},
        error: {},
        closeModal: false,
      }
    default:
      return state
  }
}

export default Schedules
