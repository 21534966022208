import React, { useEffect, useRef, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import PropTypes from "prop-types"
import { isEmpty, map } from "lodash"
import * as moment from "moment"
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledAlert,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
// emoji
import EmojiPicker from "emoji-picker-react"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

// simple bar
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"

// import { ToastContainer } from "react-toastify"
import { getForumPostImagePath } from "helpers/imageService"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import {
  getForumDetail as onGetForumDetail,
  deleteForumPost as onDeleteForumPost,
  addNewForumPost as onAddForumPost,
  updateForumPostFile as onUpdateForumPostFile,
} from "store/actions"
import { useDeepCompareEffect } from "hooks"

const Discussion = () => {
  const routeParams = useParams()
  //meta title
  document.title = "Chat"
  const dispatch = useDispatch()

  useDeepCompareEffect(() => {
    const { id } = routeParams

    dispatch(onGetForumDetail(id))
  }, [dispatch, routeParams])

  const { forum, loading } = useSelector(state => state.forum)
  const [postsData, setPostsData] = useState()
  const [isLoading, setLoading] = useState(loading)
  const [emoji, setEmoji] = useState(false)
  const [curPost, setcurPost] = useState("")
  const [selectedImage, setSelectedImage] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [isdisable, setDisable] = useState(false)
  const [selectedReplyPost, setSelectedReplyPost] = useState(null)
  const [threshhold, setthreshhold] = useState(0)
  const [disDefault, setdisDefault] = useState(0)

  useEffect(() => {
    setPostsData(forum.ForumPosts)
  }, [forum])

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y HH:mm")
    return date1
  }

  const onKeyPress = e => {
    const { key, value } = e
    if (key === "Enter") {
      setcurPost(value)
      setDisable(true)
      addPost()
    }
  }

  const addPost = () => {
    if (curPost !== "" || selectedImage !== null) {
      const newPost = {
        ForumID: forum.ForumID,
        Title: curPost,
        PostID: selectedReplyPost != null ? selectedReplyPost.PostID : 0,
        Photo: selectedFile,
      }
      dispatch(onAddForumPost(newPost))
      setcurPost("")
      setSelectedReplyPost(null)
      setdisDefault(false)
      setDisable(false)
      setEmoji(false)
      setSelectedImage(null)
      setSelectedFile(null)
    }
  }

  const deletePost = postID => {
    dispatch(onDeleteForumPost(postID))
  }

  const replyPost = post => {
    setSelectedReplyPost(post)
  }

  const [copyPostAlert, setCopyPostAlert] = useState(false)
  const copyPost = ele => {
    var copyText = ele
      .closest(".conversation-list")
      .querySelector("p").innerHTML
    navigator.clipboard.writeText(copyText)
    setCopyPostAlert(true)
    if (copyText) {
      setTimeout(() => {
        setCopyPostAlert(false)
      }, 1000)
    }
  }

  // scroll simple bar
  const scroollRef = useRef(null)
  useEffect(() => {
    if (scroollRef.current) {
      scroollRef.current.getScrollElement().scrollTop =
        scroollRef.current.getScrollElement().scrollHeight
    }
  }, [postsData])

  // emoji
  const [emojiArray, setemojiArray] = useState("")
  const onEmojiClick = (event, emojiObject) => {
    setemojiArray([...emojiArray, emojiObject.emoji])
    setcurPost(curPost + event.emoji)
    setDisable(true)
  }

  //  img upload
  const handleImageChange = event => {
    event.preventDefault()
    let allowedExtension = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/bmp",
    ]

    let reader = new FileReader()
    let file = event.target.files[0]
    if (allowedExtension.indexOf(file?.type) > -1) {
      reader.onloadend = () => {
        setSelectedImage(reader.result)
        setDisable(true)
      }
      reader.readAsDataURL(file)
      setSelectedFile(file)
    }
  }

  function threshholdDefault(event) {
    const count = event.target.value.length
    if (count > 0) {
      setdisDefault(true)
    } else {
      setdisDefault(false)
    }
    setthreshhold(event.target.value.length)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Forums" breadcrumbItem="Discussion" />

          <Row>
            <Col lg="12">
              <div className="d-lg-flex">
                <div className="w-100 user-chat">
                  <Card>
                    <div className="p-4 border-bottom ">
                      <Row>
                        <Col md="4" xs="9">
                          <h5 className="font-size-15 mb-1">{forum.Title}</h5>
                          <p className="text-muted mb-0">
                            <i
                              className={
                                forum.ForumVisibilityType === "public"
                                  ? "mdi mdi-circle text-success align-middle me-2"
                                  : forum.ForumVisibilityType === "private"
                                  ? "mdi mdi-circle text-warning align-middle me-1"
                                  : "mdi mdi-circle align-middle me-1"
                              }
                            />
                            {forum.ForumVisibilityType}
                          </p>
                        </Col>
                        <Col md="8" xs="3">
                          <div className="text-end d-none">
                            <i className="bx bx-bell bx-tada"></i>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col md="12" xs="12">
                          <p>{forum.Description}</p>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <div className="chat-conversation p-3">
                        <SimpleBar ref={scroollRef} style={{ height: "486px" }}>
                          {isLoading ? (
                            <Spinners setLoading={setLoading} />
                          ) : (
                            <ul className="list-unstyled mb-0">
                              {postsData &&
                                (postsData || []).map((post, index) => {
                                  return (
                                    <li
                                      key={index}
                                      style={{
                                        marginLeft: 30 * (post.RowLevel - 1),
                                      }}
                                    >
                                      {post.ReplyToPostID == 0 ? (
                                        ""
                                      ) : (
                                        <i className="bx bx-redo fs-2 align-top"></i>
                                      )}

                                      <div className="conversation-list">
                                        <UncontrolledDropdown>
                                          <DropdownToggle
                                            href="#!"
                                            tag="a"
                                            className="dropdown-toggle"
                                          >
                                            <i className="bx bx-dots-vertical-rounded" />
                                          </DropdownToggle>
                                          <DropdownMenu>
                                            <DropdownItem
                                              onClick={e => replyPost(post)}
                                              href="#"
                                            >
                                              Reply
                                            </DropdownItem>
                                            <DropdownItem
                                              onClick={e => copyPost(e.target)}
                                              href="#"
                                            >
                                              Copy
                                            </DropdownItem>
                                            <DropdownItem
                                              onClick={e =>
                                                deletePost(post.PostID)
                                              }
                                              href="#"
                                            >
                                              Delete
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                        <div className="ctext-wrap">
                                          <div className="conversation-name">
                                            {post.ModifiedByName}
                                          </div>
                                          <p>
                                            {post.FileName ? (
                                              <a
                                                href={getForumPostImagePath(
                                                  post.RowID,
                                                  post.FileName
                                                    ? post.FileName
                                                    : "image.jpeg"
                                                )}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <img
                                                  className=""
                                                  src={getForumPostImagePath(
                                                    post.RowID,
                                                    post.FileName
                                                      ? post.FileName
                                                      : "image.jpeg"
                                                  )}
                                                  style={{
                                                    maxWidth: "100px",
                                                    height: "auto",
                                                    marginRight: "2px",
                                                  }}
                                                  alt="File"
                                                />
                                              </a>
                                            ) : (
                                              ""
                                            )}

                                            {post.Title}
                                          </p>

                                          <p className="chat-time mb-0">
                                            <i className="bx bx-time-five align-middle me-1"></i>
                                            {handleValidDate(post.ModifiedDate)}
                                          </p>
                                        </div>
                                      </div>
                                    </li>
                                  )
                                })}
                            </ul>
                          )}
                        </SimpleBar>
                      </div>
                      {selectedImage && (
                        <div className="replymessage-block mb-0 d-flex align-items-start px-3 bg-light">
                          <div className="flex-grow-1">
                            <img
                              src={selectedImage}
                              alt="select img"
                              style={{ maxWidth: "150px", height: "auto" }}
                            />
                          </div>
                          <div className="flex-shrink-0">
                            <button
                              type="button"
                              id="close_toggle"
                              className="btn btn-sm btn-link mt-n2 me-n3 fs-18"
                              onClick={() => setSelectedImage(null)}
                            >
                              <i className="bx bx-x align-middle fs-2"></i>
                            </button>
                          </div>
                        </div>
                      )}

                      {selectedReplyPost && (
                        <div className="replymessage-block mb-0 d-flex align-items-start p-3 bg-light">
                          <div className="flex-grow-1 ctext-wrap">
                            <div className="conversation-name">
                              <b>Replying to-</b>
                              {selectedReplyPost.ModifiedByName}
                            </div>
                            <p>{selectedReplyPost.Title}</p>
                            <p className="chat-time mb-0">
                              <i className="bx bx-time-five align-middle me-1"></i>
                              {handleValidDate(selectedReplyPost.ModifiedDate)}
                            </p>
                          </div>
                          <div className="flex-shrink-0">
                            <button
                              type="button"
                              id="close_toggle"
                              className="btn btn-sm btn-link mt-n2 me-n3 fs-18"
                              onClick={() => setSelectedReplyPost(null)}
                            >
                              <i className="bx bx-x align-middle fs-2"></i>
                            </button>
                          </div>
                        </div>
                      )}

                      {copyPostAlert && (
                        <UncontrolledAlert
                          color="warning"
                          dismissible
                          role="alert"
                        >
                          Post copied
                        </UncontrolledAlert>
                      )}
                      {emoji && (
                        <EmojiPicker
                          onEmojiClick={onEmojiClick}
                          width={250}
                          height={382}
                        />
                      )}
                      <div className="p-3 chat-input-section">
                        <Row>
                          <Col>
                            <div className="position-relative">
                              <input
                                type="text"
                                value={curPost}
                                onKeyPress={onKeyPress}
                                onChange={e => {
                                  setcurPost(e.target.value)
                                  setDisable(true)
                                  threshholdDefault(e)
                                }}
                                className="form-control chat-input"
                                placeholder="Enter Post..."
                                maxLength="500"
                              />
                              <div className="chat-input-links">
                                <ul className="list-inline mb-0">
                                  <li
                                    className="list-inline-item"
                                    onClick={() => setEmoji(!emoji)}
                                  >
                                    <Link to="#">
                                      <i
                                        className="mdi mdi-emoticon-happy-outline me-1"
                                        id="Emojitooltip"
                                      />
                                      <UncontrolledTooltip
                                        placement="top"
                                        target="Emojitooltip"
                                      >
                                        Emojis
                                      </UncontrolledTooltip>
                                    </Link>
                                  </li>
                                  <li className="list-inline-item">
                                    <label
                                      htmlFor="imageInput"
                                      style={{ color: "#556ee6", fontSize: 16 }}
                                    >
                                      <i
                                        className="mdi mdi-file-image-outline me-1"
                                        id="Imagetooltip"
                                      />
                                      <UncontrolledTooltip
                                        placement="top"
                                        target="Imagetooltip"
                                      >
                                        Images
                                      </UncontrolledTooltip>
                                    </label>
                                    <input
                                      type="file"
                                      id="imageInput"
                                      className="d-none"
                                      accept="image/*"
                                      onChange={handleImageChange}
                                    />
                                  </li>
                                  {/* <li className="list-inline-item">
                                    <Link to="#">
                                      <i
                                        className="mdi mdi-file-document-outline"
                                        id="Filetooltip"
                                      />
                                      <UncontrolledTooltip
                                        placement="top"
                                        target="Filetooltip"
                                      >
                                        Add Files
                                      </UncontrolledTooltip>
                                    </Link>
                                  </li> */}
                                </ul>
                              </div>
                            </div>
                            {disDefault ? (
                              <span className="badgecount badge bg-success">
                                {threshhold} / 500
                              </span>
                            ) : null}
                          </Col>
                          <Col className="col-auto">
                            <Button
                              type="button"
                              color="primary"
                              disabled={!isdisable}
                              onClick={() => addPost()}
                              className="btn btn-primary btn-rounded chat-send w-md "
                            >
                              <span className="d-none d-sm-inline-block me-2">
                                Send
                              </span>
                              <i className="mdi mdi-send" />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <ToastContainer /> */}
    </React.Fragment>
  )
}

export default Discussion
