import React from "react"
import PropTypes from "prop-types"
import { UncontrolledAlert } from "reactstrap"

const AlertContainer = props => {
  return (
    <React.Fragment>
      {props?.message ? (
        <UncontrolledAlert color={props.color} role="alert">
          {props.message}
        </UncontrolledAlert>
      ) : null}
    </React.Fragment>
  )
}

AlertContainer.propTypes = {
  message: PropTypes.string,
  color: PropTypes.string,
}

export default AlertContainer
