import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Modal,
  ModalBody,
  InputGroup,
  FormFeedback,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import Switch from "react-switch"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import { createSelector } from "reselect"
import Select, { components } from "react-select"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
//navigation
import { Link, useNavigate, useParams } from "react-router-dom"
//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"
import FormUpload from "components/Common/Upload/FormUpload"
import Spinners from "components/Common/Spinner"
import {
  isValidFileType,
  MAX_FILE_SIZE,
  ValidExtensions,
} from "pages/Utility/constants"
import {
  addResource as onAddResource,
  // getPrivacyTypes as onGetPrivacyTypes,
  // getAdultRelations as onGetAdultRelations,
} from "store/actions"

const ResourceModal = ({
  show,
  objectRowID,
  resourceTypeID,
  data,
  onCloseClick,
}) => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const [filter, setFilter] = useState({
    objectRowID: objectRowID,
    resourceTypeID: resourceTypeID,
  })
  const [isLoading, setLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState([])
  const routeParams = useParams()
  useEffect(() => {
    if (data == null) {
      reset(formDefault)
    } else {
      // reset(data)
    }
  }, [dispatch])

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    ResourceID: yup.number().notRequired(),
    ObjectRowID: yup.string().notRequired(),
  })

  const formDefault = {
    ResourceID: 0,
    ObjectRowID: objectRowID,
    File: selectedFile,
  }

  const methods = useForm({
    mode: "onChange",
    defaultValues: formDefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, watch, setValue, getValues, trigger } =
    methods
  const { errors, isValid } = formState

  const onClosed = () => {
    reset()
  }
  const onUpload = files => {
    setSelectedFile(files)
  }
  const onSaveClick = () => {
    debugger
    trigger() //for form validation
    // if (isValid) {
    const { id } = routeParams

    dispatch(
      onAddResource({
        ObjectRowID: filter?.objectRowID,
        ResourceTypeID: filter?.resourceTypeID,
        File: selectedFile,
      })
    )
    setSelectedFile(null)
    onCloseClick()
    //  }
  }
  if (isLoading) {
    return <Spinners setLoading={setLoading} />
  }
  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      onClosed={() => onClosed()}
      centered={true}
    >
      <ModalHeader className="d-flex justify-content-center">
        Upload Resource
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <FormProvider {...methods}>
          <Row>
            <Col lg={12}>
              <FormUpload onUpload={onUpload} />
            </Col>
          </Row>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <button
            type="button"
            className="btn btn btn-primary"
            onClick={() => onSaveClick()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn btn-secondary"
            onClick={onCloseClick}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

ResourceModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
}

export default ResourceModal
