import {
  GET_FLEXIBLES_FAIL,
  GET_FLEXIBLES_SUCCESS,
  DELETE_FLEXIBLE_SUCCESS,
  DELETE_FLEXIBLE_FAIL,
  ADD_FLEXIBLE_FAIL,
  ADD_FLEXIBLE_SUCCESS,
  GET_FLEXIBLE_DETAIL_SUCCESS,
  GET_FLEXIBLE_DETAIL_FAIL,
  UPDATE_FLEXIBLE_SUCCESS,
  UPDATE_FLEXIBLE_FAIL,
  UPDATE_FLEXIBLE_SWITCH_SUCCESS,
  UPDATE_FLEXIBLE_SWITCH_FAIL,
  UPDATE_FLEXIBLE_DEFAULT_RATE_SUCCESS,
  UPDATE_FLEXIBLE_DEFAULT_RATE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  flexibles: [],
  flexible: {},
  error: {},
  loading: true,
}

const Flexible = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FLEXIBLES_SUCCESS:
      return {
        ...state,
        flexibles: action.payload,
        loading: true,
      }
    case GET_FLEXIBLES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_FLEXIBLE_SUCCESS: {
      const data = {
        ...state,
        flexibles: state.flexibles.filter(
          flexible => flexible.FlexibleID !== parseInt(action.payload)
        ),
      }
      return {
        ...state,
        flexibles: state.flexibles.filter(
          flexible => flexible.ProgramPayRateID !== action.payload
        ),
      }
    }
    case DELETE_FLEXIBLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_FLEXIBLE_SUCCESS:
      return {
        ...state,
        error: {},
        flexibles: action.payload,
      }
    case ADD_FLEXIBLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_FLEXIBLE_DETAIL_SUCCESS:
      return {
        ...state,
        flexible: action.payload,
        loading: true,
      }
    case GET_FLEXIBLE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_FLEXIBLE_SUCCESS:
      return {
        ...state,
        flexibles: action.payload,
        error: {},
        loading: true,
      }
    case UPDATE_FLEXIBLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_FLEXIBLE_SWITCH_SUCCESS:
      return {
        ...state,
        flexible: action.payload,
        loading: true,
      }
    case UPDATE_FLEXIBLE_SWITCH_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_FLEXIBLE_DEFAULT_RATE_SUCCESS:
      return {
        ...state,
        flexibles: action.payload,
        loading: true,
      }
    case UPDATE_FLEXIBLE_DEFAULT_RATE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Flexible
