import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Child Redux States
import {
  GET_CHILD_SCHEDULES,
  RESET_CHILD_SCHEDULES,
  ADD_CHILD_SCHEDULES,
  GET_CHILD_ABSENT_TYPES,
  DELETE_CHILD_SCHEDULE,
} from "./actionTypes"
import {
  getChildSchedulesSuccess,
  getChildSchedulesFail,
  resetChildScheduleSuccess,
  addChildScheduleSuccess,
  addChildScheduleFail,
  getChildAbsentTypesSuccess,
  getChildAbsentTypesFail,
  deleteChildScheduleSuccess,
  deleteChildScheduleFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getChildSchedules,
  addChildSchedule,
  getAbsentTypes as getChildAbsentTypes,
  deleteChildSchedule,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchChildSchedules({ filter }) {
  try {
    const response = yield call(getChildSchedules, filter)
    yield put(getChildSchedulesSuccess(response.Table))
  } catch (error) {
    yield put(getChildSchedulesFail(error))
  }
}

function* onAddChildSchedule({ payload: childSchedules }) {
  try {
    const request = {
      ...childSchedules,
      ChildID: childSchedules.Children.ChildID,
      ClassID: childSchedules.Classes?.ClassID ?? null,
      AbsentTypeID: childSchedules.AbsentTypes?.AbsentTypeID ?? null,
    }

    const response = yield call(addChildSchedule, request)
    yield put(addChildScheduleSuccess(response.Table))
    toast.success("Child schedule added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addChildScheduleFail(error))
    //toast.error(error.data, { autoClose: 2000 })
  }
}

function* fetchChildAbsentTypes() {
  try {
    const response = yield call(getChildAbsentTypes)
    yield put(getChildAbsentTypesSuccess(response))
  } catch (error) {
    yield put(getChildAbsentTypesFail(error))
  }
}

function* onResetChildSchedules() {
  try {
    yield put(resetChildScheduleSuccess({}))
  } catch (error) {}
}

function* onDeleteChildSchedule({ payload: childSchedules }) {
  try {
    const response = yield call(deleteChildSchedule, childSchedules)
    yield put(deleteChildScheduleSuccess(response.Table))
    toast.success("Child schedule deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteChildScheduleFail(error))
    toast.error("Child schedule deletion failed", { autoClose: 2000 })
  }
}

function* childScheduleSaga() {
  yield takeEvery(GET_CHILD_SCHEDULES, fetchChildSchedules)
  yield takeEvery(RESET_CHILD_SCHEDULES, onResetChildSchedules)
  yield takeEvery(ADD_CHILD_SCHEDULES, onAddChildSchedule)
  yield takeEvery(GET_CHILD_ABSENT_TYPES, fetchChildAbsentTypes)
  yield takeEvery(DELETE_CHILD_SCHEDULE, onDeleteChildSchedule)
}

export default childScheduleSaga
