import {
    GET_RESOURCES_FAIL,
    GET_RESOURCES_SUCCESS,
    DELETE_RESOURCE_SUCCESS,
    DELETE_RESOURCE_FAIL,
    ADD_RESOURCE_SUCCESS,
    ADD_RESOURCE_FAIL,
    GET_RESOURCE_DETAIL_SUCCESS,
    GET_RESOURCE_DETAIL_FAIL,
    UPDATE_RESOURCE_SUCCESS,
    UPDATE_RESOURCE_FAIL,
    RESET_RESOURCE,
    RESET_RESOURCE_SUCCESS
  } from "./actionTypes"
  
  const INIT_STATE = {
    resources: [],
    resource: {},
    column: [],
    error: {},
    loading: true,
  }
  
  const Resource = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_RESOURCES_SUCCESS:
        return {
          ...state,
          resources: action.payload,
          loading: true,
        }
      case GET_RESOURCES_FAIL:
        return {
          ...state,
          error: action.payload,
        }
        case DELETE_RESOURCE_SUCCESS: {
          return {
            ...state,
            resources: state.resources.filter(
              resource => resource.ResourceID !== parseInt(action.payload)
            ),
            loading: true,
          }
        }
        case DELETE_RESOURCE_FAIL:
          return {
            ...state,
            error: action.payload,
          }
      case ADD_RESOURCE_SUCCESS:
        return {
          ...state,
          resources: [...state.resources, action.payload],
        }
      case ADD_RESOURCE_FAIL:
        return {
          ...state,
          error: action.payload,
        }
   
   
      case GET_RESOURCE_DETAIL_SUCCESS:
        return {
          ...state,
          resource: action.payload,
          loading: true,
        }
  
      case GET_RESOURCE_DETAIL_FAIL:
        return {
          ...state,
          error: action.payload,
        }
      case UPDATE_RESOURCE_SUCCESS:
        return {
          ...state,
          resourcs: state.resources.map(resource =>
            resource.resourceD.toString() === action.payload.ResourceID.toString()
              ? { resource, ...action.payload }
              : resource
          ),
        }
      case UPDATE_RESOURCE_FAIL:
        return {
          ...state,
          error: action.payload,
        }
        case RESET_RESOURCE:
          return {
            ...state,
            resource: {},
          }
        case RESET_RESOURCE_SUCCESS:
          return {
            ...state,
            resource: action.payload,
          }
      default:
        return state
    }
  }
  
  export default Resource
  