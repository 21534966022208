import React, { useEffect, useState } from "react"

//navigation
import { Link, useNavigate, useParams } from "react-router-dom"

import {
  Button,
  Card,
  CardBody,
  CardText,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Alert,
} from "reactstrap"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"

import {
  StaffScheduling,
  BusScheduling,
  ClassScheduling,
  MealScheduling,
  ChildScheduling,
  ActivityScheduling,
  DoctorScheduling,
} from "./pages"

function Scheduling() {
  const navigate = useNavigate()
  const routeParams = useParams()
  const [header, setHeader] = useState("")

  useEffect(() => {
    const { id } = routeParams

    if (id === "staff") {
      setHeader("Staff Scheduling")
    }
    if (id === "child") {
      setHeader("Child Scheduling")
    }
    if (id === "class") {
      setHeader("Class Scheduling")
    }
    if (id === "meal") {
      setHeader("Meal Scheduling")
    }
    if (id === "bus") {
      setHeader("Bus Scheduling")
    }
    if (id === "activities") {
      setHeader("Activity planner")
    }
    if (id === "doctor") {
      setHeader("Televisit Scheduling")
    }
  }, [routeParams])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Scheduling" breadcrumbItem={header} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {routeParams.id === "staff" ? <StaffScheduling /> : ""}
                  {routeParams.id === "child" ? <ChildScheduling /> : ""}
                  {routeParams.id === "class" ? <ClassScheduling /> : ""}
                  {routeParams.id === "meal" ? <MealScheduling /> : ""}
                  {routeParams.id === "bus" ? <BusScheduling /> : ""}
                  {routeParams.id === "doctor" ? <DoctorScheduling /> : ""}
                  {routeParams.id === "activities" ? (
                    <ActivityScheduling />
                  ) : (
                    ""
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Scheduling
