import {
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAIL,
  GET_STATES,
  GET_STATES_SUCCESS,
  GET_STATES_FAIL,
  GET_CITIES,
  GET_CITIES_SUCCESS,
  GET_CITIES_FAIL,
  GET_MEAL_TYPES,
  GET_MEAL_TYPES_SUCCESS,
  GET_MEAL_TYPES_FAIL,
  GET_AVAILABLE_TYPES,
  GET_AVAILABLE_TYPES_SUCCESS,
  GET_AVAILABLE_TYPES_FAIL,
  GET_SERVICES,
  GET_SERVICES_SUCCESS,
  GET_SERVICES_FAIL,
  GET_SERVICE_CHARGES,
  GET_SERVICE_CHARGES_SUCCESS,
  GET_SERVICE_CHARGES_FAIL,
  GET_AVAILABILITIES,
  GET_AVAILABILITIES_SUCCESS,
  GET_AVAILABILITIES_FAIL,
  GET_SPECIALIZATIONS,
  GET_SPECIALIZATIONS_SUCCESS,
  GET_SPECIALIZATIONS_FAIL,
  GET_MEETING_START_URL,
  GET_MEETING_START_URL_SUCCESS,
  GET_MEETING_START_URL_FAIL,
} from "./actionTypes"

export const getCountries = () => ({
  type: GET_COUNTRIES,
})

export const getCountriesSuccess = countries => ({
  type: GET_COUNTRIES_SUCCESS,
  payload: countries,
})

export const getCountriesFail = error => ({
  type: GET_COUNTRIES_FAIL,
  payload: error,
})

export const getStates = countryID => ({
  type: GET_STATES,
  payload: countryID,
})

export const getStatesSuccess = states => ({
  type: GET_STATES_SUCCESS,
  payload: states,
})

export const getStatesFail = error => ({
  type: GET_STATES_FAIL,
  payload: error,
})

export const getCities = stateID => ({
  type: GET_CITIES,
  payload: stateID,
})

export const getCitiesSuccess = cities => ({
  type: GET_CITIES_SUCCESS,
  payload: cities,
})

export const getCitiesFail = error => ({
  type: GET_CITIES_FAIL,
  payload: error,
})

export const getMealTypes = () => ({
  type: GET_MEAL_TYPES,
})

export const getMealTypesSuccess = mealtypes => ({
  type: GET_MEAL_TYPES_SUCCESS,
  payload: mealtypes,
})

export const getMealTypesFail = error => ({
  type: GET_MEAL_TYPES_FAIL,
  payload: error,
})

export const getAvailableTypes = () => ({
  type: GET_AVAILABLE_TYPES,
})

export const getAvailableTypesSuccess = mealtypes => ({
  type: GET_AVAILABLE_TYPES_SUCCESS,
  payload: mealtypes,
})

export const getAvailableTypesFail = error => ({
  type: GET_AVAILABLE_TYPES_FAIL,
  payload: error,
})

export const getServices = () => ({
  type: GET_SERVICES,
})

export const getServicesSuccess = services => ({
  type: GET_SERVICES_SUCCESS,
  payload: services,
})

export const getServicesFail = error => ({
  type: GET_SERVICES_FAIL,
  payload: error,
})

export const getServiceCharges = () => ({
  type: GET_SERVICE_CHARGES,
})

export const getServiceChargesSuccess = servicecharges => ({
  type: GET_SERVICE_CHARGES_SUCCESS,
  payload: servicecharges,
})

export const getServiceChargesFail = error => ({
  type: GET_SERVICE_CHARGES_FAIL,
  payload: error,
})

export const getAvailabilities = () => ({
  type: GET_AVAILABILITIES,
})

export const getAvailabilitiesSuccess = availabilities => ({
  type: GET_AVAILABILITIES_SUCCESS,
  payload: availabilities,
})

export const getAvailabilitiesFail = error => ({
  type: GET_AVAILABILITIES_FAIL,
  payload: error,
})

export const getSpecializations = () => ({
  type: GET_SPECIALIZATIONS,
})

export const getSpecializationsSuccess = specializations => ({
  type: GET_SPECIALIZATIONS_SUCCESS,
  payload: specializations,
})

export const getSpecializationsFail = error => ({
  type: GET_SPECIALIZATIONS_FAIL,
  payload: error,
})

export const getMeetingStartURL = meetingKey => ({
  type: GET_MEETING_START_URL,
  payload: meetingKey,
})

export const getMeetingStartURLSuccess = startURL => ({
  type: GET_MEETING_START_URL_SUCCESS,
  payload: startURL,
})

export const getMeetingStartURLFail = error => ({
  type: GET_MEETING_START_URL_FAIL,
  payload: error,
})
