import React, { useEffect } from "react"
import { Button, Spinner } from "reactstrap"

const ButtonLoader = () => {
  return (
    <React.Fragment>
      <Button color="primary" disabled>
        <Spinner size="sm">Loading...</Spinner>
      </Button>
    </React.Fragment>
  )
}

export default ButtonLoader
