import React, { useEffect, useMemo, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "components/Common/TableContainer"

//import components
import DeleteModal from "components/Common/DeleteModal"

import {
  getResources as onGetResources,
  deleteResource as onDeleteResource,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Moment from "moment"

import {
  Badge,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"
import Spinners from "components/Common/Spinner"
// import { ToastContainer } from "react-toastify"
import ResourceModal from "./Modal/ResourceModal"
import { downloadResource } from "helpers/backend_helper"

function Resource(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [filter, setFilter] = useState({
    objectRowID: props?.objectRowID,
    resourceTypeID: props?.resourceTypeID,
    onView: props?.onView,
  })
  const { resources, loading } = useSelector(state => state.resource)

  const [isLoading, setLoading] = useState(loading)
  const [deleteModal, setDeleteModal] = useState(false)
  const [resourceModal, setResourceModal] = useState(false)
  const [resourceData, setResource] = useState(null)

  useEffect(() => {
    dispatch(onGetResources(filter))
  }, [dispatch, resources])

  const handleAddClick = () => {
    setResourceModal(true)
  }

  const handleEditClick = arg => {
    setResource(arg)
    setResourceModal(true)
  }

  const onClickDelete = arg => {
    setResource(arg)
    setDeleteModal(true)
  }

  const handleDelete = () => {
    if (resourceData && resourceData.id) {
      dispatch(onDeleteResource(resourceData.id))
      setResource(null)
      setDeleteModal(false)
    }
  }

  const onCloseModal = () => {
    setResource(null)
    setResourceModal(false)
  }

  const downloadFile = async obj => {
    try {
      const response = await downloadResource(obj.RowID)
      // Create a new Blob object using the response data
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      })
      // Create a link element, set its href to the blob URL, and trigger a download
      const link = document.createElement("a")
      link.href = URL.createObjectURL(blob)
      link.download = obj.FileName
      document.body.appendChild(link)
      link.click()
      // Clean up and remove the link
      document.body.removeChild(link)
    } catch (error) {
      console.error("Error downloading the file", error)
    }
  }

  // const ResourceStatus = ({ status }) => {
  //   switch (status) {
  //     case true:
  //       return <Badge className="bg-success"> Active </Badge>
  //     case false:
  //       return <Badge className="bg-danger"> Inactive </Badge>
  //     default:
  //       return ""
  //   }
  // }

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: "File Name",
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link
                  className="text-dark"
                  to="#"
                  onClick={() => {
                    downloadFile(cellProps.row.original)
                  }}
                >
                  {cellProps.row.original.FileName}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Format",
        accessor: "Type",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Uploaded",
        accessor: "CreatedDate",
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {Moment(cellProps.row.original.CreatedDate).format(
                    "DD MMM yyyy"
                  )}
                </Link>
              </h5>
              <p className="text-muted mb-0">
                {cellProps.row.original.CreatedByName}
              </p>
            </>
          )
        },
      },
    ]

    // Conditionally add the "Action" column
    if (filter?.onView) {
      baseColumns.push({
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="bx bx-trash font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      })
    }

    return baseColumns
  }, [filter?.onView])

  return (
    <>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      {resourceModal && (
        <ResourceModal
          show={resourceModal}
          onCloseClick={() => onCloseModal()}
          objectRowID={props.objectRowID}
          resourceTypeID={props.resourceTypeID}
          data={resourceData}
        />
      )}

      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <h4 hidden={filter?.onView} className="mb-sm-0 font-size-18">
                  Resources
                </h4>
                <TableContainer
                  columns={columns}
                  data={resources}
                  isAddOption={filter?.onView}
                  addOptionText="Upload Resource"
                  handleAddClick={handleAddClick}
                  customPageSize={10}
                  isPagination={true}
                  tableClass="align-middle table-nowrap table-check"
                  theadClass="table-light"
                  pagination="pagination pagination-rounded mb-0 ms-auto"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {/* <ToastContainer /> */}
    </>
  )
}

export default Resource
