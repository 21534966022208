import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Alert,
  Badge,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import * as moment from "moment"

import {
  updateEnrollment as onUpdateEnrollment,
  getEnrollmentDetail as onGetEnrollmentDetail,
} from "store/actions"
import { EnumEnrollmentTypes, EnumServiceTypes } from "helpers/enum_helper"
import AlertContainer from "components/Common/AlertContainer"
import { getHoursMinutes } from "helpers/duration_helper"

const EnrollmentApprovalModal = ({
  show,
  programEnrollmentID,
  onCloseClick,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (show) {
      dispatch(onGetEnrollmentDetail(programEnrollmentID))
    }
  }, [dispatch, show])

  //////////////////PROGRAM STATE/////////////////////
  const { enrollment } = useSelector(state => state.program)
  //////////////////////////////////////

  const onSaveClick = e => {
    let request = {
      ProgramEnrollmentID: enrollment.ProgramEnrollmentID,
      EnrollmentTypeID: e,
    }

    dispatch(onUpdateEnrollment(request))
    onCloseClick()
  }

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalHeader className="d-flex justify-content-center">
          Program Enrollment
        </ModalHeader>
        <ModalBody>
          {enrollment && (
            <>
              <p className="text-center">
                Requested by {enrollment?.CreatedByName} on{" "}
                {moment(enrollment?.CreatedDate).format("DD MMM Y, hh:mm A")}
              </p>
              <div>
                Date from :{" "}
                <b>
                  {moment(enrollment?.StartDate).format("DD MMM Y")}
                  {" - "}
                  {moment(enrollment?.EndDate).format("DD MMM Y")}
                </b>
              </div>
              <p>
                Assigned class : <b>{enrollment?.Class}</b>
              </p>
              <h4>
                {enrollment?.Program +
                  ", " +
                  enrollment?.DayID +
                  (enrollment?.DayID > 1 ? " days" : " day")}
                <Badge className="bg-success ms-1">
                  {enrollment?.BillingCycle}
                </Badge>
                <div>{`Price - $` + enrollment?.Price?.toFixed(2)}</div>
              </h4>
              {enrollment?.EnrollmentTimes && (
                <AlertContainer
                  message={`You've only selected ${enrollment?.EnrollmentTimes?.length} days for an ${enrollment?.DayID} day program`}
                  color="warning"
                />
              )}
              <div>
                {enrollment?.EnrollmentCharges ? (
                  <Table
                    style={{ backgroundColor: "#ebeeff", fontSize: "15px" }}
                  >
                    {enrollment?.EnrollmentCharges?.map((item, index) => {
                      return (
                        <tr key={"Charge" + index}>
                          <td>{item.Name}</td>
                          <td style={{ fontSize: "x-small" }}>
                            {item.DayName?.substring(0, 3)}
                          </td>
                          <td style={{ fontSize: "small" }}>
                            {item.TotalHours > 0 && (
                              <>
                                {`$${item.Rate.toFixed(2)} x ` +
                                  (item.ChargeTypeID === 1 ||
                                  item.ChargeTypeID === 3 ||
                                  item.ChargeTypeID === 5
                                    ? item.TotalHours
                                    : getHoursMinutes(item.TotalHours * 60))}
                              </>
                            )}
                          </td>
                          <td width={20}>
                            {item.ChargeTypeID === 6 ? "-" : ""}
                          </td>
                          <td width={50}>
                            {`$${item.TotalCharge?.toFixed(2).replace(
                              "-",
                              ""
                            )}`}
                          </td>
                        </tr>
                      )
                    })}
                  </Table>
                ) : (
                  ""
                )}
              </div>
              <div className="table-responsive">
                {enrollment?.EnrollmentTimes ? (
                  <Table className="table align-middle table-nowrap">
                    <thead>
                      <tr>
                        <th scope="col">Day</th>
                        <th scope="col">Start Time</th>
                        <th scope="col">End Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {enrollment?.EnrollmentTimes?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">{item.Day}</th>
                            <td>{moment(item.StartTime).format("hh:mm A")}</td>
                            <td>{moment(item.EndTime).format("hh:mm A")}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                ) : (
                  ""
                )}
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="btn btn-outline-dark"
            onClick={onCloseClick}
          >
            Close
          </Button>
          {enrollment?.EnrollmentTypeID === EnumEnrollmentTypes.Requested ? (
            <>
              <Button
                type="button"
                color="btn btn-outline-danger"
                onClick={() => onSaveClick(0)}
              >
                Decline
              </Button>
              <Button
                type="button"
                color="btn btn-outline-warning"
                onClick={() => onSaveClick(2)}
              >
                Waitlist
              </Button>
              <Button
                type="button"
                color="btn btn-outline-primary"
                onClick={() => onSaveClick(3)}
              >
                Approve
              </Button>
            </>
          ) : (
            ""
          )}
        </ModalFooter>
      </div>
    </Modal>
  )
}

EnrollmentApprovalModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default EnrollmentApprovalModal
