import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Doctor Redux States
import {
  GET_DOCTOR_SCHEDULES,
  ADD_DOCTOR_SCHEDULES,
  DELETE_DOCTOR_SCHEDULE,
  DOCTOR_MEETING_SYNC,
} from "./actionTypes"
import {
  getDoctorSchedulesSuccess,
  getDoctorSchedulesFail,
  addDoctorScheduleSuccess,
  addDoctorScheduleFail,
  deleteDoctorScheduleSuccess,
  deleteDoctorScheduleFail,
  doctorMeetingSyncSuccess,
  doctorMeetingSyncFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDoctorSchedules,
  addDoctorSchedule,
  deleteDoctorSchedule,
  getDoctorMeetingSync,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchDoctorSchedules({ filter }) {
  try {
    console.log(filter)
    const response = yield call(getDoctorSchedules, filter)
    yield put(getDoctorSchedulesSuccess(response.Table))
  } catch (error) {
    yield put(getDoctorSchedulesFail(error))
  }
}

function* onAddDoctorSchedule({ payload: doctorSchedule }) {
  try {
    const response = yield call(addDoctorSchedule, doctorSchedule)
    yield put(addDoctorScheduleSuccess(response.Table))
    toast.success("Doctor schedule added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addDoctorScheduleFail(error))
  }
}

function* onDeleteDoctorSchedule({ payload: doctorSchedule }) {
  try {
    const response = yield call(deleteDoctorSchedule, doctorSchedule)
    yield put(deleteDoctorScheduleSuccess(response.Table))
    toast.success("Doctor schedule deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteDoctorScheduleFail(error))
    toast.error("Doctor schedule deletion failed", { autoClose: 2000 })
  }
}

function* fetchDoctorMeetingSync({ payload: doctorScheduleID }) {
  try {
    const response = yield call(getDoctorMeetingSync, doctorScheduleID)
    yield put(doctorMeetingSyncSuccess(response))
  } catch (error) {
    yield put(doctorMeetingSyncFail(error))
  }
}

function* doctorScheduleSaga() {
  yield takeEvery(GET_DOCTOR_SCHEDULES, fetchDoctorSchedules)
  yield takeEvery(ADD_DOCTOR_SCHEDULES, onAddDoctorSchedule)
  yield takeEvery(DELETE_DOCTOR_SCHEDULE, onDeleteDoctorSchedule)
  yield takeEvery(DOCTOR_MEETING_SYNC, fetchDoctorMeetingSync)
}

export default doctorScheduleSaga
