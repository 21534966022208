import { call, put, takeEvery } from "redux-saga/effects"

// curriculum Redux States
import {
  GET_CURRICULUMS,
  DELETE_CURRICULUM,
  ADD_CURRICULUM,
  GET_CURRICULUM_DETAIL,
  UPDATE_CURRICULUM,
  GET_CURRICULUMCATEGORIES,
  RESET_CURRICULUM,
  ADD_CURRICULUM_SCHEDULE,
  UPDATE_CURRICULUM_SCHEDULE,
  GET_CURRICULUM_SCHEDULES_BY_CURRICULUM,
  UPDATE_CURRICULUM_SCHEDULE_DETAIL,
  GET_CURRICULUM_SCHEDULE_DETAIL_BYNSD,
  DELETE_CURRICULUM_SCHEDULE,
  DELETE_CURRICULUM_SCHEDULE_DETAIL,
  GET_CURRICULUMS_VIEW,
} from "./actionTypes"
import {
  getCurriculumsSuccess,
  getCurriculumsFail,
  deleteCurriculumSuccess,
  deleteCurriculumFail,
  addCurriculumSuccess,
  addCurriculumFail,
  getCurriculumDetailSuccess,
  getCurriculumDetailFail,
  updateCurriculumSuccess,
  updateCurriculumFail,
  resetCurriculumSuccess,
  getCurriculumCategoriesSuccess,
  getCurriculumCategoriesFail,
  addCurriculumScheduleSuccess,
  addCurriculumScheduleFail,
  updateCurriculumScheduleSuccess,
  updateCurriculumScheduleFail,
  getCurriculumSchedulesByCurriculumSuccess,
  getCurriculumSchedulesByCurriculumFail,
  updateCurriculumScheduleDetailSuccess,
  updateCurriculumScheduleDetailFail,
  deleteCurriculumScheduleSuccess,
  deleteCurriculumScheduleFail,
  deleteCurriculumScheduleDetailSuccess,
  deleteCurriculumScheduleDetailFail,
  getCurriculumsViewSuccess,
  getCurriculumsViewFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCurriculums,
  deleteCurriculum,
  addNewCurriculum,
  getCurriculumDetail,
  updateCurriculum,
  addCurriculumSchedule,
  deleteCurriculumSchedule,
  getCurriculumSchedulesByCurriculum,
  updateCurriculumScheduleDetail,
  deleteCurriculumScheduleDetail,
  getCurriculumsView,
  updateCurriculumSchedule,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchCurriculums() {
  try {
    const response = yield call(getCurriculums)
    //console.log(response)
    yield put(getCurriculumsSuccess(response))
  } catch (error) {
    yield put(getCurriculumsFail(error))
  }
}

function* onDeleteCurriculum({ payload: CurriculumID }) {
  try {
    const response = yield call(deleteCurriculum, CurriculumID)
    yield put(deleteCurriculumSuccess(response))
    toast.success("Curriculum deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteCurriculumFail(error))
    toast.error("Curriculum deletion failed", { autoClose: 2000 })
  }
}

function* onAddCurriculum({ payload: curriculum }) {
  try {
    var formData = new FormData()
    formData.append("FileName", curriculum?.File)
    formData.append("CurriculumData", JSON.stringify({...curriculum, id:0}))
    // const curriculumNew = {
    //   ...curriculum,
    //   ClassID: curriculum.Classes?.ClassID ? curriculum.Classes?.ClassID : 0,
    // }
    const response = yield call(addNewCurriculum, formData)
    yield put(addCurriculumSuccess(response[0]))
    toast.success("Curriculum added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addCurriculumFail(error))
    toast.error("Curriculum addition failed", { autoClose: 2000 })
  }
}

function* fetchCurriculumDetail({ RowID }) {
  try {
    const response = yield call(getCurriculumDetail, RowID)
    let result = response[0]
    result = {
      ...result,
    }
    yield put(getCurriculumDetailSuccess(result))
  } catch (error) {
    yield put(getCurriculumDetailFail(error))
  }
}

function* onUpdateCurriculum({ payload: curriculum }) {
  try {
    const curriculumNew = {
      ...curriculum,
      ClassID: curriculum.Classes?.ClassID ? curriculum.Classes?.ClassID : 0,
    }
    const response = yield call(updateCurriculum, curriculumNew)
    let result = response[0]

    yield put(updateCurriculumSuccess(result))
    toast.success("Curriculum updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateCurriculumFail(error))
    toast.error("Curriculum updation failed", { autoClose: 2000 })
  }
}

function* onResetCurriculum() {
  try {
    yield put(resetCurriculumSuccess({}))
  } catch (error) {}
}

function* onAddCurriculumSchedule({
  payload: { curriculumschedule, curriculumRowId },
}) {
  try {
    const response = yield call(addCurriculumSchedule, {
      curriculumschedule,
      curriculumRowId,
    })

    yield put(addCurriculumScheduleSuccess(response))
  } catch (error) {
    yield put(addCurriculumScheduleFail(error))
  }
}

function* onUpdateCurriculumSchedule({ payload: curriculumschedule }) {
  try {
    const response = yield call(updateCurriculumSchedule, curriculumschedule)
    yield put(addCurriculumScheduleSuccess(response))
  } catch (error) {
    yield put(addCurriculumScheduleFail(error))
  }
}

function* fetchCurriculumSchedulesByCurriculum({ RowID }) {
  try {
    const response = yield call(getCurriculumSchedulesByCurriculum, RowID)
    yield put(getCurriculumSchedulesByCurriculumSuccess(response))
  } catch (error) {
    yield put(getCurriculumSchedulesByCurriculumFail(error))
  }
}
function* onUpdateCurriculumScheduleDetail({
  payload: curriculumScheduleDetail,
}) {
  try {
    const response = yield call(
      updateCurriculumScheduleDetail,
      curriculumScheduleDetail
    )
    yield put(updateCurriculumScheduleDetailSuccess(response))
    toast.success("Curriculum Schedule detail updated successfully", {
      autoClose: 2000,
    })
  } catch (error) {
    yield put(updateCurriculumScheduleDetailFail(error))
    toast.error("Curriculum Schedule detail updation failed", {
      autoClose: 2000,
    })
  }
}

function* onDeleteCurriculumSchedule({ payload: CurriculumScheduleID }) {
  try {
    const response = yield call(deleteCurriculumSchedule, CurriculumScheduleID)
    yield put(deleteCurriculumScheduleSuccess(response))
    toast.success("Curriculum schedule deleted successfully", {
      autoClose: 2000,
    })
  } catch (error) {
    yield put(deleteCurriculumScheduleFail(error))
    toast.error("Curriculum schedule deletion failed", { autoClose: 2000 })
  }
}

function* onDeleteCurriculumScheduleDetail({
  payload: CurriculumScheduleDetailID,
}) {
  try {
    const response = yield call(
      deleteCurriculumScheduleDetail,
      CurriculumScheduleDetailID
    )
    yield put(deleteCurriculumScheduleDetailSuccess(response))
    toast.success("Curriculum schedule detail deleted successfully", {
      autoClose: 2000,
    })
  } catch (error) {
    yield put(deleteCurriculumScheduleDetailFail(error))
    toast.error("Curriculum schedule detail deletion failed", {
      autoClose: 2000,
    })
  }
}

function* fetchCurriculumsView({ payload: curriculum }) {
  try {
    const response = yield call(getCurriculumsView, curriculum)
    yield put(getCurriculumsViewSuccess(response))
  } catch (error) {
    yield put(getCurriculumsViewFail(error))
  }
}

function* curriculumSaga() {
  yield takeEvery(GET_CURRICULUMS, fetchCurriculums)
  yield takeEvery(DELETE_CURRICULUM, onDeleteCurriculum)
  yield takeEvery(ADD_CURRICULUM, onAddCurriculum)
  yield takeEvery(GET_CURRICULUM_DETAIL, fetchCurriculumDetail)
  yield takeEvery(UPDATE_CURRICULUM, onUpdateCurriculum)
  yield takeEvery(RESET_CURRICULUM, onResetCurriculum)
  yield takeEvery(ADD_CURRICULUM_SCHEDULE, onAddCurriculumSchedule)
  yield takeEvery(UPDATE_CURRICULUM_SCHEDULE, onUpdateCurriculumSchedule)
  yield takeEvery(
    GET_CURRICULUM_SCHEDULES_BY_CURRICULUM,
    fetchCurriculumSchedulesByCurriculum
  )
  yield takeEvery(
    UPDATE_CURRICULUM_SCHEDULE_DETAIL,
    onUpdateCurriculumScheduleDetail
  )
  yield takeEvery(DELETE_CURRICULUM_SCHEDULE, onDeleteCurriculumSchedule)
  yield takeEvery(
    DELETE_CURRICULUM_SCHEDULE_DETAIL,
    onDeleteCurriculumScheduleDetail
  )
  yield takeEvery(GET_CURRICULUMS_VIEW, fetchCurriculumsView)
}

export default curriculumSaga
