import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { Input, Label, FormFeedback } from "reactstrap"
import Select from "react-select"

const DropdownInput = ({
  label,
  id,
  items,
  itemlabel,
  itemvalue,
  required = false,
  onChange = null,
}) => {
  const methods = useFormContext()
  const { control, formState, setValue } = methods
  const { errors } = formState

  const handleChange = e => {
    setValue(id, e)
    if (onChange) {
      onChange(e)
    }
  }

  return (
    <React.Fragment>
      <Label>{label}</Label>
      <Controller
        name={id}
        control={control}
        render={({ field }) => (
          <>
            <Select
              {...field}
              id={id}
              options={items}
              getOptionLabel={option => option[itemlabel]}
              getOptionValue={option => option[itemvalue]}
              required={required}
              aria-invalid={!!errors[id]}
              classNamePrefix="select2-selection"
              onChange={handleChange}
            />
            {errors[id]?.message ? (
              <FormFeedback type="invalid" className="d-block">
                {errors[id]?.message}
              </FormFeedback>
            ) : null}
          </>
        )}
      />
    </React.Fragment>
  )
}

DropdownInput.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
  items: PropTypes.array,
  itemlabel: PropTypes.string,
  itemvalue: PropTypes.string,
  onChange: PropTypes.func,
}

export default DropdownInput
