import PropTypes, { object } from "prop-types"
import React, { useEffect } from "react"
//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Modal,
  ModalBody,
  InputGroup,
  FormFeedback,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import Flatpickr from "react-flatpickr"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"

import {
  getMeals as onGetMeals,
  addMealSchedule as onAddMealSchedule,
  getChildren as onGetChildren,
} from "store/actions"
import AssignMultiple from "components/Common/AssignMultipleDates"

const AssignModal = ({ show, onCloseClick }) => {
  const dispatch = useDispatch()

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    ScheduleDate: yup.string().required("Required"),
    Meals: yup
      .object()
      .required("Required")
      .transform((_, val) => (val ? val : null)),
    Children: yup
      .object()
      .required("Required")
      .transform((_, val) => (val ? val : null)),
    MealOptions: yup.string(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      ScheduleDate: "",
      Meals: "",
      MealOptions: "",
      Children: "",
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  useDeepCompareEffect(() => {
    dispatch(onGetChildren())
    function updateState() {
      dispatch(onGetMeals())
    }

    updateState()
  }, [dispatch])

  //////////////////Meal SCHEDULE STATE/////////////////////
  const { meals } = useSelector(state => state.meal)
  //////////////////////////////////////

  //////////////////CHILDREN STATE/////////////////////
  const { children } = useSelector(state => state.child)
  //////////////////////////////////////

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      dispatch(onAddMealSchedule(getValues()))
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset()
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      onClosed={() => onClosed()}
      centered={true}
    >
      <ModalHeader className="d-flex justify-content-center">
        Assign Meal
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <FormProvider {...methods}>
          <Row>
            <Col lg={12}>
              <div className="mb-3">
                <Label>Schedule Date</Label>
                <Controller
                  name="ScheduleDate"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Flatpickr
                        {...field}
                        className="form-control d-block"
                        id="ScheduleDate"
                        options={{
                          dateFormat: "d M, Y",
                        }}
                        onChange={(selectedDates, dateStr, instance) => {
                          setValue("ScheduleDate", dateStr)
                        }}
                        required
                      />
                      {errors?.ScheduleDate?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.ScheduleDate?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="mb-3">
                <Label>Meal</Label>
                <Controller
                  name="Meals"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        id="Meals"
                        options={meals.filter(meal => meal.IsActive == 1)}
                        getOptionLabel={option => option.Title}
                        getOptionValue={option => option.MealID}
                        required
                        aria-invalid={!!errors.Meals}
                        className="select2-selection"
                      />
                      {errors?.Meals?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.Meals?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
            </Col>

            <Col lg={12}>
              <div className="mb-3">
                <Label>Child</Label>
                <Controller
                  name="Children"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        id="Children"
                        options={children.filter(child => child.IsActive == 1)}
                        getOptionLabel={option => option.Name}
                        getOptionValue={option => option.ChildID}
                        required
                        aria-invalid={!!errors.Children}
                        className="select2-selection"
                      />
                      {errors?.Children?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.Children?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="mb-3">
                <Label>Description</Label>
                <Controller
                  name="MealOptions"
                  control={control}
                  render={({ field }) => (
                    <>
                      <textarea
                        {...field}
                        className="form-control d-block"
                        id="MealOptions"
                      />
                    </>
                  )}
                />
              </div>
            </Col>
          </Row>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <button
            type="button"
            className="btn btn btn-primary"
            onClick={() => onSaveClick()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn btn-secondary"
            onClick={onCloseClick}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

AssignModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
}

export default AssignModal
