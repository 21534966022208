import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"
import PayrateModal from "../modal/PayrateModal"
import {
  getPayrates as onGetPayrates,
  deletePayrate as onDeletePayrate,
} from "store/actions"
import * as moment from "moment"
import { useDeepCompareEffect } from "hooks"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Badge,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
} from "reactstrap"

import Spinners from "components/Common/Spinner"

function PayrateTab() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { payrates, staff, loading } = useSelector(state => state.staff)

  const [isLoading, setLoading] = useState(loading)
  const [deleteModal, setDeleteModal] = useState(false)
  const [payrate, setPayrate] = useState(null)
  const [payrateTitle, setPayrateTitle] = useState("Add Payrate")

  const [payrateModal, setPayrateModal] = useState(false)
  useDeepCompareEffect(() => {
    dispatch(onGetPayrates(staff.StaffID))
  }, [dispatch, payrates])

  useEffect(() => {}, [dispatch])
  const handleAddClick = () => {
    setPayrate(null)
    setPayrateTitle("Add Payrate")
    setPayrateModal(true)
  }

  const onClickDelete = arg => {
    setPayrate(arg)
    setDeleteModal(true)
  }

  const handleDelete = () => {
    if (payrate && payrate.PayRateID) {
      dispatch(onDeletePayrate(payrate.PayRateID))
      setDeleteModal(false)
    }
  }
  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }
  const onClickEdit = arg => {
    setPayrate(arg)
    setPayrateTitle("Edit Payrate")
    setPayrateModal(true)
  }
  const onPayrateCloseModal = () => {
    setPayrate(null)
    setPayrateModal(false)
  }
  const PayrateStatus = ({ status }) => {
    switch (status) {
      case true:
        return <Badge className="bg-success"> Active </Badge>
      case false:
        return <Badge className="bg-danger"> Inactive </Badge>
      default:
        return ""
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Employment",
        accessor: "EmploymentType",
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.EmploymentType}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Classification",
        accessor: "ClassificationType",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Pay rate",
        accessor: "Amount",
        Cell: cellProps => {
          return (
            <>
              {cellProps.value ? `$${cellProps.value.toFixed("2")}` : ""}
              <p className="text-muted mb-0">
                {cellProps.row.original.PayRateStartDate}
              </p>
            </>
          )
        },
      },

      {
        Header: "Status",
        accessor: "IsActive",
        Cell: cellProps => {
          return <PayrateStatus status={cellProps.value} />
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-secondary"
                onClick={() => {
                  onClickEdit(cellProps.row.original)
                }}
              >
                <i className="bx bx-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="bx bx-trash font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )
  return (
    <React.Fragment>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      {payrateModal && (
        <PayrateModal
          payrateTitle={payrateTitle}
          show={payrateModal}
          payrate={payrate}
          onPayrateCloseClick={() => onPayrateCloseModal()}
        />
      )}
      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <Row>
          <Col xs="12">
            <TableContainer
              columns={columns}
              data={payrates}
              isGlobalFilter={true}
              isAddOption={true}
              addOptionText="Add payrate"
              handleAddClick={handleAddClick}
              customPageSize={10}
              isPagination={true}
              filterable={false}
              iscustomPageSizeOptions={true}
              tableClass="align-middle table-nowrap table-check"
              theadClass="table-light"
              pagination="pagination pagination-rounded mb-0 ms-auto"
            />
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}
PayrateTab.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default PayrateTab
