import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_THEME_IMAGE,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
  SHOW_SIDEBAR,
  CHANGE_PRELOADER,
  TOGGLE_LEFTMENU,
  CHANGE_LAYOUT_MODE,
  GET_THEME,
  GET_THEME_SUCCESS,
  GET_THEME_FAIL,
  UPDATE_THEME,
  UPDATE_THEME_SUCCESS,
  UPDATE_THEME_FAIL,

  GET_BASICSETTING,
  GET_BASICSETTING_SUCCESS,
  GET_BASICSETTING_FAIL,
  UPDATE_BASICSETTING,
  UPDATE_BASICSETTING_SUCCESS,
  UPDATE_BASICSETTING_FAIL
} from "./actionTypes"

export const changeLayout = layout => ({
  type: CHANGE_LAYOUT,
  payload: layout,
})

export const changePreloader = layout => ({
  type: CHANGE_PRELOADER,
  payload: layout,
})

export const changeLayoutMode = layoutMode => ({
  type: CHANGE_LAYOUT_MODE,
  payload: layoutMode,
})

export const changeLayoutWidth = width => ({
  type: CHANGE_LAYOUT_WIDTH,
  payload: width,
})

export const changeSidebarTheme = theme => ({
  type: CHANGE_SIDEBAR_THEME,
  payload: theme,
})

export const changeSidebarThemeImage = themeimage => ({
  type: CHANGE_SIDEBAR_THEME_IMAGE,
  payload: themeimage,
})

export const changeSidebarType = (sidebarType, isMobile) => {
  return {
    type: CHANGE_SIDEBAR_TYPE,
    payload: { sidebarType, isMobile },
  }
}

export const changeTopbarTheme = topbarTheme => ({
  type: CHANGE_TOPBAR_THEME,
  payload: topbarTheme,
})

export const showRightSidebarAction = isopen => ({
  type: SHOW_RIGHT_SIDEBAR,
  payload: isopen,
})

export const showSidebar = isopen => ({
  type: SHOW_SIDEBAR,
  payload: isopen,
})

export const toggleLeftmenu = isopen => ({
  type: TOGGLE_LEFTMENU,
  payload: isopen,
})

export const getTheme = () => ({
  type: GET_THEME,
})

export const getThemeSuccess = theme => ({
  type: GET_THEME_SUCCESS,
  payload: theme,
})

export const getThemeFail = error => ({
  type: GET_THEME_FAIL,
  payload: error,
})

export const updateTheme = theme => ({
  type: UPDATE_THEME,
  payload: theme,
})

export const updateThemeSuccess = theme => ({
  type: UPDATE_THEME_SUCCESS,
  payload: theme,
})

export const updateThemeFail = error => ({
  type: UPDATE_THEME_FAIL,
  payload: error,
})



export const getBasicSettings= () => ({
  type: GET_BASICSETTING,
})

export const getBasicSettingsSuccess = basicSetting => ({
  type: GET_BASICSETTING_SUCCESS,
  payload: basicSetting,
})

export const getBasicSettingsFail = error => ({
  type: GET_BASICSETTING_FAIL,
  payload: error,
})

export const updateBasicSettings = basicSettings => ({
  type: UPDATE_BASICSETTING,
  payload: basicSettings,
})

export const updateBasicSettingsSuccess = basicSettings => ({
  type: UPDATE_BASICSETTING_SUCCESS,
  payload: basicSettings,
})

export const updateBasicSettingsFail = error => ({
  type: UPDATE_BASICSETTING_FAIL,
  payload: error,
})