import { isEmpty } from "lodash"
import React, { useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { Link } from "react-router-dom"
import {
  Input,
  Label,
  FormFeedback,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap"

function FileUpload(props) {
  const methods = useFormContext()
  const { control, formState, setValue, getValues } = methods
  const { errors } = formState

  const handleFileChange = event => {
    let currentItems = getValues(props.ControlID)

    if (!isEmpty(event.target.value)) {
      if (currentItems === undefined || currentItems === null) {
        setValue(props.ControlID, [event.target.files[0]])
      } else {
        setValue(props.ControlID, [...currentItems, event.target.files[0]])
      }
    }
  }

  const removeFile = index => {
    const files = [...getValues(props.ControlID)]
    files.splice(index, 1)

    if (files.length === 0) {
      setValue(props.ControlID, null)
    } else {
      setValue(props.ControlID, files)
    }
  }

  return (
    <Row>
      <Col sm="12">
        <div>
          <Label className="form-label">{props.ControlID}</Label>
          <Controller
            name={props.ControlID}
            control={control}
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  className="form-control"
                  type="file"
                  id={props.ControlID}
                  required
                  value={field.value?.name}
                  accept={props.Accept}
                  invalid={!!eval(`errors.${props.ControlID}`)}
                  onChange={handleFileChange}
                />
                {eval(`errors?.${props.ControlID}?.message`) ? (
                  <FormFeedback type="invalid" className="d-block">
                    {eval(`errors?.${props.ControlID}?.message`)}
                  </FormFeedback>
                ) : null}
                {field.value &&
                  field.value.length > 0 &&
                  field.value.map((item, index) => (
                    <div key={index}>
                      <span>{item?.name}</span>
                      <Link
                        to="#"
                        className="text-danger"
                        onClick={() => {
                          removeFile(index)
                        }}
                      >
                        <i
                          className="bx bx-trash font-size-18"
                          id="deletetooltip"
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target="deletetooltip"
                        >
                          Delete
                        </UncontrolledTooltip>
                      </Link>
                    </div>
                  ))}
              </>
            )}
          />
        </div>
      </Col>
    </Row>
  )
}

export default FileUpload
