import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { Input, Label, FormFeedback } from "reactstrap"
import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

const ToggleInput = ({ label, id }) => {
  const methods = useFormContext()
  const { control, formState } = methods

  return (
    <React.Fragment>
      <Label>{label}</Label>
      <Controller
        name={id}
        control={control}
        render={({ field }) => (
          <>
            <Switch
              {...field}
              id={id}
              checked={field.value}
              uncheckedIcon={<Offsymbol />}
              checkedIcon={<OnSymbol />}
              className="ms-2 mb-sm-8 mb-2"
              onColor="#626ed4"
            />
          </>
        )}
      />
    </React.Fragment>
  )
}

ToggleInput.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
}

export default ToggleInput
