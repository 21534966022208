/*Get CHILD SCHEDULES */
export const GET_CHILD_SCHEDULES = "GET_CHILD_SCHEDULES"
export const GET_CHILD_SCHEDULES_SUCCESS = "GET_CHILD_SCHEDULES_SUCCESS"
export const GET_CHILD_SCHEDULES_FAIL = "GET_CHILD_SCHEDULES_FAIL"

/**
 * Reset CHILD SCHEDULES
 */
export const RESET_CHILD_SCHEDULES = "RESET_CHILD"
export const RESET_CHILD_SCHEDULES_SUCCESS = "RESET_CHILD_SCHEDULES_SUCCESS"

/*Add CHILD SCHEDULES */
export const ADD_CHILD_SCHEDULES = "ADD_CHILD_SCHEDULES"
export const ADD_CHILD_SCHEDULES_SUCCESS = "ADD_CHILD_SCHEDULES_SUCCESS"
export const ADD_CHILD_SCHEDULES_FAIL = "ADD_CHILD_SCHEDULES_FAIL"

/*Get ABSENT TYPES*/
export const GET_CHILD_ABSENT_TYPES = "GET_CHILD_ABSENT_TYPES"
export const GET_CHILD_ABSENT_TYPES_SUCCESS = "GET_CHILD_ABSENT_TYPES_SUCCESS"
export const GET_CHILD_ABSENT_TYPES_FAIL = "GET_CHILD_ABSENT_TYPES_FAIL"

/**
 * Delete CHILD SCHEDULE
 */
export const DELETE_CHILD_SCHEDULE = "DELETE_CHILD_SCHEDULE"
export const DELETE_CHILD_SCHEDULE_SUCCESS = "DELETE_CHILD_SCHEDULE_SUCCESS"
export const DELETE_CHILD_SCHEDULE_FAIL = "DELETE_CHILD_SCHEDULE_FAIL"

//Reset CHILD SCHEDULE
export const RESET_CHILD_SCHEDULE = "RESET_CHILD_SCHEDULE"
