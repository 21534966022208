import React, { useState, useEffect } from "react"

//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
  InputGroup,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import Switch from "react-switch"

//react form
//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//navigation
import { Link, useNavigate, useParams } from "react-router-dom"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

import { isEmpty } from "lodash"
//Date filter
import Moment from "moment"
import {
  isValidFileType,
  MAX_FILE_SIZE,
  ValidExtensions,
} from "pages/Utility/constants"
import {
  addNote as onAddNote,
  updateNote as onUpdateNote,
  getClasses as onGetClasses,
  resetNote as onResetNote,
} from "store/actions"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"
import Spinners from "components/Common/Spinner"
import PageModal from "components/Common/PageModal"
const formdefault = {
  NoteID: 0,
  Title: "",
  Link: "",
  File: "",
}
function BasicInfoTab({ note }) {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  // const [isLoading, setLoading] = useState(loading)
  const { classes, loading } = useSelector(state => state.class1)

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    NoteID: yup.number(),
    Title: yup.string().required("Required"),
    Classes: yup.object().required("Required"),
    Link: yup.string().nullable(),
    File: yup
      .mixed()
      //.required("Required")
      .transform((_, val) => (val ? String(val) : null))
      .notRequired(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  useDeepCompareEffect(() => {
    function updateCurriculumState() {
      dispatch(onGetClasses())
    }

    updateCurriculumState()
  }, [dispatch])

  useEffect(() => {
    const { id } = routeParams
    if (id === "new") {
      reset()
    } else {
      //reset form with server data
      const response = {
        ...note,
        Classes: {
          ClassID: Number(note?.ClassID),
          Title: note?.ClassName,
        },
        Link: note?.Link || "",
      }
      reset(response)
    }

    return () => {
      //  dispatch(onResetNote())
    }
  }, [dispatch, note])

  const onCancelClick = () => {
    navigate("/learning/notes")
  }

  const handleSave = () => {
    trigger() //for form validation
    if (isValid) {
      const { id } = routeParams

      const newNote = {
        ...getValues(),
        // id: id,
        NoteID: note?.NoteID,
      }
      if (id === "new") {
        dispatch(onAddNote(newNote))
      } else {
        dispatch(onAddNote(newNote))
      }

      navigate("/learning/notes")
    }
  }

  return (
    <FormProvider {...methods}>
      <Row>
        <Col lg={6}>
          <div className="mb-3">
            <Label>Title</Label>
            <Controller
              name="Title"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="Title"
                    type="text"
                    required
                    invalid={!!errors.Title}
                  />
                  {errors?.Title?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Title?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>

          <div className="mb-3">
            <Label>Class</Label>
            <Controller
              name="Classes"
              control={control}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    id="Classes"
                    options={classes.filter(x => x.RoomID > 0)}
                    getOptionLabel={option => option.Title}
                    getOptionValue={option => option.ClassID}
                    required
                    aria-invalid={!!errors.Classes}
                    classNamePrefix="select2-selection"
                  />
                  {errors?.Classes?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Classes?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Link</Label>
            <Controller
              name="Link"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="Link"
                    type="text"
                    value={field.value || ""}
                  />
                  {/* {errors?.Link?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Link?.message}
                    </FormFeedback>
                  ) : null} */}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>File</Label>
            <Controller
              name="File"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    className="form-control"
                    type="file"
                    id="File"
                    value={field.value?.File}
                    onChange={e => field.onChange(e.target.files[0])}
                    // required
                    // invalid={!!errors.File}
                  />
                  {/* {errors?.File?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.File?.message}
                        </FormFeedback>
                      ) : null} */}
                </>
              )}
            />
            <a>{getValues("FileName") && "File : " + getValues("FileName")}</a>
          </div>
        </Col>
      </Row>
      <div className="d-flex flex-wrap gap-2">
        <Button color="primary" className="btn" onClick={handleSave}>
          {routeParams.id === "new" ? "Save" : "Update"}
        </Button>
        <Button
          type="submit"
          color="secondary"
          onClick={onCancelClick}
          className=""
        >
          Cancel
        </Button>
      </div>
    </FormProvider>
  )
}
export default BasicInfoTab
