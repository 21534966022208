import React, { useState, useEffect } from "react"

//controls
import { Button, Col, Form, Input, Label, Row, FormFeedback } from "reactstrap"
import Flatpickr from "react-flatpickr"
import Switch from "react-switch"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

// Form Editor
import { Editor } from "react-draft-wysiwyg"
import { EditorState, ContentState } from "draft-js"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

//navigation
import { Link, useNavigate, useParams } from "react-router-dom"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"
import { isEmpty } from "lodash"
import {
  addNewMeal as onAddNewMeal,
  updateMeal as onUpdateMeal,
  getClasses as onGetClasses,
  resetMeal as onResetMeal,
} from "store/actions"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"
import Spinners from "components/Common/Spinner"

function BasicInfoTab({ meal, loading }) {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )
  const [isLoading, setLoading] = useState(loading)
  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    Title: yup
      .string()
      .required("Required")
      .min(3, "Title must be at least 3 characters"),
    Classes: yup.object().required("Required"),
    IsActive: yup.bool(),
    Menu: yup
      .string()
      .required("Required")
      .transform((_, val) => (val ? val : null)),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      Title: "",
      IsActive: true,
      Menu: "",
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  useDeepCompareEffect(() => {
    function updateMealState() {
      //reset form with server data
      reset(meal)
    }
    updateMealState()
  }, [dispatch])

  //////////////////ClassType STATE/////////////////////
  const { classes } = useSelector(state => state.class1)
  ///////////////////////////////////////

  ///////////////////////////////////////
  const handleEditorChange = editorState => {
    setEditorState(editorState)
    const content = editorState.getCurrentContent().getPlainText()
    setValue("Menu", content)
  }
  useEffect(() => {
    const { id } = routeParams
    if (id === "new") {
      reset()
    } else {
      //reset form with server data
      if (!isEmpty(meal)) {
        const response = {
          ...meal,
          Classes: {
            ClassID: Number(meal?.ClassID),
            Title: meal?.ClassTitle,
          },
        }
        const contentState = ContentState.createFromText(meal.Menu ?? '')
        const editorState = EditorState.createWithContent(contentState)
        setEditorState(editorState)
        reset(response)
      }
    }
    return () => {
      dispatch(onResetMeal())
    }
  }, [dispatch])

  const onCancelClick = () => {
    navigate("/meals")
  }

  const handleSave = () => {
    trigger() //for form validation
    if (isValid) {
      const { id } = routeParams

      if (id === "new") {
        dispatch(onAddNewMeal(getValues()))
      } else {
        dispatch(onUpdateMeal(getValues()))
      }
      navigate("/meals")
    }
  }

  if (isLoading) {
    return <Spinners setLoading={setLoading} />
  }

  return (
    <FormProvider {...methods}>
      <Row>
        <Col sm="6">
          <div className="mb-3">
            <Label>Title</Label>
            <Controller
              name="Title"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="Title"
                    type="text"
                    required
                    invalid={!!errors.Title}
                  />
                  {errors?.Title?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Title?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Class</Label>
            <Controller
              name="Classes"
              control={control}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    placeholder="Select..."
                    id="Classes"
                    options={classes.filter(x => x.RoomID > 0)}
                    getOptionLabel={option => option.Title}
                    getOptionValue={option => option.ClassID}
                    classNamePrefix="select2-selection"
                  />
                  {errors?.Classes?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Classes?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Active</Label>
            <div>
              <Controller
                name="IsActive"
                control={control}
                render={({ field }) => (
                  <>
                    <Switch
                      {...field}
                      id="IsActive"
                      checked={field.value}
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      className="me-1 mb-sm-8 mb-2"
                      onColor="#626ed4"
                    />
                  </>
                )}
              />
            </div>
          </div>
          <div className="mb-3">
            <Label>Menu</Label>
            <div>
              <Editor
                editorState={editorState}
                onEditorStateChange={handleEditorChange}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />
              {errors?.Menu?.message ? (
                <FormFeedback type="invalid" className="d-block">
                  {errors?.Menu?.message}
                </FormFeedback>
              ) : null}
            </div>
          </div>
        </Col>
      </Row>
      <div className="d-flex flex-wrap gap-2">
        <Button color="primary" className="btn" onClick={handleSave}>
          {routeParams.id === "new" ? "Save" : "Update"}
        </Button>
        <Button
          type="submit"
          color="secondary"
          onClick={onCancelClick}
          className=""
        >
          Cancel
        </Button>
      </div>
    </FormProvider>
  )
}
export default BasicInfoTab
