import Moment from "moment"

export const Calculate_Age = dateOfBirth => {
  let year = Moment().diff(dateOfBirth, "years")
  let month = Moment().diff(dateOfBirth, "months") - year * 12
  return `${year}y ${month}m`
}

export const HandleValidDate = date => {
  return Moment(new Date(date)).format("DD MMM Y HH:mm")
}

export const FormatDate = date => {
  return Moment(new Date(date)).format("DD MMM Y")
}

export const FormatDateDDMMMYYYY = date => {
  return Moment(new Date(date)).format("DD MMM YYYY")
}
