import {
    GET_RESOURCES,
    GET_RESOURCES_SUCCESS,
    GET_RESOURCES_FAIL,
    ADD_RESOURCE,
    ADD_RESOURCE_SUCCESS,
    ADD_RESOURCE_FAIL,
    DELETE_RESOURCE,
    DELETE_RESOURCE_SUCCESS,
    DELETE_RESOURCE_FAIL,
    GET_RESOURCE_DETAIL,
    GET_RESOURCE_DETAIL_SUCCESS,
    GET_RESOURCE_DETAIL_FAIL,
    UPDATE_RESOURCE,
    UPDATE_RESOURCE_SUCCESS,
    UPDATE_RESOURCE_FAIL,
    RESET_RESOURCE,
    RESET_RESOURCE_SUCCESS

  } from "./actionTypes"
  
  export const getResources = filter => ({
    type: GET_RESOURCES,
    filter,
  })
  
  export const getResourcesSuccess = resources => ({
    type: GET_RESOURCES_SUCCESS,
    payload: resources,
  })
  
  export const getResourcesFail = error => ({
    type: GET_RESOURCES_FAIL,
    payload: error,
  })
  
  export const addResource = resource => ({
    type: ADD_RESOURCE,
    payload: resource,
  })
  
  export const addResourceSuccess = resource => ({
    type: ADD_RESOURCE_SUCCESS,
    payload: resource,
  })
  
  export const addResourceFail = error => ({
    type: ADD_RESOURCE_FAIL,
    payload: error,
  })
  
  export const deleteResource = resource => ({
    type: DELETE_RESOURCE,
    payload: resource,
  })
  
  export const deleteResourceSuccess = resource => ({
    type: DELETE_RESOURCE_SUCCESS,
    payload: resource,
  })
  
  export const deleteResourceFail = error => ({
    type: DELETE_RESOURCE_FAIL,
    payload: error,
  })
  
  export const getResourceDetail = resourceId => ({
    type: GET_RESOURCE_DETAIL,
    payload: resourceId,
  })
  
  export const getResourceDetailSuccess = activity => ({
    type: GET_RESOURCE_DETAIL_SUCCESS,
    payload: activity,
  })
  
  export const getResourceDetailFail = error => ({
    type: GET_RESOURCE_DETAIL_FAIL,
    payload: error,
  })
  
  export const updateResource = resource => ({
    type: UPDATE_RESOURCE,
    payload: resource,
  })
  
  export const updateResourceSuccess = resource => ({
    type: UPDATE_RESOURCE_SUCCESS,
    payload: resource,
  })
  
  export const updateResourceFail = error => ({
    type: UPDATE_RESOURCE_FAIL,
    payload: error,
  })
  
  export const resetResource = () => ({
    type: RESET_RESOURCE,
  })
  
  export const resetResourceSuccess = resource => ({
    type: RESET_RESOURCE_SUCCESS,
    payload: resource,
  })