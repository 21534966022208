// @flow
import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_SIDEBAR_THEME_IMAGE,
  CHANGE_PRELOADER,
  TOGGLE_LEFTMENU,
  SHOW_SIDEBAR,
  CHANGE_LAYOUT_MODE,
  GET_THEME_SUCCESS,
  GET_THEME_FAIL,
  UPDATE_THEME_SUCCESS,
  UPDATE_THEME_FAIL,
  GET_BASICSETTING_SUCCESS,
  GET_BASICSETTING_FAIL,
  UPDATE_BASICSETTING_SUCCESS,
  UPDATE_BASICSETTING_FAIL,
} from "./actionTypes"

//constants
import {
  layoutTypes,
  layoutModeTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  leftBarThemeImageTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
} from "../../constants/layout"

const INIT_STATE = {
  layoutType: layoutTypes.VERTICAL,
  layoutModeType: layoutModeTypes.LIGHT,
  layoutWidth: layoutWidthTypes.FLUID,
  leftSideBarTheme: leftSideBarThemeTypes.DARK,
  leftSideBarThemeImage: leftBarThemeImageTypes.NONE,
  leftSideBarType: leftSidebarTypes.DEFAULT,
  topbarTheme: topBarThemeTypes.LIGHT,
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false,
  showSidebar: true,
  leftMenu: false,
  basicSetting: [],
  error: {},
  loading: true,
}
const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      }
    case CHANGE_PRELOADER:
      return {
        ...state,
        isPreloader: action.payload,
      }

    case CHANGE_LAYOUT_MODE:
      return {
        ...state,
        layoutModeType: action.payload,
      }

    case CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidth: action.payload,
      }
    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload,
      }
    case CHANGE_SIDEBAR_THEME_IMAGE:
      return {
        ...state,
        leftSideBarThemeImage: action.payload,
      }
    case CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType,
      }
    case CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarTheme: action.payload,
      }
    case SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: action.payload,
      }
    case SHOW_SIDEBAR:
      return {
        ...state,
        showSidebar: action.payload,
      }
    case TOGGLE_LEFTMENU:
      return {
        ...state,
        leftMenu: action.payload,
      }
    case GET_THEME_SUCCESS:
      return {
        ...state,
        layoutType: action.payload[0].LayoutType,
        layoutModeType: action.payload[0].LayoutModeType,
        layoutWidth: action.payload[0].LayoutWidth,
        leftSideBarTheme: action.payload[0].LeftSideBarTheme,
        leftSideBarThemeImage: action.payload[0].LeftSideBarThemeImage,
        leftSideBarType: action.payload[0].LeftSidebarType,
        topbarTheme: action.payload[0].TopBarTheme,
        loading: true,
      }
    case GET_THEME_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_THEME_SUCCESS:
      return {
        ...state,
        layoutType: action.payload[0].LayoutType,
        layoutModeType: action.payload[0].LayoutModeType,
        layoutWidth: action.payload[0].LayoutWidth,
        leftSideBarTheme: action.payload[0].LeftSideBarTheme,
        leftSideBarThemeImage: action.payload[0].LeftSideBarThemeImage,
        leftSideBarType: action.payload[0].LeftSidebarType,
        topbarTheme: action.payload[0].TopBarTheme,
        loading: true,
      }
    case UPDATE_THEME_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_BASICSETTING_SUCCESS:
      return {
        ...state,
        basicSetting: action.payload,
        loading: true,
      }
    case GET_BASICSETTING_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_BASICSETTING_SUCCESS:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_BASICSETTING_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Layout
