import React, { useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"
import Select from "react-select"
import { Input, Label, FormFeedback, Row, Col } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  getCountries as onGetCountries,
  getStates as onGetStates,
  getCities as onGetCities,
} from "store/actions"

import { DropdownInput, NumberInput, TextareaInput } from "../Controls"

export default function LocationField(props) {
  const methods = useFormContext()
  const dispatch = useDispatch()
  const { control, formState, setValue, getValues, reset } = methods
  const { errors } = formState

  useEffect(() => {
    dispatch(onGetCountries())
    if (getValues("Countries") !== undefined) {
      dispatch(onGetStates(getValues("Countries").CountryID))
      dispatch(onGetCities(getValues("States").StateID))
    }
  }, [dispatch])

  const GetStates = x => {
    setValue("Countries", x)
    dispatch(onGetStates(x.CountryID))
  }

  const GetCities = x => {
    setValue("States", x)
    dispatch(onGetCities(x.StateID))
  }

  //////////////////HELPER STATE/////////////////////
  const { countries, states, cities } = useSelector(state => state.helper)
  ///////////////////////////////////////

  return (
    <Row>
      <Col sm="12">
        <div className="mb-3">
          <TextareaInput label="Address" id="Address" required={false} />
        </div>
      </Col>
      <Col sm="4">
        <div className="mb-3">
          <DropdownInput
            label="Country"
            id="Countries"
            items={countries}
            itemlabel="Name"
            itemvalue="CountryID"
            onChange={x => GetStates(x)}
          />
        </div>
      </Col>
      <Col sm="4">
        <div className="mb-3">
          <DropdownInput
            label="State"
            id="States"
            items={states}
            itemlabel="Name"
            itemvalue="StateID"
            onChange={x => GetCities(x)}
          />
        </div>
      </Col>
      <Col sm="4">
        <div className="mb-3">
          <DropdownInput
            label="City"
            id="Cities"
            items={cities}
            itemlabel="Name"
            itemvalue="CityID"
          />
        </div>
      </Col>
      <Col sm="12">
        <div className="mb-3">
          <NumberInput label="Zip Code" id="ZipCode" />
        </div>
      </Col>
    </Row>
  )
}
