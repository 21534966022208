import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//Adult
import adult from "./adult/reducer"

//child
import child from "./child/reducer"

//child Schedules
import childSchedules from "./scheduling/child/reducer"

//class
import class1 from "./class/reducer"

//class Schedules
import classSchedules from "./scheduling/class/reducer"

//Certification
import certificate from "./certification/reducer"

//document
import document from "./document/reducer"

//Immunization
import immunization from "./immunization/reducer"

//meal
import meal from "./meal/reducer"

//meal Schedules
import mealSchedules from "./scheduling/meal/reducer"

//bus
import bus from "./bus/reducer"

//bus Schedules
import busSchedules from "./scheduling/bus/reducer"

//room
import room from "./room/reducer"

//Staff
import staff from "./staff/reducer"

//staff Schedules
import staffSchedules from "./scheduling/staff/reducer"

//Role
import role from "./role/reducer"

//notification
import notification from "./notification/reducer"

//forum
import forum from "./forum/reducer"

//chat
import chat from "./chat/reducer"

//program
import program from "./pricing/program/reducer"

//flexible
import flexible from "./pricing/flexible/reducer"
//addons
import addon from "./pricing/addon/reducer"

//charges
import charge from "./pricing/charges/reducer"

//navigation
import navigation from "./navigation/reducer"

//profile
import centerProfile from "./profile/reducer"

// album
import album from "./album/reducer"

// learing
import lessonPlan from "./learning/lessonplan/reducer"
import curriculum from "./learning/curriculum/reducer"
import note from "./learning/note/reducer"
import resource from "./learning/resource/reducer"

//report
import report from "./report/reducer"

//Dashboard
import Dashboard from "./dashboard/reducer"

//User
import user from "./user/reducer"

//Calendar
import calendar from "./calendar/reducer"

//Wallet
import wallet from "./wallet/reducer"

//Activity
import activity from "./scheduling/activity/reducer"

//Fee
import fee from "./fee/reducer"

//Module
import module from "./module/reducer"

//Helper
import helper from "./helper/reducer"

//Doctor
import doctorSchedules from "./scheduling/doctor/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  adult,
  role,
  child,
  childSchedules,
  class1,
  classSchedules,
  certificate,
  document,
  immunization,
  meal,
  mealSchedules,
  room,
  bus,
  busSchedules,
  staff,
  staffSchedules,
  notification,
  forum,
  chat,
  program,
  flexible,
  addon,
  charge,
  navigation,
  centerProfile,
  album,
  lessonPlan,
  curriculum,
  note,
  resource,
  report,
  Dashboard,
  user,
  calendar,
  wallet,
  activity,
  fee,
  module,
  helper,
  doctorSchedules,
})

export default rootReducer
