import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Resource Redux States
import {
  GET_RESOURCES,
  ADD_RESOURCE,
  DELETE_RESOURCE,
  UPDATE_RESOURCE,
  RESET_RESOURCE,
} from "./actionTypes"
import {
  getResourcesSuccess,
  getResourcesFail,
  addResourceSuccess,
  addResourceFail,
  deleteResourceSuccess,
  deleteResourceFail,
  updateResourceSuccess,
  updateResourceFail,
  resetResourceSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getResources,
  addResource,
  deleteResource,
  updateResource,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchResources({ filter }) {
  try {
    const response = yield call(getResources, filter)
    yield put(getResourcesSuccess(response))
  } catch (error) {
    yield put(getResourcesFail(error))
  }
}

function* onAddResource({ payload: resource }) {
  try {
    var formData = new FormData()
    let i = 1
    resource?.File?.forEach(item => {
      formData.append("File" + i, item)
      i++
    })
    formData.append("Data", JSON.stringify(resource))
    const response = yield call(addResource, formData)
    yield put(addResourceSuccess(response))
    toast.success("Resource added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addResourceFail(error))
    toast.error("Resource addition failed", { autoClose: 2000 })
  }
}

function* onUpdateResource({ payload: resource }) {
  try {
    var formData = new FormData()
    let i = 1
    resource?.Attachment?.forEach(item => {
      formData.append("Attachment" + i, item)
      i++
    })
    formData.append("Data", JSON.stringify(resource))

    const response = yield call(updateResource, formData)
    yield put(updateResourceSuccess(response))
    toast.success("Resource updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateResourceFail(error))
    toast.error("Resource updatation failed", { autoClose: 2000 })
  }
}

function* onDeleteResource({ payload: resource }) {
  try {
    const response = yield call(deleteResource, resource)
    yield put(deleteResourceSuccess(response))
    toast.success("Resource deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteResourceFail(error))
    toast.error("Resource deletion failed", { autoClose: 2000 })
  }
}

function* onResetResource() {
  try {
    yield put(resetResourceSuccess({}))
  } catch (error) {}
}

function* resourceSaga() {
  yield takeEvery(GET_RESOURCES, fetchResources)
  yield takeEvery(ADD_RESOURCE, onAddResource)
  yield takeEvery(DELETE_RESOURCE, onDeleteResource)
  yield takeEvery(UPDATE_RESOURCE, onUpdateResource)
  yield takeEvery(RESET_RESOURCE, onResetResource)
}

export default resourceSaga
