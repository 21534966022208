/*Get DOCTOR SCHEDULES */
export const GET_DOCTOR_SCHEDULES = "GET_DOCTOR_SCHEDULES"
export const GET_DOCTOR_SCHEDULES_SUCCESS = "GET_DOCTOR_SCHEDULES_SUCCESS"
export const GET_DOCTOR_SCHEDULES_FAIL = "GET_DOCTOR_SCHEDULES_FAIL"

/*Add DOCTOR SCHEDULES */
export const ADD_DOCTOR_SCHEDULES = "ADD_DOCTOR_SCHEDULES"
export const ADD_DOCTOR_SCHEDULES_SUCCESS = "ADD_DOCTOR_SCHEDULES_SUCCESS"
export const ADD_DOCTOR_SCHEDULES_FAIL = "ADD_DOCTOR_SCHEDULES_FAIL"

/**
 * Delete DOCTOR SCHEDULE
 */
export const DELETE_DOCTOR_SCHEDULE = "DELETE_DOCTOR_SCHEDULE"
export const DELETE_DOCTOR_SCHEDULE_SUCCESS = "DELETE_DOCTOR_SCHEDULE_SUCCESS"
export const DELETE_DOCTOR_SCHEDULE_FAIL = "DELETE_DOCTOR_SCHEDULE_FAIL"

/*Sync DOCTOR MEETING*/
export const DOCTOR_MEETING_SYNC = "DOCTOR_MEETING_SYNC"
export const DOCTOR_MEETING_SYNC_SUCCESS = "DOCTOR_MEETING_SYNC_SUCCESS"
export const DOCTOR_MEETING_SYNC_FAIL = "DOCTOR_MEETING_SYNC_FAIL"

//Reset DOCTOR SCHEDULE
export const RESET_DOCTOR_SCHEDULE = "RESET_DOCTOR_SCHEDULE"
