import React, { useState, useEffect } from "react"
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"
import axios from "axios"

const StripeCheckOutForm = ({ triggerPayment, amount, user, onReady }) => {
  const stripe = useStripe()
  const elements = useElements()
  const [paymentStatus, setPaymentStatus] = useState("")

  useEffect(() => {
    // Notify parent when Stripe is ready
    if (stripe && elements) {
      onReady(true)
    }
  }, [stripe, elements, onReady])

  triggerPayment.current = async () => {
    if (!stripe || !elements) {
      throw new Error("Stripe.js has not yet loaded")
    }

    setPaymentStatus("")
    try {
      // Create PaymentIntent in the backend
      const { data } = await axios.post(
        `${process.env.REACT_APP_WEB_API_URL}/stripe/create-payment-intent`,
        {
          amount: amount * 100, // Amount in cents
          currency: "usd",
          description: `Card Payment`,
          customerName: user.Name,
          address: {
            Line1: user.Address,
            Line2: "",
            City: user.City,
            State: user.State,
            PostalCode: user.ZipCode,
            Country: user.Country,
          },
        }
      )

      const { paymentIntent, error } = await stripe.confirmCardPayment(
        data.clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        }
      )

      if (error) {
        setPaymentStatus(`Payment failed: ${error.message}`)
        return null // Return null in case of failure
      }

      if (paymentIntent) {
        setPaymentStatus(
          `Payment successful! Payment Intent ID: ${paymentIntent.id}`
        )
        return paymentIntent.id // Return the PaymentIntent ID
      }
    } catch (err) {
      setPaymentStatus(`Error: ${err.message}`)
      throw err
    }
  }

  return (
    <div>
      <div className="custom-card-element">
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "16px",
                color: "#424770",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#9e2146",
              },
            },
          }}
          className="stripe-card-element"
        />
      </div>
    </div>
  )
}

export default StripeCheckOutForm
