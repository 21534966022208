import React, { useEffect, useMemo, useState, Suspense } from "react"
import { useDeepCompareEffect } from "hooks"
import PropTypes from "prop-types"
import { Link, json, useNavigate } from "react-router-dom"
import "../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../../components/Common/TableContainer"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Badge,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import Spinners from "components/Common/Spinner"
import EmptyContainer from "components/Common/EmptyContainer"

function LessonPlanTab({ editable }) {
  const selectCalendarState = state => state.calendar

  const CalendarProperties = createSelector(selectCalendarState, Calendar => ({
    programenrollmentDetails: Calendar.programenrollmentDetails,
    events: Calendar.programenrollments,
    categories: Calendar.categories,
    isLoading: Calendar.isLoading,
  }))
  const { events, categories, isLoading, programenrollmentDetails } =
    useSelector(CalendarProperties)

  const [lessonPlans, setLessonPlans] = useState([])
  useEffect(() => {
    if (programenrollmentDetails[0]?.LessonPlans) {
      setLessonPlans(programenrollmentDetails[0]?.LessonPlans)
    }
  }, [programenrollmentDetails, lessonPlans, isLoading])

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Name",
        Cell: cellProps => {
          return (
            <>
              {editable ? (
                <>
                  <Link
                    to={
                      "/learning/lesson-plans/" + cellProps.row.original.RowID
                    }
                  >
                    {cellProps.row.original.Name}
                  </Link>
                </>
              ) : (
                <>{cellProps.row.original.Name}</>
              )}
            </>
          )
        },
      },
      {
        Header: "Class",
        accessor: "Title",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Shared",
        accessor: "IsShared",
        Cell: cellProps => {
          return cellProps.value == true ? "Yes" : "No"
        },
      },
    ],
    []
  )
  return (
    <React.Fragment>
      <div className="col-sm-3 d-flex align-items-center"></div>
      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <Row>
          <Col xs="12">
            {lessonPlans?.length == 0 ? (
              <EmptyContainer
                backURL=""
                message="No lesson plans found"
                linkText=""
              />
            ) : (
              <TableContainer
                columns={columns}
                data={lessonPlans}
                customPageSize={10}
                isPagination={true}
                filterable={false}
                iscustomPageSizeOptions={true}
                tableClass="align-middle table-nowrap table-check"
                theadClass="table-light"
                pagination="pagination pagination-rounded mb-0 ms-auto"
              />
            )}
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}
LessonPlanTab.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default LessonPlanTab
