import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { Input, Label, FormFeedback } from "reactstrap"

const TextInput = ({ label, id, required }) => {
  const methods = useFormContext()
  const { control, formState } = methods
  const { errors } = formState

  return (
    <React.Fragment>
      <Label>{label}</Label>
      <Controller
        name={id}
        control={control}
        render={({ field }) => (
          <>
            <Input
              {...field}
              id={id}
              type="text"
              required={required}
              invalid={!!errors[id]}
            />
            {errors[id]?.message ? (
              <FormFeedback type="invalid" className="d-block">
                {errors[id]?.message}
              </FormFeedback>
            ) : null}
          </>
        )}
      />
    </React.Fragment>
  )
}

TextInput.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
}

export default TextInput
