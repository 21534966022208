import React, { useEffect, useState, Fragment, useMemo } from "react"
import { Table, Row, Col } from "reactstrap"
import Moment from "moment"

function Scheduler({ data, column, filter, onHandleDateClick }) {
  return (
    <Fragment>
      <Row className="mb-2">
        <Col sm="12">
          <div className="text-sm-end">
            <div className="btn-group" role="group">
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio4"
                autoComplete="off"
                onClick={() =>
                  onHandleDateClick(
                    Moment(filter.date).subtract(7, "days").format("YYYY-MM-DD")
                  )
                }
              />
              <label className="btn btn-outline-secondary" htmlFor="btnradio4">
                <i className="fas fa-angle-left" />
              </label>

              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio5"
                autoComplete="off"
                defaultChecked
                onClick={() => onHandleDateClick(Moment().format("YYYY-MM-DD"))}
              />
              <label className="btn btn-outline-secondary" htmlFor="btnradio5">
                Today
              </label>

              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio6"
                autoComplete="off"
                onClick={() =>
                  onHandleDateClick(
                    Moment(filter.date).add(7, "days").format("YYYY-MM-DD")
                  )
                }
              />
              <label className="btn btn-outline-secondary" htmlFor="btnradio6">
                <i className="fas fa-angle-right" />
              </label>
            </div>
          </div>
        </Col>
      </Row>

      <div className="table-responsive">
        <Table className="align-middle table-nowrap table-check table-bordered">
          <thead className="table-light">
            <tr>
              {column.map((row, i) => (
                <th
                  key={"th" + row}
                  className={i == 0 || i == 1 ? "d-none" : ""}
                >
                  <div className="m-0">{row}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((n, d) => {
              return (
                <Fragment key={"data" + d}>
                  <tr>
                    {column.map((v, index) => {
                      return (
                        <td
                          key={"dl" + index}
                          className={index == 0 || index == 1 ? "d-none" : ""}
                        >
                          {/* {console.log("Inner Loop", n[v])} */}
                          {index !== 0 && index !== 1 && n[v] !== ""
                            ? JSON.parse(n[v]).map((w, i) => (
                                <div
                                  style={{
                                    padding: 5,
                                    fontSize: 12,
                                    border: "1px solid lightgrey",
                                    margin: 5,
                                    cursor: "pointer",
                                  }}
                                  key={i}
                                >
                                  {w.ScheduleTypeID === 1 ? w.Title : "Absent"}
                                  <div style={{ fontSize: 10 }}>
                                    {Moment(w.StartTime).format("hh:mm A") +
                                      " - " +
                                      Moment(w.EndTime).format("hh:mm A")}
                                  </div>
                                </div>
                              ))
                            : n[v]}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>
    </Fragment>
  )
}
export default Scheduler
