export const MAX_IMAGE_FILE_SIZE = 102400 //100KB
export const MAX_FILE_SIZE = 5242880 //5MB

const validFileExtensions = {
  image: ["jpg", "gif", "png", "jpeg"],
  file: ["doc", "docx", "txt", "xls", "xlsx", "csv", "pdf"],
}

export const ValidImageExtensions = ".jpg, .gif, .png, .jpeg"

export const ValidFileExtensions = ".doc, .docx, .txt, .xls, .xlsx, .csv, .pdf"

export const ValidExtensions =
  ".jpg, .gif, .png, .jpeg, .doc, .docx, .txt, .xls, .xlsx, .csv, .pdf"

export function isValidFileType(fileName, fileType) {
  return (
    fileName &&
    validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
  )
}

 // Basic Setting File validation constants
 export const validExtensionsBasicSetting = ["image/png", "image/PNG", "image/jpeg", "image/JPEG", "image/jpg", "image/JPG"]
 export const maxFileSizeBasicSetting = 1 * 1024 * 1024 // 1MB
