import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
//controls
import {
  Col,
  Label,
  Row,
  Modal,
  ModalBody,
  InputGroup,
  FormFeedback,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import Switch from "react-switch"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import { createSelector } from "reselect"
import Select from "react-select"
//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"

//import { addNewDocument as onAddDocument } from "store/actions"
import {
  // getChildHappenings as onGetChildHappenings,
  getStaffs as onGetStaffs,
  getChildRelations as onGetChildRelations,
  addChildHappening as onAddChildHappening,
  updateChildHappening as onUpdateChildHappening,
  //getChildHappeningDetail as onGetChildHappeningDetail,
} from "../../../store/actions"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"
import { json } from "react-router-dom"
import { isEmpty } from "lodash"

const HappeningModal = ({
  happeningTitle,
  show,
  childHappening,
  onHappeningCloseClick,
  happeningTypeID,
}) => {
  const dispatch = useDispatch()
  const [personTypeID, setPersonTypeID] = useState(1)
  const { child, relations, loading } = useSelector(state => state.child)

  const { staffs } = useSelector(state => state.staff)
  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    Date: yup.string().required("Required"),
    Time: yup.string().required("Required"),

    Staffs: yup
      .object()
      //.required("Required")
      .transform((_, val) => (val ? val : null)),
    Adults: yup
      .object()
      // .required("Required")
      .transform((_, val) => (val ? val : null)),
    Notes: yup.string(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      Date: "",
      Time: "",
      Notes: "",
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, watch, setValue, getValues, trigger } =
    methods
  const { errors, isValid } = formState

  useEffect(() => {
    dispatch(onGetStaffs())
  }, [dispatch])

  const onHappeningSaveClick = () => {
    trigger()
    if (isValid) {
      if (childHappening == null) {
        dispatch(
          onAddChildHappening({
            ...getValues(),
            HappeningTypeID: happeningTypeID,
            ChildID: child.ChildID,
            PersonTypeID: personTypeID,
          })
        )
        onHappeningCloseClick()
      } else {
        dispatch(
          onUpdateChildHappening({
            ...getValues(),
            HappeningTypeID: happeningTypeID,
            ChildID: child.ChildID,
            PersonTypeID: personTypeID,
            ChildHappeningID: childHappening.ChildHappeningID,
          })
        )

        onHappeningCloseClick()
      }
    }
  }

  const onHappeningModalClosed = () => {
    reset()
  }

  useEffect(() => {
    if (!isEmpty(child)) {
      dispatch(onGetChildRelations(child.ChildID))
    }

    if (isEmpty(childHappening)) {
      reset({
        Date: Moment().format("DD MMM yyyy"),
        Time: Moment().format("h:mm a"),
      })
    } else {
      const result = {
        ...childHappening,
        Date: Moment(childHappening.Date).format("DD MMM yyyy"),
        Time: Moment(childHappening.Time).format("h:mm a"),
        Staffs: {
          StaffID: Number(childHappening?.PersonID),
          Name: childHappening?.Person,
        },
        Adults: {
          AdultID: Number(childHappening?.PersonID),
          Name: childHappening?.Person,
        },
      }
      setPersonTypeID(childHappening?.PersonTypeID)
      reset(result)
    }
  }, [show, child])

  return (
    <Modal
      isOpen={show}
      toggle={onHappeningCloseClick}
      onClosed={() => onHappeningModalClosed()}
      centered={true}
    >
      <ModalHeader className="d-flex justify-content-center">
        {happeningTitle}
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <FormProvider {...methods}>
          <Row>
            <Col lg={12}>
              <div className="mb-3">{"Checking in " + child.Name}</div>
              <div className="mb-3">
                <Label>Date: {Moment().format("DD MMM yyyy")}</Label>
                <div className="d-none">
                  <Controller
                    name="Date"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Flatpickr
                          {...field}
                          className="form-control d-block"
                          id="Date"
                          options={{
                            dateFormat: "d M, Y",
                          }}
                          onChange={(selectedDates, dateStr, instance) => {
                            setValue("Date", dateStr)
                          }}
                          required
                        />
                        {errors?.Date?.message ? (
                          <FormFeedback type="invalid" className="d-block">
                            {errors?.Date?.message}
                          </FormFeedback>
                        ) : null}
                      </>
                    )}
                  />
                </div>{" "}
              </div>

              <div className="mb-3">
                <Label>Time</Label>
                <Controller
                  name="Time"
                  control={control}
                  render={({ field }) => (
                    <>
                      <InputGroup>
                        <Flatpickr
                          {...field}
                          className="form-control d-block"
                          id="Time"
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "h:i K",
                          }}
                          onChange={(selectedTimes, timeStr, instance) => {
                            setValue(
                              "Time",
                              Moment(selectedTimes[0]).format("h:mm a")
                            )
                          }}
                          required
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-clock-outline" />
                          </span>
                        </div>
                        {errors?.Time?.message ? (
                          <FormFeedback type="invalid" className="d-block">
                            {errors?.Time?.message}
                          </FormFeedback>
                        ) : null}
                      </InputGroup>
                    </>
                  )}
                />
              </div>

              <div>
                <div className="mb-3">
                  <Label>Person</Label>
                  <br></br>
                  <div className="btn-group" role="group">
                    <input
                      type="radio"
                      className="btn-check"
                      name="Person"
                      id="btnAdult"
                      autoComplete="off"
                      defaultChecked={personTypeID == 1 ? true : false}
                      onChange={() => {
                        setPersonTypeID(1)
                      }}
                    />
                    <label
                      className={
                        personTypeID == 1
                          ? "btn btn-outline-secondary active"
                          : "btn btn-outline-secondary"
                      }
                      htmlFor="btnAdult"
                    >
                      Adult
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="Person"
                      id="btnStaff"
                      autoComplete="off"
                      defaultChecked={personTypeID == 2 ? true : false}
                      onChange={() => {
                        setPersonTypeID(2)
                      }}
                    />
                    <label
                      className={
                        personTypeID == 2
                          ? "btn btn-outline-secondary active"
                          : "btn btn-outline-secondary"
                      }
                      htmlFor="btnStaff"
                    >
                      Staff
                    </label>
                  </div>
                </div>
                {personTypeID == 1 ? (
                  <div className="mb-3">
                    <Label>Adult</Label>
                    <Controller
                      name="Adults"
                      control={control}
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            id="Adults"
                            options={
                              relations &&
                              relations.filter(x => x.RelationTypeID > 0)
                            }
                            getOptionLabel={option => option.Name}
                            getOptionValue={option => option.AdultID}
                            required
                            aria-invalid={!!errors.Adults}
                            classNamePrefix="select2-selection"
                          />
                          {errors?.Adults?.message ? (
                            <FormFeedback type="invalid" className="d-block">
                              {errors?.Adults?.message}
                            </FormFeedback>
                          ) : null}
                        </>
                      )}
                    />
                  </div>
                ) : (
                  <div className="mb-3">
                    <Label>Staff</Label>
                    <Controller
                      name="Staffs"
                      control={control}
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            id="Staffs"
                            options={staffs}
                            getOptionLabel={option => option.Name}
                            getOptionValue={option => option.StaffID}
                            required
                            aria-invalid={!!errors.Staffs}
                            classNamePrefix="select2-selection"
                          />
                          {errors?.Staffs?.message ? (
                            <FormFeedback type="invalid" className="d-block">
                              {errors?.Staffs?.message}
                            </FormFeedback>
                          ) : null}
                        </>
                      )}
                    />
                  </div>
                )}
                <div className="mb-3">
                  <Label>Notes</Label>
                  <Controller
                    name="Notes"
                    control={control}
                    value=""
                    render={({ field }) => (
                      <>
                        <textarea
                          {...field}
                          rows={3}
                          className="form-control mb-3"
                          id="Notes"
                        />
                        {/* {errors?.Notes?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.Notes?.message}
                        </FormFeedback>
                      ) : null} */}
                      </>
                    )}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <button
            type="button"
            className="btn btn btn-primary"
            onClick={() => onHappeningSaveClick()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn btn-secondary"
            onClick={() => onHappeningCloseClick()}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

HappeningModal.propTypes = {
  onHappeningCloseClick: PropTypes.func,
  onHappeningSaveClick: PropTypes.func,
  show: PropTypes.any,
}

export default HappeningModal
