import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, json, useNavigate } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"

import {
  getServices as onGetServices,
  getEnrollments as onGetEnrollments,
} from "store/actions"
import Moment from "moment"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Badge,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
  Collapse,
  Table,
} from "reactstrap"

import Spinners from "components/Common/Spinner"
import DropdownButton from "components/Common/DropdownButton"
import EmptyContainer from "components/Common/EmptyContainer"
import EnrollModal from "../modal/EnrollModal"
import DailyVisitModal from "../modal/DailyVisitModal"
import HourlyVisitModal from "../modal/HourlyVisitModal"
import RecurringVisitModal from "../modal/RecurringVisitModal"
import ChargeModal from "../modal/ChargeModal"
import classnames from "classnames"
import { isEmpty } from "lodash"
import { FormatDate } from "helpers/method_helper"
import EnrollmentApprovalModal from "pages/Dashboard/request/modal/EnrollmentApprovalModal"

function ServiceTab() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [state, setState] = useState({
    enrollModal: false,
    dailyVisitModal: false,
    hourlyVisitModal: false,
    recurringVisitModal: false,
    chargeModal: false,
    isChanged: false,
    approvalModal: false,
    programEnrollmentID: 0,
  })

  const updateState = data => setState(previous => ({ ...previous, ...data }))

  //////////////////CHILD STATE/////////////////////
  const { child } = useSelector(state => state.child)
  //////////////////////////////////////

  //////////////////PROGRAM STATE//////////////////////
  const { enrollments } = useSelector(state => state.program)
  /////////////////////////////////////////////////////

  useEffect(() => {
    dispatch(onGetServices())
    if (!isEmpty(child) || state.isChanged) {
      dispatch(onGetEnrollments(child.ChildID))
    }
  }, [dispatch, child, state.isChanged])

  const { services, loading } = useSelector(state => state.helper)
  const [isLoading, setLoading] = useState(loading)

  const onClick = item => {
    if (item.ServiceTypeID === 1) {
      updateState({ enrollModal: true })
    } else if (item.ServiceTypeID === 2) {
      updateState({ hourlyVisitModal: true })
    } else if (item.ServiceTypeID === 3) {
      updateState({ dailyVisitModal: true })
    } else if (item.ServiceTypeID === 4) {
      updateState({ recurringVisitModal: true })
    } else {
      updateState({ chargeModal: true })
    }
  }

  const handleValidDate = date => {
    return Moment(new Date(date)).format("DD MMM Y")
  }

  const onModalClick = e => {
    updateState({ approvalModal: true, programEnrollmentID: e })
  }

  const columns = useMemo(
    () => [
      {
        Header: "Service",
        accessor: "Program",
        Cell: cellProps => {
          return (
            <>
              <p className="text-muted mb-0">
                {cellProps.row.original.Program}
              </p>
              <div className="font-size-12 mb-1">
                {"Starting date : " +
                  handleValidDate(cellProps.row.original.StartDate)}
              </div>
            </>
          )
        },
      },
      {
        Header: "Price",
        accessor: "Price",
        Cell: cellProps => {
          return "$" + Number(cellProps.value).toFixed(2)
        },
      },
      {
        Header: "Class",
        accessor: "Class",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Requested By",
        accessor: "CreatedBy",
        Cell: cellProps => {
          return (
            <>
              <span className="font-size-14 mb-1">
                {cellProps.row.original.CreatedByName}
              </span>
              <p className="text-muted mb-0">
                {handleValidDate(cellProps.row.original.CreatedDate)}
              </p>
            </>
          )
        },
      },
      {
        Header: "Status",
        accessor: "EnrollmentType",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  onModalClick(cellProps.row.original.ProgramEnrollmentID)
                }}
              >
                <i
                  className="mdi mdi-eye-outline font-size-18"
                  id="edittooltip"
                />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  View
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const onCloseModal = () => {
    updateState({
      enrollModal: false,
      hourlyVisitModal: false,
      dailyVisitModal: false,
      recurringVisitModal: false,
      chargeModal: false,
      isChanged: true,
      approvalModal: false,
    })
  }

  return (
    <React.Fragment>
      {state.enrollModal && (
        <EnrollModal
          show={state.enrollModal}
          onCloseClick={() => onCloseModal()}
        />
      )}
      {state.dailyVisitModal && (
        <DailyVisitModal
          show={state.dailyVisitModal}
          onCloseClick={() => onCloseModal()}
        />
      )}
      {state.hourlyVisitModal && (
        <HourlyVisitModal
          show={state.hourlyVisitModal}
          onCloseClick={() => onCloseModal()}
        />
      )}
      {state.recurringVisitModal && (
        <RecurringVisitModal
          show={state.recurringVisitModal}
          onCloseClick={() => onCloseModal()}
        />
      )}
      {state.chargeModal && (
        <ChargeModal
          show={state.chargeModal}
          onCloseClick={() => onCloseModal()}
        />
      )}
      {state.approvalModal && (
        <EnrollmentApprovalModal
          show={state.approvalModal}
          programEnrollmentID={state.programEnrollmentID}
          onCloseClick={() => onCloseModal()}
        />
      )}
      <div className="row mb-3">
        <div className="col text-sm-end">
          <DropdownButton
            label="Add"
            items={services}
            textField="ServiceType"
            valueField="ServiceTypeID"
            onSelectClick={e => onClick(e)}
          />
        </div>
      </div>
      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <Row>
          <Col xs="12">
            {state.objectsList?.length == 0 ? (
              <EmptyContainer
                backURL=""
                message="No programs found for child"
                linkText=""
              />
            ) : (
              <div>
                <TableContainer
                  columns={columns}
                  data={enrollments}
                  customPageSize={10}
                  isPagination={true}
                  tableClass="align-middle table-nowrap table-check"
                  theadClass="table-light"
                  pagination="pagination pagination-rounded mb-0 ms-auto"
                />
              </div>
            )}
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}
ServiceTab.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default ServiceTab
